import { usePortalTemplateProvider } from "../../template-providers/PortalTemplateProvider"
import PortalSelectionMenu from "./PortalSelectionMenu"

export const PortalTemplateTemplateUI = () => {
    const { showMenu, template } = usePortalTemplateProvider()

    return (
        <div>
            {
                showMenu &&
                <PortalSelectionMenu
                    config={template.config}
                    selectionCallback={(category, index) => {
                        template.handleMenuSelection(category, index)
                    }}
                    onClose={() => template.closeMenu()}
                />
            }
        </div>
    )
}