export const theme = {
  borderRadius: "12px",
  primaryBackground: "#12eace",
  typography: {
    fontSize: {
      small: "16px",
      medium: "20px",
      large: "24px",
    },
    fontFamily: "'Roboto', sans-serif",
    fontWeight: {
      light: 100,
      bold: 300,
      bolder: 500,
    },
  },
  spacing: {
    small: "8px",
    medium: "16px",
    large: "24px",
  },
  zIndex: {
    level1: 10,
    level2: 20,
    level3: 30,
  },
};
