import { PropsWithChildren, createContext, useContext, useState } from "react";
import { IndoorDesignTemplate } from "../Templates/IndoorDesign/IndoorDesignTemplate";

const IndoorDesignContext = createContext<any>(null)

type IndoorDesignProviderProps = PropsWithChildren & { template: IndoorDesignTemplate }
const IndoorDesignProvider = ({ children, template }: IndoorDesignProviderProps) => {
    const [showMenu, setShowMenu] = useState<boolean>(false)

    template.setShowMenu = setShowMenu

    return <IndoorDesignContext.Provider value={{ showMenu, setShowMenu, template }}>{children}</IndoorDesignContext.Provider>
}

interface IndoorDesignContextValues {
    template: IndoorDesignTemplate,
    showMenu: boolean,
    setShowMenu: (status: boolean) => void
}
export const useIndoorDesignProvider = (): IndoorDesignContextValues => {
    const context = useContext(IndoorDesignContext);
    if (context === undefined) {
        throw new Error('useGlobalProvider must be used within an GlobalProvider');
    }
    return context;
};
export default IndoorDesignProvider