import { useContext, useEffect, useState } from "react";
import { ICustomerResponse, IExperienceResponse } from "../../../Managers/ServerManager";
import { Divider, CustomerLogo } from "./styled";
import { AppStore } from "../../../app-store/store";
import { SocialShareButtons } from "../../ShareButton";
import { Card, Spin } from "antd";
import { Mixpanel } from "../../../Managers/AnalyticsClient/AnalyticsClient";
import { AnalyticsEventPropertyKeys, AnalyticsKeys } from "../../../Managers/AnalyticsClient/AnalyticsKeys";
const { Meta } = Card;

export const HomePage = () => {
    const { allExperiences, urlParams } = useContext(AppStore)

    useEffect(() => {
        Mixpanel.track(AnalyticsKeys.HomeScreen, {
            [AnalyticsEventPropertyKeys.customerID]: urlParams.id
        })
    }, [])

    useEffect(() => {
        const numExperiences = allExperiences[0].experiences.length ?? -1
        Mixpanel.track(AnalyticsKeys.GotAllExperiences, {
            [AnalyticsEventPropertyKeys.NumberOfCustomerExperiences]: numExperiences
        })
    }, [allExperiences])


    if (allExperiences.length == 1) {
        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px 5px', gap: 5 }}>
                <SocialShareButtons />
                <CustomerLogo src={allExperiences[0].businessDescription.logo} />
                <div style={{ flex: 1, width: '100%', overflowY: 'scroll' }}>
                    <DisplayCustomerExperiences customer={allExperiences[0]} />
                </div>
            </div>
        )
    }

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', flex: 1 }}>
            <SocialShareButtons />
            {
                allExperiences.map((customer: ICustomerResponse, index: number) => (
                    <div key={index} style={{ width: '100%', padding: '15px 8px', display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, gap: 10 }}>
                        <CustomerLogo src={customer.businessDescription.logo} />
                        <div style={{ overflowY: 'auto', flex: 0.9, width: '100%' }}>
                            <DisplayCustomerExperiences customer={customer} />
                        </div>
                    </div>
                ))
            }
        </div >
    );
}

const DisplayCustomerExperiences: React.FC<{ customer: ICustomerResponse }> = ({
    customer,
}) => {
    const { experienceSelected, loadingExperienceJson } = useContext(AppStore);
    const [selectedExperienceID, setSelectedExperienceID] = useState<string | null>(
        null
    );

    const experienceTapped = (experience: IExperienceResponse) => {
        setSelectedExperienceID(experience.experienceID); // Set the selected experience ID
        Mixpanel.track(AnalyticsKeys.ExperienceTapped, {
            [AnalyticsEventPropertyKeys.ExperienceID]: experience.experienceID,
        });
        experienceSelected(experience.experienceID);
    };

    return (
        <>
            {customer.experiences.map(
                (experience: IExperienceResponse, expIndex: number) => (
                    <div key={expIndex} style={{ position: "relative" }}>
                        <Card
                            style={{
                                margin: "10px",
                                border: "none",
                                textAlign: "center",
                                position: "relative",
                            }}
                            cover={
                                <div style={{ position: "relative" }}>
                                    <img
                                        style={{
                                            height: 200,
                                            width: 200,
                                            objectFit: "contain",
                                            borderRadius: "40px",
                                            margin: "auto",
                                            display: "block",
                                            opacity:
                                                loadingExperienceJson && selectedExperienceID === experience.experienceID
                                                    ? 0.5
                                                    : 1, // Reduce opacity only if the selected experience is loading
                                        }}
                                        alt="example"
                                        src={experience.imageUrl}
                                    />
                                    {/* Show loading indicator only if the current card is the selected one */}
                                    {loadingExperienceJson && selectedExperienceID === experience.experienceID && (
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: "40px",
                                            }}
                                        >
                                            <Spin size="large" />
                                        </div>
                                    )}
                                </div>
                            }
                            onClick={() => experienceTapped(experience)}
                        >
                            <Meta style={{ width: "100%", color: "white" }} title={experience.name} />
                        </Card>
                        {expIndex < customer.experiences.length - 1 && <Divider />}
                    </div>
                )
            )}
        </>
    );
};