export const DoritosExperience0 = `
{
   "experience":{
      "auxData":{
         "backgroundExperienceJSON":{
            "UIObjects":[
               {
                  "height":70,
                  "imageUrl":"https://storage.googleapis.com/arplatform-99ab9.appspot.com/Production/Experiences/94b19ba545414746a9f84cdd1336ed9a/Images/906a47fb.png?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2554416000&Signature=V2uv94shbLI7P90DlkZR4KUVU32SQQTeNRdQHi29ozw8kV%2Bjxj5sSzWHgNpGiiB%2FJxQ1gL75pT%2F9OtT4rjFipo8MzrvJFePVyehdL0I63oEzIofMnwJChSbXzAKWPcNWUwpNH9BDF2CUygJRQE7wQeSJwqWGXaz7czIFUZPqKzxuZ7cd65KkdDkylZ7hYKoQctsyvyuzMkDMjOXKrYpP7h5q0IkeIpcmDYffZnwLqTC42d%2Bs9%2FPOBuspj3t0nF%2FKoQ4aRWp7se10dN9x6oe7glFfzI1FLECXaZm%2BFycl%2BRMRNrrq2YBCFTL5seWK7VueNgOUcsu3Pp10f4ZvmHLB%2BA%3D%3D",
                  "locX":0.5,
                  "locY":0.9,
                  "tag":"shootingButton",
                  "type":"button",
                  "width":70
               },
               {
                  "height":100,
                  "locX":0.5,
                  "locY":0.1,
                  "maxScore":10000,
                  "maxTime":30,
                  "tag":"stats123",
                  "type":"stats",
                  "useLevels":false,
                  "useScore":true,
                  "useTimer":true,
                  "width":100
               },
               {
                  "height":70,
                  "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FDoritosScopeImage.png?alt=media&token=feb054e7-e3e1-4e4f-87a3-94607822ea6a",
                  "locX":0.5,
                  "locY":0.5,
                  "tag":"scopeImage",
                  "type":"image",
                  "width":70
               }
            ],
            "generalRules":[
               {
                  "activity":{
                     "actions":[
                        
                     ],
                     "trigger":{
                        "trigger":"onExperienceStart"
                     }
                  }
               },
               {
                  "activity":{
                     "actions":[
                        
                     ],
                     "trigger":{
                        "trigger":"onTimeAlmostUp"
                     }
                  }
               },
               {
                  "activity":{
                     "actions":[
                        {
                           "action":"experienceFailed"
                        }
                     ],
                     "trigger":{
                        "trigger":"onTimeEnd"
                     }
                  }
               },
               {
                  "activity":{
                     "actions":[
                        {
                           "action":"pausePublishing"
                        }
                     ],
                     "maxExecutions":-1,
                     "trigger":{
                        "trigger":"onTrackingLost"
                     }
                  }
               },
               {
                  "activity":{
                     "actions":[
                        {
                           "action":"resumePublishing"
                        }
                     ],
                     "maxExecutions":-1,
                     "trigger":{
                        "trigger":"onTrackingEstablished"
                     }
                  }
               }
            ],
            "objects":[
               {
                  "activities":[
                     {
                        "actions":[
                           {
                              "action":"bringToLife",
                              "loc":"infront"
                           },
                           {
                              "action":"move",
                              "x":0,
                              "y":1,
                              "z":0
                           },
                           {
                              "action":"spinObject",
                              "duration":5,
                              "numRotations":100,
                              "spinAxis":[
                                 1,
                                 1,
                                 0.3
                              ]
                           },
                           {
                              "action":"kill",
                              "delay":5
                           }
                        ],
                        "trigger":{
                           "maxDelta":2,
                           "minDelta":1,
                           "trigger":"onRandDelta"
                        }
                     },
                     {
                        "actions":[
                           {
                              "action":"kill",
                              "delay":0
                           },
                           {
                              "action":"updateCounter",
                              "counterID":"stats123",
                              "delta":10
                           },
                           {
                              "action":"playSound",
                              "soundtrackName":"Hit-Pop-clipped.wav",
                              "soundtrackUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FAudios%2FHit-Pop-clipped.wav?alt=media&token=29177f73-8217-4c61-89b9-f96d430c38aa"
                           },
                           {
                              "action":"vibrate"
                           }
                        ],
                        "trigger":{
                           "id2":"bulletObject",
                           "trigger":"onCollideWith"
                        }
                     }
                  ],
                  "id":"SelectedObject",
                  "name":"Selected Object",
                  "object":{
                     "fileName":"ORBAqua.png",
                     "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA.",
                     "settings":{
                        "addPhysicsBody":true,
                        "addToAnalytics":false,
                        "allowUserGestures":false,
                        "isAffectedByGravity":false,
                        "physicsShapeType":2,
                        "scaleAxis":0,
                        "size":0.1
                     }
                  }
               },
               {
                  "activities":[
                     {
                        "actions":[
                           {
                              "action":"bringToLife",
                              "loc":"userPos"
                           },
                           {
                              "action":"spinObject",
                              "duration":0.7,
                              "numRotations":100,
                              "spinAxis":[
                                 0,
                                 1,
                                 0
                              ]
                           },
                           {
                              "action":"shoot"
                           },
                           {
                              "action":"kill",
                              "delay":5
                           },
                           {
                              "action":"playSound",
                              "soundtrackName":"Shoot-Crunch.wav",
                              "soundtrackUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FAudios%2FShoot-Crunch.wav?alt=media&token=d458d979-7f70-4da8-a568-b62fce6b7e9f"
                           }
                        ],
                        "trigger":{
                           "id":"shootingButton",
                           "trigger":"onButtonTap"
                        }
                     },
                     {
                        "actions":[
                           {
                              "action":"kill",
                              "delay":0
                           }
                        ],
                        "trigger":{
                           "id2":"Selected Object",
                           "trigger":"onCollideWith"
                        }
                     }
                  ],
                  "id":"BulletObject",
                  "name":"bulletObject",
                  "object":{
                     "fileName":"doritos_chip.usdz",
                     "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FModels%2FFinal%2Fdoritos_chip.usdz?alt=media&token=e23512ef-9a6a-49c1-b5a4-8df6c80e298d",
                     "fileUrlAndroid":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FModels%2FDoritos-chip-compressed.glb?alt=media&token=5a472674-3148-4810-8677-0180b37ca752",
                     "fileThumbnail":"https://storage.googleapis.com/arplatform-99ab9.appspot.com/Production/Experiences/94b19ba545414746a9f84cdd1336ed9a/Images/f3540519.png?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2554416000&Signature=137FAz2iUF%2Bn6ssZWoZxY4CDqnOL6CdwMs7BVuOE1NqCxCOduB26wkJhlkCxVSjBewJGGmTxWorfnf6TZGyK2gE4hVHRbEmCoNJoNFDpsipHGkcXMoVhxdvOCs9XgQe1nTBamxOarngA%2BRkZdftukH%2FzVmhHX%2BsoUmtcwFW%2FORP96mnE5e%2B7YxikiQaIqWL88F8g60XcCp8qG7l2ZMR4PUbNTfBOSUiE6wYnwhNHXgl0zUM5yEcrFhdJBAXgcMPK3L6sE1csrsrYWkneFXhSeB%2FyFT3%2FKEg3WlMGhYYjOBa4gmixl6dFfxJjEw8y8JW1WlXJ2gWPX%2BY66bJaAMeiBA%3D%3D",
                     "settings":{
                        "addPhysicsBody":true,
                        "addToAnalytics":false,
                        "allowUserGestures":false,
                        "isAffectedByGravity":false,
                        "physicsShapeType":2,
                        "scaleAxis":0,
                        "size":0.05
                     }
                  }
               },
               {
                  "id":"FalafelObjectID",
                  "name":"falafelObject",
                  "object":{
                     "fileName":"FalafelItem.png",
                     "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FFalafelItem.png?alt=media&token=6f1ebbbf-51f4-414c-8a14-f77ed293bfa8",
                     "fileUrlAndroid":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FFalafelItem.png?alt=media&token=6f1ebbbf-51f4-414c-8a14-f77ed293bfa8",
                     "fileThumbnail":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FFalafelItem.png?alt=media&token=6f1ebbbf-51f4-414c-8a14-f77ed293bfa8",
                     "settings":{
                        "allowUserGestures":false,
                        "size":0.03,
                        "addPhysicsBody":false,
                        "physicsShapeType":2,
                        "isAffectedByGravity":false
                     }
                  },
                  "activities":[
                     {
                        "trigger":{
                           "trigger":"onRandDelta",
                           "minDelta":1,
                           "maxDelta":3
                        },
                        "actions":[
                           {
                              "action":"bringToLife",
                              "loc":"infront"
                           },
                           {
                              "action":"moveFor",
                              "x":0,
                              "y":1,
                              "z":0,
                              "duration":10,
                              "durationPerMove":10,
                              "speedFactor":1.5
                           },
                           {
                              "action":"kill",
                              "delay":2
                           }
                        ]
                     }
                  ]
               },
               {
                  "id":"TacoObjectID",
                  "name":"tacoObject",
                  "object":{
                     "fileName":"TacoItem.png",
                     "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FTacoItem.png?alt=media&token=de6514e1-d5e7-4ad8-a329-9596c77b6668",
                     "fileUrlAndroid":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FTacoItem.png?alt=media&token=de6514e1-d5e7-4ad8-a329-9596c77b6668",
                     "fileThumbnail":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FTacoItem.png?alt=media&token=de6514e1-d5e7-4ad8-a329-9596c77b6668",
                     "settings":{
                        "allowUserGestures":false,
                        "size":0.03,
                        "addPhysicsBody":false,
                        "physicsShapeType":2,
                        "isAffectedByGravity":false
                     }
                  },
                  "activities":[
                     {
                        "trigger":{
                           "trigger":"onRandDelta",
                           "minDelta":0.5,
                           "maxDelta":1
                        },
                        "actions":[
                           {
                              "action":"bringToLife",
                              "loc":"infront"
                           },
                           {
                              "action":"moveFor",
                              "x":0,
                              "y":1,
                              "z":0,
                              "duration":10,
                              "durationPerMove":10,
                              "speedFactor":1.5
                           },
                           {
                              "action":"kill",
                              "delay":2
                           }
                        ]
                     }
                  ]
               },
               {
                  "id":"FalafelBagExplode_removeIfNot_0",
                  "name":"falafelBagExplode",
                  "object":{
                     "fileName":"DoritosBagExplode-Falafel.usdz",
                     "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FModels%2FFinal%2FpackageExplode-Blue.usdz?alt=media&token=ac0edd04-bf52-468e-a2b4-6e287e1ce991",
                     "fileUrlAndroid":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FModels%2FFinal%2FpackageExplode-Blue.glb?alt=media&token=cb183a97-8d46-4c89-a9fa-5c236f597fc7",
                     "fileThumbnail":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FFalafel_plus_10.png?alt=media&token=b39798c3-bc14-40eb-8a22-6ef9c8c5a999",
                     "settings":{
                        "allowUserGestures":false,
                        "scaleAxis":0,
                        "size":0.1,
                        "addPhysicsBody":false,
                        "physicsShapeType":2,
                        "isAffectedByGravity":false
                     }
                  },
                  "activities":[
                     {
                        "trigger":{
                           "trigger":"onCollideWith",
                           "id1":"Selected Object",
                           "id2":"bulletObject"
                        },
                        "actions":[
                           {
                              "action":"bringToLife",
                              "loc":"triggerPos"
                           },
                           {
                              "action":"kill",
                              "delay":2
                           }
                        ]
                     }
                  ]
               },
               {
                  "id":"TagoBagExplode_removeIfNot_1",
                  "name":"tacoBagExplode",
                  "object":{
                     "fileName":"DoritosBagExplode-Taco.usdz",
                     "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FModels%2FFinal%2FpackageExplode-Purple.usdz?alt=media&token=fdcab63a-c060-4081-9994-7adb206d1364",
                     "fileUrlAndroid":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FModels%2FFinal%2FpackageExplode-Purple.glb?alt=media&token=22f4c8d7-5609-4279-a0cb-a0e36c03b70a",
                     "fileThumbnail":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FFalafel_plus_10.png?alt=media&token=b39798c3-bc14-40eb-8a22-6ef9c8c5a999",
                     "settings":{
                        "allowUserGestures":false,
                        "scaleAxis":0,
                        "size":0.1,
                        "addPhysicsBody":false,
                        "physicsShapeType":2,
                        "isAffectedByGravity":false
                     }
                  },
                  "activities":[
                     {
                        "trigger":{
                           "trigger":"onCollideWith",
                           "id1":"Selected Object",
                           "id2":"bulletObject"
                        },
                        "actions":[
                           {
                              "action":"bringToLife",
                              "loc":"triggerPos"
                           },
                           {
                              "action":"kill",
                              "delay":2
                           }
                        ]
                     }
                  ]
               },
               {
                  "id":"DoritosChipsExplode",
                  "name":"doritosChipsExplode",
                  "object":{
                     "fileName":"DoritosExplodeCompressed.usdz",
                     "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FModels%2FFinal%2FDoritosExplodeCompressed.usdz?alt=media&token=ebe82f0d-1b97-427d-9784-948d5e15ed79",
                     "fileUrlAndroid":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FModels%2FFinal%2FDoritosExplode.glb?alt=media&token=d2ecfda5-ca70-4f4e-8486-a6313448ff50",
                     "fileThumbnail":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FFalafel_plus_10.png?alt=media&token=b39798c3-bc14-40eb-8a22-6ef9c8c5a999",
                     "settings":{
                        "allowUserGestures":false,
                        "size":0.06,
                        "addPhysicsBody":false,
                        "physicsShapeType":2,
                        "isAffectedByGravity":false
                     }
                  },
                  "activities":[
                     {
                        "trigger":{
                           "trigger":"onCollideWith",
                           "id1":"Selected Object",
                           "id2":"bulletObject"
                        },
                        "actions":[
                           {
                              "action":"bringToLife",
                              "loc":"triggerPos"
                           },
                           {
                              "action":"fadeOut",
                              "duration":2
                           },
                           {
                              "action":"kill",
                              "delay":2
                           }
                        ]
                     }
                  ]
               },
               {
                  "id":"FalafelPlus10Points_removeIfNot_0",
                  "name":"falafelPlus10Points",
                  "object":{
                     "fileName":"Falafel_plus_10.png",
                     "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FFalafel_plus_10.png?alt=media&token=b39798c3-bc14-40eb-8a22-6ef9c8c5a999",
                     "fileUrlAndroid":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FFalafel_plus_10.png?alt=media&token=b39798c3-bc14-40eb-8a22-6ef9c8c5a999",
                     "fileThumbnail":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FFalafel_plus_10.png?alt=media&token=b39798c3-bc14-40eb-8a22-6ef9c8c5a999",
                     "settings":{
                        "allowUserGestures":false,
                        "size":0.06,
                        "addPhysicsBody":false,
                        "physicsShapeType":2,
                        "isAffectedByGravity":false
                     }
                  },
                  "activities":[
                     {
                        "trigger":{
                           "trigger":"onCollideWith",
                           "id1":"Selected Object",
                           "id2":"bulletObject"
                        },
                        "actions":[
                           {
                              "action":"bringToLife",
                              "loc":"triggerPos"
                           },
                           {
                              "action":"lookAtUser"
                           },
                           {
                              "action":"moveFor",
                              "x":0,
                              "y":1,
                              "z":0,
                              "duration":10,
                              "durationPerMove":10,
                              "speedFactor":1.5
                           },
                           {
                              "action":"fadeOut",
                              "duration":1.5
                           },
                           {
                              "action":"kill",
                              "delay":1.2
                           }
                        ]
                     }
                  ]
               },
               {
                  "id":"TacoPlus10Points_removeIfNot_1",
                  "name":"tacoPlus10Points",
                  "object":{
                     "fileName":"Taco_plus_10.png",
                     "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FTaco_plus_10.png?alt=media&token=9dc0611c-30f5-454e-95ae-6eb77ecd78a0",
                     "fileUrlAndroid":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FTaco_plus_10.png?alt=media&token=9dc0611c-30f5-454e-95ae-6eb77ecd78a0",
                     "fileThumbnail":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FTaco_plus_10.png?alt=media&token=9dc0611c-30f5-454e-95ae-6eb77ecd78a0",
                     "settings":{
                        "allowUserGestures":false,
                        "size":0.06,
                        "addPhysicsBody":false,
                        "physicsShapeType":2,
                        "isAffectedByGravity":false
                     }
                  },
                  "activities":[
                     {
                        "trigger":{
                           "trigger":"onCollideWith",
                           "id1":"Selected Object",
                           "id2":"bulletObject"
                        },
                        "actions":[
                           {
                              "action":"bringToLife",
                              "loc":"triggerPos"
                           },
                           {
                              "action":"lookAtUser"
                           },
                           {
                              "action":"moveFor",
                              "x":0,
                              "y":1,
                              "z":0,
                              "duration":10,
                              "durationPerMove":10,
                              "speedFactor":1.5
                           },
                           {
                              "action":"fadeOut",
                              "duration":1.5
                           },
                           {
                              "action":"kill",
                              "delay":1.2
                           }
                        ]
                     }
                  ]
               }
            ]
         }
      },
      "AndroidExperiencePageBackgroundImage": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FMobile_doritos_18.jpg?alt=media&token=c24159b5-0ec2-442a-9a06-b870bf59e2ac",
      "input":{
         "shootersItems":[
            {
               "object":{
                  "fileName":"Doritos_Falafel_Bag.usdz",
                  "fileThumbnail":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FFalafel_bag.png?alt=media&token=923782a9-ac1c-4273-9166-120c4d31e1d6",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FModels%2FFinal%2FUn-Animated%2FDoritos_Falafel_Bag.usdz?alt=media&token=23fb3ed1-788f-42c3-a4ed-29f24ab4860f",
                  "fileUrlAndroid":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FModels%2FFinal%2FUn-Animated%2FDoritos_Falafel_Bag.glb?alt=media&token=11c31453-2b2a-4dbd-b760-8a4ba1e4aa79",
                  "settings":{
                     "addPhysicsBody":true,
                     "addToAnalytics":false,
                     "allowUserGestures":false,
                     "isAffectedByGravity":false,
                     "physicsShapeType":2,
                     "scaleAxis":1,
                     "size":0.05
                  }
               },
               "objectImage":{
                  "fileName":"Falafel_bag.png",
                  "fileThumbnail":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FFalafel_bag.png?alt=media&token=923782a9-ac1c-4273-9166-120c4d31e1d6",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FFalafel_bag.png?alt=media&token=923782a9-ac1c-4273-9166-120c4d31e1d6",
                  "fileUrlAndroid":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FFalafel_bag.png?alt=media&token=923782a9-ac1c-4273-9166-120c4d31e1d6",
                  "settings":{
                     "addPhysicsBody":false,
                     "addToAnalytics":false,
                     "allowUserGestures":false,
                     "isAffectedByGravity":false,
                     "physicsShapeType":2,
                     "scaleAxis":0,
                     "size":0.05
                  }
               },
               "objectName":"فلافل"
            },
            {
               "object":{
                  "fileName":"Doritos_Taco_Bag.usdz",
                  "fileThumbnail":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FTaco_bag.png?alt=media&token=e420c79f-2190-4023-be89-ad43e4fe65f6",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FModels%2FFinal%2FUn-Animated%2FDoritos_Taco_Bag.usdz?alt=media&token=70ac8264-c2d2-421d-a22a-dcfc6ad79921",
                  "fileUrlAndroid":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FModels%2FFinal%2FUn-Animated%2FDoritos_Taco_Bag.glb?alt=media&token=bfc30561-4038-40de-a09e-a101e2e3909a",
                  "settings":{
                     "addPhysicsBody":true,
                     "addToAnalytics":false,
                     "allowUserGestures":false,
                     "isAffectedByGravity":false,
                     "physicsShapeType":2,
                     "scaleAxis":1,
                     "size":0.05
                  }
               },
               "objectImage":{
                  "fileName":"Taco_bag.png",
                  "fileThumbnail":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FTaco_bag.png?alt=media&token=e420c79f-2190-4023-be89-ad43e4fe65f6",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FTaco_bag.png?alt=media&token=e420c79f-2190-4023-be89-ad43e4fe65f6",
                  "fileUrlAndroid":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FTaco_bag.png?alt=media&token=e420c79f-2190-4023-be89-ad43e4fe65f6",
                  "settings":{
                     "addPhysicsBody":false,
                     "addToAnalytics":false,
                     "allowUserGestures":false,
                     "isAffectedByGravity":false,
                     "physicsShapeType":2,
                     "scaleAxis":0,
                     "size":0.05
                  }
               },
               "objectName":"تاكو"
            }
         ],
         "shootersListViewTheme":{
            "backgroundImageUrl":"",
            "logo":"",
            "title":"اختر الطّعمة",
            "listType":"largeIcons"
         }
      }
   },
   "styling":{
      "language":"ar"
   },
   "metadata":{
      "description":"دوريتوز",
      "experienceID":"94b19ba545414746a9f84cdd1336ed9a",
      "experienceName":"دوريتوز",
      "experienceType":"BaselBurgerShooters",
      "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FDoritosLogo.png?alt=media&token=5d72db7d-a733-41f6-8deb-ee94825b688d"
   },
   "pageRoutingConfig":[
      {
         "pageName":"welcome",
         "pageParamsName":"welcomeScreenInput"
      },
      {
         "pageName":"game",
         "pageParamsName":"experience"
      },
      {
         "pageName":"registration",
         "pageParamsName":"registrationPageParams"
      },
      {
         "pageName":"postGame"
      }
   ],
   "registrationPageParams":{
      "forceRegisterToThisExperience":true,
      "giftSheetInput":{
         "description":"",
         "imageUrl":"https://storage.googleapis.com/arplatform-99ab9.appspot.com/Production/Experiences/94b19ba545414746a9f84cdd1336ed9a/Images/3dda85a5.jpg?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2554416000&Signature=cPBfZSfrVyoDt8eWAd7q1eOuwoYcUOOFpCWVVKUSo4uyUo50zGUiIQ6axuDBD5pvEECUr77rzH7NMz2pf2AbOxhpBMfan%2FqyKiysY5TPi5q3jwNCK4sEGACF5worc1uyYoccC8nILHsEs75DyiCsHAnOJUquA%2Fe7oMC4Pkdc3AmKDAcWv8TatAHA9DvcHBXQ3JDqlTXMrG2WcTfnd7dWcG3%2BiXMNezvS8If1DfPgXh%2FaR4qn9f6H8Yhy9TinAkOv%2Fp2HO9lTvkdz6lird3uvJNFJn9j5V%2FTyOigqjSKWaOIvjZA130c9PB7b4U%2Feud%2FMOyRiTnHqg2DEgYp1IVq4xw%3D%3D"
      },
      "skipEnabled":false,
      "uploadImage":true,
      "uploadImageDescription":"حابين تربحوا جوائز قيّمة؟ صوّروا كيسين دوريتوس فلافل وتاكو بالبيت، وعبّئوا تفاصيلكم، ويمكن تربحوا!",
      "uploadImageSize":1024,
      "backgroundImageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FMobile_doritos_111.jpg?alt=media&token=b5e7d7f0-e2b1-4684-96dd-ed1ae9fe90b7",
      "policyUrl": "https://storage.googleapis.com/arplatform-99ab9.appspot.com/Production/Experiences/57d38fd4e8a44e60a343100e9b93dd14/PDFs/DoritosTermsOfUse.pdf?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2529691200&Signature=jv9iOxyAnFb3eDu8a6tjLfDBXuj7pUx0%2BoQti%2BgNfpo6%2Bhj01ylMimLtqfOHoWNTFqnu7Ma9KVoIhCrURyvpCVoCAgpPaF5rTZNzLOdsBVfWU0%2FF7XG0u3iUGHof133GKW10B2RxXytsM19C9Yyzp5EUwP7SPDJHv%2BtU4IgEMFKtg6cszQtd9RWEeuJ5YKnL3WvABLgu5JQ6ZlsSvLEE1QxDyCTmCATMfNls3ScWtjNCRYCc1SU9GzRw%2ByTMG5i7PfnGtBAJ2piRtYGQlb1TkMgtfxtWZkri5UmjWhgyR%2BlMCX%2FZoEv1me7%2BwNnHxUy9W8iuJVJ3uAlSF6tF4oVWNg%3D%3D"
   },
   "theme":{
      "imageUrl":"https://storage.googleapis.com/arplatform-99ab9.appspot.com/Production/Businesses/bfd45a26-e640-4755-a6d6-134143cd5ca1/Images/ORBARLogo.png?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2529705600&Signature=Am%2BR0xkWXCBktR3e52GpknS2C6gFhCgPPXky2WcYVqVcUsNwnSbvhdhqVJh0wyktEObT4YVacopFzlcezdItNPfwxOUJaEw5tqg62iDx%2FdL6m7huB9j26AxGSrwwp0LJto7tYhMiNiYTnT1sWSCZoKXAc9teoDh4uvirrTp48KudIP2iyzYkGuVc3LtsmKMBGvEpYQNrXG0JRSLsnNKYxdZmq1CJ8tU7%2FkYXh8I4L3%2Bl1Rpidu4PJBlyT%2BX%2B%2BixV1%2FO4Agv4tt%2BbGwO0S%2FMT3Myr2Db43p3hf8jiYt6Akh4hV7uIQVOb%2Fyd7P7OhtUY1%2FrZ3DKIxUD4S%2FuWcuHesCg%3D%3D",
      "plantLogoOnSurfaces":false,
      "primaryColor":[
         125,
         53,
         130
      ],
      "secondaryColor":[
         60,
         137,
         176
      ]
   },
   "welcomeScreenInput":{
      "description":"",
      "giftSheetInput":{
         "description":"",
         "imageUrl":"https://storage.googleapis.com/arplatform-99ab9.appspot.com/Production/Experiences/94b19ba545414746a9f84cdd1336ed9a/Images/3dda85a5.jpg?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2554416000&Signature=cPBfZSfrVyoDt8eWAd7q1eOuwoYcUOOFpCWVVKUSo4uyUo50zGUiIQ6axuDBD5pvEECUr77rzH7NMz2pf2AbOxhpBMfan%2FqyKiysY5TPi5q3jwNCK4sEGACF5worc1uyYoccC8nILHsEs75DyiCsHAnOJUquA%2Fe7oMC4Pkdc3AmKDAcWv8TatAHA9DvcHBXQ3JDqlTXMrG2WcTfnd7dWcG3%2BiXMNezvS8If1DfPgXh%2FaR4qn9f6H8Yhy9TinAkOv%2Fp2HO9lTvkdz6lird3uvJNFJn9j5V%2FTyOigqjSKWaOIvjZA130c9PB7b4U%2Feud%2FMOyRiTnHqg2DEgYp1IVq4xw%3D%3D"
      },
      "imageUrl":"",
      "notes":[
         
      ],
      "title":"",
      "videoUrl":null,
      "backgroundImageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2F94b19ba545414746a9f84cdd1336ed9a%2FImages%2FDoritosWelcomePageBackground.jpg?alt=media&token=2126d8bf-be68-488f-90a2-385e4052a7cf",
      "policyUrl": "https://storage.googleapis.com/arplatform-99ab9.appspot.com/Production/Experiences/57d38fd4e8a44e60a343100e9b93dd14/PDFs/DoritosTermsOfUse.pdf?GoogleAccessId=firebase-adminsdk-mbgaq%40arplatform-99ab9.iam.gserviceaccount.com&Expires=2529691200&Signature=jv9iOxyAnFb3eDu8a6tjLfDBXuj7pUx0%2BoQti%2BgNfpo6%2Bhj01ylMimLtqfOHoWNTFqnu7Ma9KVoIhCrURyvpCVoCAgpPaF5rTZNzLOdsBVfWU0%2FF7XG0u3iUGHof133GKW10B2RxXytsM19C9Yyzp5EUwP7SPDJHv%2BtU4IgEMFKtg6cszQtd9RWEeuJ5YKnL3WvABLgu5JQ6ZlsSvLEE1QxDyCTmCATMfNls3ScWtjNCRYCc1SU9GzRw%2ByTMG5i7PfnGtBAJ2piRtYGQlb1TkMgtfxtWZkri5UmjWhgyR%2BlMCX%2FZoEv1me7%2BwNnHxUy9W8iuJVJ3uAlSF6tF4oVWNg%3D%3D"
   }
}
`