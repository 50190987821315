import * as THREE from 'three';
import { PhysicsManager } from './PhysicsManager';
import { ActionParameterNamesDict } from '../TriggerAction/Actions';

export interface SpinParams {
    action: string;
    keyPath: string;
    duration: number;
    repeatCount: number;
    fromValue: number[];
    toValue: number[];
}

export class ObjectActionManager {
    objectMovements: Record<string, ObjectMovement[]>;
    movingObjects: Record<string, THREE.Object3D>;

    objectRotations: Record<string, ObjectRotation[]>;
    rotatingObjects: Record<string, THREE.Object3D>;

    objectScalings: Record<string, ObjectScaling[]>;
    scalingObjects: Record<string, THREE.Object3D>;

    objectOpacities: Record<string, ObjectOpacity[]>;
    opacitiesObjects: Record<string, THREE.Mesh>;

    clock: THREE.Clock

    physicsManager: PhysicsManager;

    // objectAccumulatedTransformation: Record<string, THREE.Matrix4> = {}; // Store the accumulated transformation for each object as aresult of moving/scaling/rotating the object

    constructor(physicsManager: PhysicsManager) {
        this.objectMovements = {};      // key: objectID, value: list of movements
        this.movingObjects = {};        // Key: objectID, value: the THREE object itself

        this.objectRotations = {};
        this.rotatingObjects = {};

        this.objectScalings = {};
        this.scalingObjects = {};

        this.objectOpacities = {};
        this.opacitiesObjects = {};

        this.clock = new THREE.Clock()
        this.physicsManager = physicsManager;
    }

    cleanup() {

    }

    tick() {
        const timeDelta = this.clock.getDelta()
        for (const [objectID, movementList] of Object.entries(this.objectMovements)) {
            for (const movement of movementList) {
                const movementDelta = movement.getMovementDelta(timeDelta)
                if (movementDelta === null) {
                    this.removeObjectMovement(objectID, movement)
                } else {
                    const object = this.movingObjects[objectID]
                    // object.position.add(movementDelta)
                    this.moveARObject(object, movementDelta)
                }
            }
        }

        for (const [objectID, rotationList] of Object.entries(this.objectRotations)) {
            for (const rotation of rotationList) {
                if (rotation instanceof ObjectAxisRotation) {
                    const rotationDelta = rotation.getRotationDelta(timeDelta)
                    const rotationAxis = rotation.getRotationAxis()
                    if (rotationDelta === null) {
                        this.removeObjectRotation(objectID, rotation)
                    } else {
                        const object = this.rotatingObjects[objectID]
                        // object.rotateOnAxis(rotationAxis, rotationDelta);
                        this.rotateARObject(object, rotationAxis, rotationDelta);
                    }
                }
            }
        }

        for (const [objectID, scalingList] of Object.entries(this.objectScalings)) {
            for (const scaling of scalingList) {
                if (scaling instanceof ObjectPlainScaling) {
                    const scalingDelta = scaling.getScalingDelta(timeDelta)
                    if (scalingDelta === null) {
                        this.removeObjectScaling(objectID, scaling)
                    } else {
                        const object = this.scalingObjects[objectID]
                        // object.scale.add(scalingDelta)
                        this.scaleARObject(object, scalingDelta)
                    }
                }
            }
        }

        // Handle opacity animations
        for (const [objectID, opacityList] of Object.entries(this.objectOpacities)) {
            for (const opacity of opacityList) {
                const opacityDelta = opacity.getOpacityDelta(timeDelta);
                if (opacityDelta === null) {
                    this.removeObjectOpacity(objectID, opacity);
                } else {
                    const object = this.opacitiesObjects[objectID];
                    this.updateARObjectOpacity(object, opacityDelta);
                }
            }
        }
    }

    // getObjectTransformationWithoutActions(object: THREE.Object3D): THREE.Matrix4 | undefined {
    //     const animationTransformation = this.objectAccumulatedTransformation[object.id]

    //     const originalTransform = new THREE.Matrix4();

    //     // Get the object's world transform
    //     originalTransform.copy(object.matrixWorld);

    //     if (animationTransformation) {
    //         // Create a new matrix for the inverse of the animation transformation
    //         const inverseAnimationMatrix = new THREE.Matrix4().copy(animationTransformation).invert();

    //         // Combine the original transform with the inverse animation transformation
    //         originalTransform.premultiply(inverseAnimationMatrix);
    //     }

    //     // Return the transformed matrix without the animation rotation
    //     return originalTransform;
    // }

    moveARObject(object: THREE.Object3D, movementDelta: THREE.Vector3) {
        object.position.add(movementDelta)
        this.physicsManager.ARObjectMovedManually(object)

        // // Update the accumulated transformation with the new position
        // const currentTransform = this.objectAccumulatedTransformation[object.id];
        // const translationMatrix = new THREE.Matrix4().makeTranslation(movementDelta.x, movementDelta.y, movementDelta.z);
        // currentTransform.multiplyMatrices(currentTransform, translationMatrix);
    }

    rotateARObject(object: THREE.Object3D, rotationAxis: THREE.Vector3, rotationDelta: number) {
        object.rotateOnAxis(rotationAxis, rotationDelta);
        this.physicsManager.ARObjectRotatedManually(object)

        // // Accumulate the rotation effect
        // const deltaQuaternion = new THREE.Quaternion();
        // deltaQuaternion.setFromAxisAngle(rotationAxis, rotationDelta);
        // const currentTransform = this.objectAccumulatedTransformation[object.id];
        // const rotationMatrix = new THREE.Matrix4().makeRotationFromQuaternion(deltaQuaternion);
        // currentTransform.multiplyMatrices(currentTransform, rotationMatrix);
    }

    scaleARObject(object: THREE.Object3D, scalingDelta: THREE.Vector3) {
        object.scale.add(scalingDelta)
        this.physicsManager.ARObjectScaledManually(object)

        // // Update the accumulated transformation with the new scale
        // const currentTransform = this.objectAccumulatedTransformation[object.id];
        // const scalingMatrix = new THREE.Matrix4().makeScale(scalingDelta.x, scalingDelta.y, scalingDelta.z);
        // currentTransform.multiplyMatrices(currentTransform, scalingMatrix);
    }

    // Method to update AR object opacity
    updateARObjectOpacity(object: THREE.Mesh, opacityDelta: number) {
        if (object.material instanceof THREE.Material) {
            object.material.opacity += opacityDelta;
            object.material.transparent = true; // Ensure transparency is enabled
        }
    }

    addActionToObject(object: THREE.Object3D, action: ObjectAction) {
        if (action instanceof ObjectMovement) {
            this.addMovementToObject(object, action)
        } else if (action instanceof ObjectRotation) {
            this.addRotationToObject(object, action)
        } else if (action instanceof ObjectScaling) {
            this.addScalingToObject(object, action)
        } else if (action instanceof ObjectOpacity && object instanceof THREE.Mesh) {
            this.addOpacityToObject(object, action)
        }

        // if (!this.objectAccumulatedTransformation[object.id]) {
        //     this.objectAccumulatedTransformation[object.id] = new THREE.Matrix4();
        // }
    }

    addMovementToObject(object: THREE.Object3D, movement: ObjectMovement) {
        this.movingObjects[object.id] = object
        if (object.id in this.objectMovements) {
            this.objectMovements[String(object.id)].push(movement)
        } else {
            this.objectMovements[String(object.id)] = [movement]
        }

    }

    addRotationToObject(object: THREE.Object3D, rotation: ObjectRotation) {
        this.rotatingObjects[object.id] = object
        if (object.id in this.objectRotations) {
            this.objectRotations[String(object.id)].push(rotation)
        } else {
            this.objectRotations[String(object.id)] = [rotation]
        }
    }

    addScalingToObject(object: THREE.Object3D, scaling: ObjectScaling) {
        this.scalingObjects[object.id] = object
        if (object.id in this.objectScalings) {
            this.objectScalings[String(object.id)].push(scaling)
        } else {
            this.objectScalings[String(object.id)] = [scaling]
        }
    }

    // Method to add opacity action to an object
    addOpacityToObject(object: THREE.Mesh, opacity: ObjectOpacity) {
        this.opacitiesObjects[object.id] = object;
        if (object.id in this.objectOpacities) {
            this.objectOpacities[object.id].push(opacity);
        } else {
            this.objectOpacities[object.id] = [opacity];
        }
    }

    removeObjectMovement(objectID: string, movement: ObjectMovement) {
        // delete this.objectMovements[objectID]
        const index = this.objectMovements[objectID].indexOf(movement);
        this.objectMovements[objectID].splice(index, 1);

        if (this.objectMovements[objectID].length == 0) {
            delete this.movingObjects[objectID]
        }

    }

    removeObjectRotation(objectID: string, rotation: ObjectRotation) {
        // delete this.objectRotations[objectID]
        const index = this.objectRotations[objectID].indexOf(rotation);
        this.objectRotations[objectID].splice(index, 1);

        if (this.objectRotations[objectID].length == 0) {
            delete this.rotatingObjects[objectID]
        }
    }

    removeObjectScaling(objectID: string, scaling: ObjectScaling) {
        // delete this.objectScalings[objectID]
        const index = this.objectScalings[objectID].indexOf(scaling);
        this.objectScalings[objectID].splice(index, 1);

        if (this.objectScalings[objectID].length == 0) {
            delete this.scalingObjects[objectID]
        }
    }

    // Remove opacity from object
    removeObjectOpacity(objectID: string, opacity: ObjectOpacity) {
        const index = this.objectOpacities[objectID].indexOf(opacity);
        this.objectOpacities[objectID].splice(index, 1);
        if (this.objectOpacities[objectID].length == 0) {
            delete this.opacitiesObjects[objectID];
        }
    }

    addAnimationToObject(object: THREE.Object3D, animation: ObjectAnimation) {
        var action: ObjectAction
        if (animation.keyPath == "rotation" && animation.fromValue instanceof THREE.Vector4 && animation.toValue instanceof THREE.Vector4) {
            action = new ObjectAxisRotation(new THREE.Vector3(animation.toValue.x, animation.toValue.y, animation.toValue.z),
                (animation.toValue.w - animation.fromValue.w) * animation.repeatCount,
                (animation.duration) * animation.repeatCount)
        }
        // else if (object instanceof THREE.Mesh && animation.keyPath == "material.opacity" && animation.fromValue instanceof Number && animation.toValue instanceof Number) {

        // }

        this.addActionToObject(object, action);
    }
}

export class ObjectAnimation {
    keyPath: string
    duration: number
    repeatCount: number
    fromValue: THREE.Vector4 | number
    toValue: THREE.Vector4 | number

    constructor(actionDataDict: any) {
        this.keyPath = actionDataDict[ActionParameterNamesDict.animate.keyPath]
        this.duration = actionDataDict[ActionParameterNamesDict.animate.duration]
        this.repeatCount = actionDataDict[ActionParameterNamesDict.animate.repeatCount]

        const fromValueVec = actionDataDict[ActionParameterNamesDict.animate.fromValue]
        const toValueVec = actionDataDict[ActionParameterNamesDict.animate.toValue]

        if (Array.isArray(fromValueVec)) {
            this.fromValue = new THREE.Vector4(fromValueVec[0], fromValueVec[1], fromValueVec[2], fromValueVec[3])
            this.toValue = new THREE.Vector4(toValueVec[0], toValueVec[1], toValueVec[2], toValueVec[3])
        } else if (typeof fromValueVec === "number" && typeof toValueVec === "number") {
            this.fromValue = fromValueVec
            this.toValue = toValueVec
        }
    }
}

export class ObjectAction {
    totalTime: number
    isDone: boolean

    constructor() {
        this.totalTime = 0;
        this.isDone = false
    }
}

class ObjectMovement extends ObjectAction {
    constructor() {
        super()
    }

    // Abstract method to be overridden by derived classes
    getMovementDelta(timeDelta: number): THREE.Vector3 | null {
        throw new Error("Subclasses must implement applyMovementToObject method");
    }
}

class ObjectRotation extends ObjectAction {
    constructor() {
        super()
    }

    getRotationDelta(timeDelta: number): number | null {
        throw new Error("Subclasses must implement applyMovementToObject method");
    }
}

class ObjectScaling extends ObjectAction {
    constructor() {
        super()
    }

    getScalingDelta(timeDelta: number): THREE.Vector3 | null {
        throw new Error("Subclasses must implement applyMovementToObject method");
    }
}

class ObjectOpacity extends ObjectAction {
    constructor() {
        super()
    }

    getOpacityDelta(timeDelta: number): number | null {
        throw new Error("Subclasses must implement getOpacityDelta method");
    }
}

export class ObjectStraightMovement extends ObjectMovement {
    direction: THREE.Vector3
    distance: number
    time: number
    cummulativeDistance: number

    constructor(direction: THREE.Vector3, distance: number, time: number) {
        super();
        this.direction = direction
        this.distance = distance
        this.time = time
        this.cummulativeDistance = 0;
    }

    getMovementDelta(timeDelta: number): THREE.Vector3 | null {
        if (this.isDone) {
            return null
        }

        if (this.time == 0) {
            this.isDone = true
            return new THREE.Vector3(this.distance, this.distance, this.distance);
        }

        var translationVec = new THREE.Vector3();
        translationVec.copy(this.direction)

        var distanceDelta = (timeDelta / this.time) * this.distance // Calculate the amount of distance that the object should move within timeDelta seconds
        this.cummulativeDistance += distanceDelta
        this.totalTime += timeDelta
        translationVec.multiplyScalar(distanceDelta)

        if (this.cummulativeDistance >= this.distance) {
            this.isDone = true
        }

        return translationVec
    }
}

export class ObjectRandomStraightMovements extends ObjectMovement {
    direction: THREE.Vector3;
    distance: number;
    time: number;
    cummulativeDistance: number;
    changeDirectionInterval: number;
    timeSinceLastDirectionChange: number;
    // If a direction is given, then use this direction as the first direction
    constructor(time: number, changeDirectionInterval: number = 1, speedFactor: number = 1, direction: THREE.Vector3 | null = null) {
        super();
        this.direction = direction ?? this.generateRandomUnitVector();
        this.distance = (time / changeDirectionInterval) * (speedFactor);
        this.time = time;
        this.cummulativeDistance = 0;
        this.changeDirectionInterval = changeDirectionInterval; // Interval at which the direction changes
        this.timeSinceLastDirectionChange = 0;
    }

    generateRandomUnitVector(): THREE.Vector3 {
        const theta = Math.random() * Math.PI * 2;
        const phi = Math.acos(2 * Math.random() - 1);
        const x = Math.sin(phi) * Math.cos(theta);
        const y = Math.sin(phi) * Math.sin(theta);
        const z = Math.cos(phi);
        return new THREE.Vector3(x, y, z).normalize();
    }

    getMovementDelta(timeDelta: number): THREE.Vector3 | null {
        if (this.isDone) {
            return null;
        }

        if (this.time === 0) {
            this.isDone = true;
            return new THREE.Vector3(this.distance, this.distance, this.distance);
        }

        this.timeSinceLastDirectionChange += timeDelta;
        if (this.timeSinceLastDirectionChange >= this.changeDirectionInterval) {
            this.direction = this.generateRandomUnitVector();
            this.timeSinceLastDirectionChange = 0;
        }

        const translationVec = new THREE.Vector3().copy(this.direction);
        const distanceDelta = (timeDelta / this.time) * this.distance; // Calculate the amount of distance that the object should move within timeDelta seconds
        this.cummulativeDistance += distanceDelta;
        this.totalTime += timeDelta;
        translationVec.multiplyScalar(distanceDelta);

        if (this.cummulativeDistance >= this.distance) {
            this.isDone = true;
        }

        return translationVec;
    }
}

export class ObjectAxisRotation extends ObjectRotation {
    axis: THREE.Vector3
    angle: number
    time: number
    cummulativeAngle: number

    constructor(axis: THREE.Vector3, angle: number, time: number) {
        super();
        this.axis = axis
        this.angle = angle
        this.time = time
        this.cummulativeAngle = 0;
    }

    getRotationDelta(timeDelta: number): number | null {
        if (this.isDone) {
            return null
        }

        if (this.time == 0) {
            this.isDone = true
            return this.angle;
        }

        var angleDelta = (timeDelta / this.time) * this.angle // Calculate the angle that the object should rotate in within timeDelta seconds
        this.cummulativeAngle += angleDelta
        this.totalTime += timeDelta

        if (this.cummulativeAngle >= this.angle) {
            this.isDone = true
        }

        return angleDelta
    }

    getRotationAxis(): THREE.Vector3 {
        return this.axis
    }
}

export class ObjectPlainScaling extends ObjectScaling {
    scale: number
    time: number
    cummulativeScale: number

    constructor(scale: number, time: number) {
        super();
        this.scale = scale
        this.time = time
        this.cummulativeScale = 0;
    }

    getScalingDelta(timeDelta: number): THREE.Vector3 | null {
        if (this.isDone) {
            return null
        }

        if (this.time == 0) {
            this.isDone = true
            return new THREE.Vector3(this.scale, this.scale, this.scale);
        }

        var currScale = 1
        var scalingVec = new THREE.Vector3(currScale, currScale, currScale);
        if (this.scale >= 1) {
            currScale = (timeDelta / this.time) * (this.scale - 1) // Calculate the angle that the object should rotate in within timeDelta seconds
            this.cummulativeScale += currScale

            if (this.cummulativeScale >= this.scale - 1) {
                this.isDone = true
            }

            console.log("Current scale", currScale)
            console.log("this.cummulativeScale", this.cummulativeScale)
            // scalingVec = new THREE.Vector3(1+this.cummulativeScale, 1+this.cummulativeScale, 1+this.cummulativeScale);
            scalingVec = new THREE.Vector3(currScale, currScale, currScale);
        } else {
            currScale = (timeDelta / this.time) * (1 - this.scale)

            this.cummulativeScale += currScale
            if (this.cummulativeScale >= 1 - this.scale) {
                this.isDone = true
            }

            // scalingVec = new THREE.Vector3(1-this.cummulativeScale, 1-this.cummulativeScale, 1-this.cummulativeScale);
            scalingVec = new THREE.Vector3(-currScale, -currScale, -currScale);
        }

        this.totalTime += timeDelta
        return scalingVec
    }
}

export class ObjectLinearOpacity extends ObjectOpacity {
    startOpacity: number;
    endOpacity: number;
    duration: number;
    currentOpacity: number;
    elapsedTime: number;

    constructor(startOpacity: number, endOpacity: number, duration: number) {
        super();
        this.startOpacity = startOpacity;
        this.endOpacity = endOpacity;
        this.duration = duration;
        this.currentOpacity = startOpacity;
        this.elapsedTime = 0;
    }

    getOpacityDelta(timeDelta: number): number | null {
        if (this.isDone) {
            return null;
        }

        // If no time to animate, instantly finish
        if (this.duration == 0) {
            this.isDone = true;
            return this.endOpacity - this.startOpacity;
        }

        // Calculate the opacity change per timeDelta
        const opacityDelta = (timeDelta / this.duration) * (this.endOpacity - this.startOpacity);
        this.currentOpacity += opacityDelta;
        this.elapsedTime += timeDelta;

        // Clamp the opacity within the bounds of startOpacity and endOpacity
        if (this.elapsedTime >= this.duration) {
            this.currentOpacity = this.endOpacity;
            this.isDone = true;
        }

        return opacityDelta;
    }
}