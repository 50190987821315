// styled.ts
import styled from "styled-components";

export const FormContainer = styled.div`
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 15px;
`;

export const FormGroup = styled.div`
  margin-bottom: 15px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

export const InputField = styled.input`
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 10px;
`;

export const InnerWrapper = styled.div`
  position: absolute;
  bottom: 12%;
  margin-bottom: 10px;
  overflow-y: auto;
  padding: 2%;
  left: 0;
  &::-webkit-scrollbar-track {
    background-color: lightgrey;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 8px;
    margin-right: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: darkgrey;
  }
`;
