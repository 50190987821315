import styled from "styled-components";

export const MainWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 10px;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  text-decoration: underline;
  text-align: center;
  margin-top: 0;
`;

export const Message = styled.p`
  font-size: 18px;
  text-align: center;
  margin: 10px 0;
`;

export const ScoreBox = styled.div<{ primaryColor: string }>`
  font-size: 18px;
  padding: 10px 20px;
  text-align: center;
  color: black; /* Text color matches border color */
  border: 2px solid ${(props) => props.primaryColor}; /* Add border with primary color */
  border-radius: 10px;
  margin: 10px 0;
`;

export const ButtonWrapper = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`;


export const LottieContainer = styled.div`
  width: 60%;
  height: auto;
  margin: auto;
  margin-top: 8%;
`;