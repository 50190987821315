import { createRoot } from "react-dom/client";
import { ARObjectLoader } from "../../ExperienceObjects/ExperienceObject";
import { pubsub } from "../../TriggerAction/TriggerManager";
import { Trigger, TriggerType } from "../../TriggerAction/Triggers";
import { loadTexture } from "../../utils";
import { PlatformManager } from "../CustomTemplate/PlatformManager";
import { ITemplatesBasics } from "../TemplatesInterface";
import { IPortalTemplateConfig, PortalCategory, PortalTemplateConfig, RectanglePortal, SpherePortal } from "./PortalTemplateInput";
import * as THREE from 'three';

interface IPortalTemplate extends ITemplatesBasics { }

export class PortalTemplate
    extends PlatformManager
    implements IPortalTemplate {

    config: PortalTemplateConfig;

    menuElement: HTMLElement | null = null;

    currentPortal: THREE.Object3D = null

    /////// External UI
    setShowMenu: (status: boolean) => void | null = null;

    ///////////// Parse input json //////////////////
    static async parseInput(
        json: Record<string, any>
    ): Promise<PortalTemplateConfig> {
        const networkConfig: IPortalTemplateConfig = json as IPortalTemplateConfig;
        const spherePortals: SpherePortal[] = [];
        const rectanglePortals: RectanglePortal[] = [];
        var portalGate: THREE.Object3D

        for (const networkItem of networkConfig.spherePortals) {
            const textureImage = await loadTexture(networkItem.textureImage.fileUrl);
            spherePortals.push(new SpherePortal(networkItem, textureImage))
        }

        for (const networkItem of networkConfig.rectanglePortals) {
            const loader = new ARObjectLoader(networkItem.scene)
            if (loader) {
                const scene = await loader.loadARObject()
                rectanglePortals.push(new RectanglePortal(networkItem, scene))
            }
        }

        if (networkConfig.portalGate) {
            const loader = new ARObjectLoader(networkConfig.portalGate)
            if (loader) {
                portalGate = await loader.loadARObject()
            }
        }

        const config = new PortalTemplateConfig(spherePortals, rectanglePortals, networkConfig, portalGate);
        return config;
    }

    startExperience(): void {
        super.startExperience();

        this.setup()
    }

    setup() {
        this.addSubscribers()

        if (this.config.spherePortals.length == 1 && this.config.rectanglePortals.length == 0) {
            this.placeSphericalPortal(0)
        } else if (this.config.spherePortals.length == 0 && this.config.rectanglePortals.length == 1) {
            this.placeRectangularPortal(0)
        } else {
            this.presentMenu()
        }
    }

    removeExistingPortal() {
        if (this.currentPortal) {
            this.ARManager.removeARObjectFromScene(this.currentPortal)
        }
    }

    placeSphericalPortal(index: number) {
        if (index >= this.config.spherePortals.length) return

        const sphericalPortal = this.config.spherePortals[index]
        const pos = this.ARManager.getPointInFrontOfUser(2)
        const portal = this.ARManager.createTexturedPortalSphere(sphericalPortal.textureImage, sphericalPortal.radius)
        portal.position.set(pos.x, pos.y, pos.z)

        if (this.config.portalGate) {
            const gateObject = this.config.portalGate
            const boundingBox = new THREE.Box3();
            boundingBox.setFromObject(gateObject);
            const gateDepth = boundingBox.max.z - boundingBox.min.z;
            const gatePos = this.ARManager.getPointInFrontOfUser(-sphericalPortal.radius-gateDepth/2)
            gateObject.position.set(gatePos.x, gatePos.y, gatePos.z)
            portal.add(gateObject)
        }

        this.removeExistingPortal()
        this.ARManager.addARObjectToScene(portal)
        this.currentPortal = portal
    }

    placeRectangularPortal(index: number) {
        if (index >= this.config.rectanglePortals.length) return
        const rectanglePortal = this.config.rectanglePortals[index]
        const pos = this.ARManager.getPointInFrontOfUser(rectanglePortal.distFromUser)

        const portal = this.ARManager.createInvisibleRectangularWall(rectanglePortal.width, rectanglePortal.height, rectanglePortal.depth, true)
        portal.position.set(pos.x, pos.y, pos.z)
        
        let scene = rectanglePortal.scene
        let floorNode = portal.getObjectByName("floor")
        if (floorNode) {
            scene.position.set(floorNode.position.x, floorNode.position.y, floorNode.position.z)
        }
        portal.add(scene)

        this.removeExistingPortal()
        this.ARManager.addARObjectToScene(portal)
        this.currentPortal = portal
    }

    addSubscribers() {
        this.subscribeToButtonTaps()
    }

    subscribeToButtonTaps() {
        const buttonTapTriggerDict = { "trigger": TriggerType.onButtonTap, "id": "menuButton" }
        const buttonTapTrigger = new Trigger(buttonTapTriggerDict)

        pubsub.subscribe(buttonTapTrigger.description, buttonTapTrigger.description, this.onButtonTapFunc.bind(this))
    }

    onButtonTapFunc(triggerID: string, experienceObjectName: string, data: Record<string, any>) {
        if (!("buttonID" in data)) {
            return
        }
        const buttonID = data.buttonID
        switch (buttonID) {
            case "menuButton":
                this.presentMenu()
                break;
        }
    }

    presentMenu() {
        this.setShowMenu && this.setShowMenu(true);
        // const container = document.querySelector("#overlay-container");
        // if (container) {
        //     const newElement = document.createElement('div');
        //     this.menuElement = newElement
        //     const root = createRoot(newElement);

        //     const props: IPortalSelectionMenuProps = {
        //         config: this.config,
        //         selectionCallback: (category, index) => {
        //             this.handleMenuSelection(category, index)
        //         },
        //         onClose: () => {
        //             this.closeMenu()
        //         }
        //     };
        //     root.render(
        //         React.createElement(PortalSelectionMenu, props)
        //     );
        //     container.appendChild(newElement);
        // }
    }

    closeMenu() {
        this.setShowMenu && this.setShowMenu(false);
        // if (this.menuElement) {
        //     this.menuElement.remove(); // Remove the menu element from the DOM
        //     this.menuElement = null; // Reset the reference to prevent memory leaks
        // }
    }

    handleMenuSelection(category: PortalCategory, index: number) {
        this.closeMenu()

        switch (category) {
            case PortalCategory.Spherical:
                this.placeSphericalPortal(index)
                break;
            case PortalCategory.Rectangular:
                this.placeRectangularPortal(index)
                break;
        }
    }
}

export enum PlaneDirection {
    XPositive,
    XNegative,
    YPositive,
    YNegative,
    ZPositive,
    ZNegative,
}