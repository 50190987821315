import React, { useState } from "react"
import { ORBButtonUI, ORBImageUI, ORBLottieUI, ORBStatsUI, UIElementInteractionType, UIElementProps, UIElementType } from "../../Managers/UIManager"
import { TriggerManager } from "../../TriggerAction/TriggerManager";
import { StatsComponent } from "../../UIElements/progress-circle/StatsComponent";
import { CloseOutlined } from "@ant-design/icons";
import { FullScreenLottieContainer, LottieContainer } from "../pages/experience-page/styled";
import Lottie from "lottie-react";
import './styled.css';

export type UIObjectesRendererDelegate = {
    placeUIObject: (uiObject: UIElementProps) => void;
    removeUIElement: (tag: string) => void;
    hideUIElement: (tag: string) => void;
    unhideUIElement: (tag: string) => void;
    updateCounter: (delta: number) => void;
    fetchScore: () => number;
    updateTimer: (delta: number) => void;
    placeScopeImage: () => void;
    placeLottie: (lottieName: string | null) => void;
    placeFullScreenLottie: (lottieName: string | null, displayTime: number | null) => void;
    hideScopeImage: () => void;
    cleanup: () => void;
};

interface ISetStatsParams {
    initTime: number;
    callBackFun: (timeLeft: number) => void; // used for time-end
    scoreChangeCallback: (newScore: number) => void;
}

export const TemplateUIOBjectsRenderer = React.forwardRef<UIObjectesRendererDelegate, React.PropsWithChildren<{ triggerManager: TriggerManager }>>(({ triggerManager }, ref) => {

    const [UIElements, setUIElements] = useState<Record<string, React.ReactElement>>({})
    const [showScopeImage, setShowScopeImage] = useState<boolean>(false);
    const [statsParams, setStatsParams] = useState<ISetStatsParams | null>(null);
    const [score, setScore] = useState<number>(0);
    const [timeDelta, setTimeDelta] = useState<number>(0);
    const [lottieData, setLottieData] = useState<any>(null);
    const [fullScreenLottieData, setFullScreenLottieData] = useState<any>(null);

    var nonStateScore: number = 0
    let timeoutId: NodeJS.Timeout | null = null; // Store the timeout ID

    const placeUIObject = (uiObject: UIElementProps) => {
        const styleDict = getUIObjectSizeAndLoc(uiObject);
        switch (uiObject.type) {
            case UIElementType.button:
                const buttonObject = uiObject as ORBButtonUI;
                const newButton = buttonObject.propsDict.imageUrl ? (
                    // Render an <img> element if imageUrl exists
                    <img
                        src={buttonObject.propsDict.imageUrl}
                        id={buttonObject.tag}
                        className={buttonObject.addStartAnimation ? 'pulse-button' : undefined} // Add pulse animation css if the flag is true
                        style={{
                            ...styleDict
                        }}
                        onClick={() => UIElementInteraction(uiObject.tag, uiObject.type, UIElementInteractionType.click)}
                        onTouchEnd={() => UIElementInteraction(uiObject.tag, uiObject.type, UIElementInteractionType.release)}
                        onTouchStart={() => UIElementInteraction(uiObject.tag, uiObject.type, UIElementInteractionType.holdDown)}
                    />
                ) : (
                    // Render a <button> element if no imageUrl exists
                    <button
                        id={buttonObject.tag}
                        className={buttonObject.addStartAnimation ? 'pulse-button' : undefined} // Add pulse animation css if the flag is true
                        style={{
                            ...styleDict,
                        }}
                        onClick={() => UIElementInteraction(uiObject.tag, uiObject.type, UIElementInteractionType.click)}
                        onTouchEnd={() => UIElementInteraction(uiObject.tag, uiObject.type, UIElementInteractionType.release)}
                        onTouchStart={() => UIElementInteraction(uiObject.tag, uiObject.type, UIElementInteractionType.holdDown)}
                    >
                    </button>
                );
                addUIElement(buttonObject.tag, newButton);
                break;
            case UIElementType.image:
                const imageObject = uiObject as ORBImageUI;
                const newImage = <img
                    src={imageObject.propsDict.imageUrl}
                    id={imageObject.tag}
                    style={{
                        ...styleDict
                    }}
                />
                addUIElement(imageObject.tag, newImage);
                break;
            case UIElementType.stats:
                const statsObject = uiObject as ORBStatsUI;
                setStatsParams({
                    initTime: statsObject.maxTime,
                    callBackFun: handleTimeEnd,
                    scoreChangeCallback: (score: number) => handleScoreChange(statsObject.tag, score)
                })
                break;
            case UIElementType.lottie:
                const lottieObject = uiObject as ORBLottieUI;
                if (lottieObject) {
                    const lottieName = lottieObject.lottieUrl == "" ? null : lottieObject.lottieUrl;
                    placeLottie(lottieName);
                }
                break;
            case UIElementType.joystick:
                break;
        }
    }

    const addUIElement = (tag: string, newElement: React.ReactElement) => {
        setUIElements((prevUIElements) => ({
            ...prevUIElements,
            [tag]: newElement
        }));
    }

    const removeUIElement = (tag: string) => {
        setUIElements((prevUIElements) => {
            const { [tag]: _, ...newUIElements } = prevUIElements; // Destructure to exclude the specified tag
            return newUIElements; // Return the new state without the removed element
        });
    }

    const hideUIElement = (tag: string) => {
        setUIElements((prevUIElements) => {
            const element = prevUIElements[tag];

            // Check if the element exists and clone its style to update
            if (element) {
                const updatedElement = React.cloneElement(element, {
                    style: { ...element.props.style, display: 'none' }
                });

                return {
                    ...prevUIElements,
                    [tag]: updatedElement, // Update the specific element's style
                };
            }
            return prevUIElements; // Return the previous state if the tag doesn't exist
        });
    };

    const unhideUIElement = (tag: string) => {
        setUIElements((prevUIElements) => {
            const element = prevUIElements[tag];

            // Check if the element exists and clone its style to update
            if (element) {
                const updatedElement = React.cloneElement(element, {
                    style: { ...element.props.style, display: 'inline' } // Change to 'inline' to unhide
                });

                return {
                    ...prevUIElements,
                    [tag]: updatedElement, // Update the specific element's style
                };
            }
            return prevUIElements; // Return the previous state if the tag doesn't exist
        });
    };

    const cleanup = () => {

    }

    const UIElementInteraction = (
        tag: string,
        elementType: UIElementType,
        interactionType: UIElementInteractionType
    ) => {
        triggerManager.UIElementInteraction(tag, elementType, interactionType);
    }

    const getUIObjectSizeAndLoc = (uiObject: UIElementProps): Record<string, any> => {
        const styleDict: Record<string, any> = {}
        styleDict['position'] = 'absolute'
        styleDict['transform'] = 'translate(-50%, -50%)'

        if (uiObject.locX <= 1) {
            styleDict['left'] = `${uiObject.locX * 100}%`;
        } else {
            styleDict['left'] = `${uiObject.locX}px`;
        }

        if (uiObject.locY <= 1) {
            styleDict['top'] = `${uiObject.locY * 100}%`;
        } else {
            styleDict['top'] = `${uiObject.locY}px`;
        }

        if (uiObject.width <= 1) {
            styleDict['width'] = `${uiObject.width * 100}%`;
        } else {
            styleDict['width'] = `${uiObject.width}px`;
        }

        if (uiObject.height <= 1) {
            styleDict['height'] = `${uiObject.height * 100}%`;
        } else {
            styleDict['height'] = `${uiObject.height}px`;
        }

        return styleDict;
    }

    /////// Stats-related functions
    const updateTimer = (delta: number) => {
        setTimeDelta((prev: number) => {
            return prev - delta;
        });
    }
    const updateCounter = (delta: number) => {
        setScore((prev: any) => {
            nonStateScore = prev + delta;
            return prev + delta;
        });
    }
    const fetchScore = (): number => {
        return nonStateScore
    }
    const handleTimeEnd = (timeLeft: number) => {
        if (timeLeft <= 1) {
            triggerManager.timeIsUp();
        } else if (timeLeft <= 5) {
            triggerManager.timeIsAlmostUp(timeLeft)
        }
    }
    const handleScoreChange = (counterID: string, newScore: number) => {
        triggerManager.scoreChange(counterID, newScore);
    }

    ////// Lottie element
    const placeLottie = async (lottieName: string | null) => {
        if (lottieName === null) {
            // Remove the lottie view
            setLottieData(null)
        } else {
            const response = await fetch(`/assets/Lottie/${lottieName}.json`);
            const data = await response.json();
            setLottieData(data)
        }
    }

    const placeFullScreenLottie = async (lottieUrl: string | null, displayTime: number) => {
        if (lottieUrl === null) {
            // Remove the lottie view
            setFullScreenLottieData(null)
            if (timeoutId) {
                clearTimeout(timeoutId);
                timeoutId = null;
            }
        } else {
            const response = await fetch(lottieUrl);
            const data = await response.json();
            setFullScreenLottieData(data);

            // Clear the previous timeout if it exists
            if (timeoutId) {
                clearTimeout(timeoutId);
            }

            // If displayTime is not null, set it to null after the given display time
            if (displayTime > 0) {
                timeoutId = setTimeout(() => {
                    setFullScreenLottieData(null);
                    timeoutId = null; // Reset the timeout ID
                }, displayTime * 1000);
            }
        }
    }

    ////// Aux functions
    const placeScopeImage = () => {
        setShowScopeImage(true)
    }

    const hideScopeImage = () => {
        setShowScopeImage(false)
    }

    React.useImperativeHandle(ref, () => ({
        placeUIObject: (uiObject: UIElementProps) => placeUIObject(uiObject),
        removeUIElement: (tag: string) => removeUIElement(tag),
        hideUIElement: (tag: string) => hideUIElement(tag),
        unhideUIElement: (tag: string) => unhideUIElement(tag),
        updateCounter: (delta: number) => updateCounter(delta),
        fetchScore: () => fetchScore(),
        updateTimer: (delta: number) => updateTimer(delta),
        placeScopeImage: () => placeScopeImage(),
        hideScopeImage: () => hideScopeImage(),
        placeLottie: (lottieName: string | null) => placeLottie(lottieName),
        placeFullScreenLottie: async (lottieName: string | null, displayTime: number | null) => placeFullScreenLottie(lottieName, displayTime),
        cleanup: () => cleanup(),
    }));


    return (
        <>
            {
                statsParams &&
                <StatsComponent
                    seconds={statsParams.initTime}
                    score={score}
                    timeDelta={timeDelta}
                    callback={statsParams.callBackFun}
                    scoreChangeCallback={statsParams.scoreChangeCallback}
                />
            }
            {
                lottieData !== null &&
                <LottieContainer id="lottie-container">
                    <Lottie animationData={lottieData} />
                </LottieContainer>
            }
            {
                fullScreenLottieData !== null &&
                <FullScreenLottieContainer>
                    <Lottie animationData={fullScreenLottieData} />
                </FullScreenLottieContainer>
            }
            {
                Object.entries(UIElements).map(([key, element]) => (
                    <div key={key}>
                        {element}
                    </div>
                ))
            }
            {
                showScopeImage && (
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}>
                        <CloseOutlined />
                    </div>
                )
            }
        </>
    )
})