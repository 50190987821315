import { PropsWithChildren, createContext, useContext, useState } from "react";
import { PortalTemplate } from "../Templates/PortalTemplate/PortalTemplate";

const PortalTemplateContext = createContext<any>(null)

type PortalTemplateProviderProps = PropsWithChildren & { template: PortalTemplate }
const PortalTemplateProvider = ({ children, template }: PortalTemplateProviderProps) => {
    const [showMenu, setShowMenu] = useState<boolean>(false)

    template.setShowMenu = setShowMenu

    return <PortalTemplateContext.Provider value={{ showMenu, setShowMenu, template }}>{children}</PortalTemplateContext.Provider>
}

interface PortalTemplateContextValues {
    template: PortalTemplate,
    showMenu: boolean,
    setShowMenu: (status: boolean) => void
}
export const usePortalTemplateProvider = (): PortalTemplateContextValues => {
    const context = useContext(PortalTemplateContext);
    if (context === undefined) {
        throw new Error('useGlobalProvider must be used within an GlobalProvider');
    }
    return context;
};
export default PortalTemplateProvider