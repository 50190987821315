import styled from "styled-components";
import { lighten } from "polished";

export const StyledLeaderboardContainer = styled.div`
  width: 100%;
  height: 90%;
  color: black;
`;

export const Podium = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
`;

export const PodiumContainer = styled.div<{
  primaryColor: string;
  secondaryColor: string;
}>`
  background: ${({ primaryColor, secondaryColor }) =>
    `linear-gradient(to bottom, ${lighten(0.1, primaryColor)}, ${lighten(
      0.1,
      secondaryColor
    )})`};
  margin-bottom: 10px;
  border-radius: 10px 10px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 0px 5px, rgba(0, 0, 0, 0.23) 0px 4px 6px;
  display: flex;
  flex-direction: column;
`;

export const PodiumColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 30%;
`;

export const PodiumColumn = styled.div<{ height: number; bgColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #000;
  margin: 0 10px;
  width: 100%;
  box-sizing: border-box;
  height: ${({ height }) => height}px;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 18px 18px 0px 0px;
  font-weight: bold;
  font-size: 1.5rem;
  color: white;
`;

export const LeaderboardRows = styled.div`
  width: 100%;
  padding: 0;
  max-height: 270px;
  overflow-y: scroll;
`;

export const LeaderboardRow = styled.div<{ color: string }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ color }) => color};
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 75%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const Span = styled.span`
  font-weight: bold;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 20%;
  margin-bottom: 15px;
  line-height: 5px;
  & > img {
    width: 70px;
    height: 70px;
  }
`;
