import styled from "styled-components";
import { Button } from "antd";

export const PageCloseButton = styled(Button)`
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 99;
  color: black;
  background-color: #c0c0c0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
