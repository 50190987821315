import { NetworkARObjectDict } from "../ExperienceObjects/ExperienceObject";
import { IUIElementPropsDict } from "../Managers/UIManager";
// export const JsonKeys = {metadata: "metadata", theme: "theme", pageRoutingConfigKey: "pageRoutingConfig"}
// export const OutputJsonKeys = {userID: "userID", metadata: "metadata", analytics: "analytics"}
// export const MetadataKeys = {experienceIDKey: "experienceID", experienceTypeKey: "experienceType"}
// export const BrandThemeKeys = {imageUrlKey: "imageUrl", primaryColorKey: "primaryColor", secondaryColorKey: "secondaryColor", plantLogoOnSurfacesKey: "plantLogoOnSurfaces"}
// export const ActivityKeys = {triggerKey: "trigger", actionsKey: "actions", idKey: "id", maxExecutionsKey: "maxExecutions"}
// export const GeneralRuleKeys = {activityKey: "activity"}
// export const ExperienceObjectKeys = {idKey: "id", nameKey: "name", objectKey: "object", activitiesKey: "activities", maxExecutionsKey: "maxExecutions"}
// export const FullExperienceKeys = {objectsKey: "objects", generalRulesKey: "generalRules", UIObjectsKey: "UIObjects"}
// export const UIElementPropsKeys = {tagKey: "tag", locXKey: "locX", locYKey: "locY", heightKey: "height", widthKey: "width", typeKey: "type"}
// export const ORBButtonUIKeys = {imageUrlKey: "imageUrl"}
// export const ORBImageUIKeys = {imageUrlKey: "imageUrl"}
// export const ORBLottieUIKeys = {lottieUrlKey: "lottieUrl"}
// export const ORBStatsUIKeys = {useTimerKey: "useTimer", useScoreKey: "useScore", useLevelsKey: "useLevels",
//     maxTimeKey:"maxTime", maxScoreKey: "maxScore", imageUrlKey: "imageUrl"}

export enum SupportedLanguages {
    English = "en",
    Arabic = "ar",
    Hebrew = "heb"
}

export enum SupportedFonts {
    Test = "test"
}

export interface IStyling {
    language: string;
    font: string;
}

export interface IMetadata {
    experienceID: string;
    experienceType: string;
    experienceName?: string;
    description?: string;
    imageUrl?: string;
}

export interface ITheme {
    imageUrl: string;
    primaryColor: number[];
    secondaryColor: number[];
    plantLogoOnSurfaces: boolean;
}

export interface IPageRoutingItem {
    pageName: string;
    pageParamsName?: string;
}

export interface IExperienceJson {
    styling?: IStyling;
    metadata: IMetadata;
    theme: ITheme;
    pageRoutingConfig: IPageRoutingItem[];
}

export interface ICustomExperience {
    objects: IExperienceObject[];
    UIObjects: IUIElementPropsDict[];
    generalRules: IGeneralRule[];
}

export interface IActivity {
    trigger: Record<string, any>;
    actions: Record<string, any>[];
    id: string;
    maxExecutions?: number;
}

export interface IGeneralRule {
    activity: IActivity
}

export interface IExperienceObject {
    id?: string;
    name: string;
    object: NetworkARObjectDict;
    activities: IActivity[];
    maxExecutions?: number;
}

// All Trigger-action templates should have type = BuildYourOwn. But just in case (for backward compatability) we also treat "custom" / "ShootersTemplate" / ... the same
export enum ExperienceType {
    // CUSTOM = 'custom',
    BUILDYOUROWN = 'BuildYourOwn',
    SURVEY = 'survey',
    MENUTEMPLATE = 'BaselBurgerMenu',
    BASELBURGERSHOOTERS = 'BaselBurgerShooters',
    SHOOTERSTEMPLATE = 'ShootersTemplate',
    GRIDTEMPLATE = 'GridTemplate',
    INDOORDESIGN = 'indoorDesign',
    PHOTOALBUM = 'PhotoAlbum',
    PORTALTEMPLATE = 'PortalTemplate'
}