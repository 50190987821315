import { ISummaryPage } from "../interfaces";
import { useContext, useEffect } from "react";
import { Mixpanel } from "../../../Managers/AnalyticsClient/AnalyticsClient";
import { AnalyticsEventPropertyKeys, AnalyticsKeys } from "../../../Managers/AnalyticsClient/AnalyticsKeys";
import { MainWrapper, Title, Message, ScoreBox, ButtonWrapper } from './styled';
import { AppStore } from '../../../app-store/store';
import { CustomizedButton } from "../welcome-page/styled";
import { PageCloseButton } from "../exit-button/styled";
import { CloseOutlined } from "@ant-design/icons";
import { ExperienceStore } from "../../../app-store/experienceStore";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../localization/LocalizationKeys";


export enum SummaryPageSelection {
    playAgain = "playAgain",
    exit = "exit"
}

export interface SummaryScreenInput {

}

export const SummaryScreen: React.FC<ISummaryPage> = ({ metadata, theme, input, experienceEndResponse, onPageComplete }) => {

    const { handleExitExperience } = useContext(ExperienceStore)
    const { t } = useTranslation();

    useEffect(() => {
        Mixpanel.track(AnalyticsKeys.SummaryScreen, {})
    }, []);

    const tryAgaintButtonTapped = () => {
        Mixpanel.track(AnalyticsKeys.ButtonTapped, {
            [AnalyticsEventPropertyKeys.buttonID]: "Play Again"
        })
        onPageComplete(SummaryPageSelection.playAgain)
    }

    const exitButtonTapped = () => {
        Mixpanel.track(AnalyticsKeys.ButtonTapped, {
            [AnalyticsEventPropertyKeys.buttonID]: "Exit"
        })
        onPageComplete(SummaryPageSelection.exit)
    }

    return (
        <MainWrapper>
            <PageCloseButton
                onClick={handleExitExperience}
                shape="circle"
                icon={<CloseOutlined />}
            />
            <Title>{t(LocalizationKeys.SummaryPageTitle)}</Title>
            <Message>{t(LocalizationKeys.SummaryPageText)}</Message>
            {experienceEndResponse && (
                <ScoreBox primaryColor={theme.primaryColor}>
                    {t(LocalizationKeys.ScoreString)}: {experienceEndResponse.stats.score} {t(LocalizationKeys.ScorePoints)}
                </ScoreBox>
            )}
            <ButtonWrapper>
                <CustomizedButton
                    primaryColor={theme.primaryColor}
                    secondaryColor={theme.secondaryColor}
                    onClick={() => tryAgaintButtonTapped()}
                >
                    {t(LocalizationKeys.SummaryPageTryAgain)}
                </CustomizedButton>
            </ButtonWrapper>
        </MainWrapper>
    );
}