import * as THREE from 'three';
import { Trigger, TriggerParameterNamesDict, TriggerType, TriggerDictKeys, TriggerTypesToContinue } from './Triggers';
import { Activity, ExperienceObject } from '../ExperienceObjects/ExperienceObject';
import { UIElementInteractionType, UIElementType } from '../Managers/UIManager';
import { ScheduleManager, ScheduleManagerDelegate } from './ScheduleManager';
// import { ObjectActionManager } from '../Managers/ObjectActionManager';

export class TriggerManager implements ScheduleManagerDelegate {

    pauseFlag: boolean = false
    scoresToMonitor: Trigger[] = []
    private scheduleManager: ScheduleManager;
    // private objectActionManager?: ObjectActionManager;

    constructor() {
        this.scheduleManager = new ScheduleManager(this);
    }

    cleanup() {
        pubsub.unsubscribeAll()
        this.scheduleManager.cleanup()
    }

    // setObjectActionManager(manager?: ObjectActionManager) {
    //     this.objectActionManager = manager
    // }

    fireTrigger(trigger: Trigger, data: Record<string, any>) {
        // If the pause flag is true, then stop all triggers except the ones in the list TriggerTypesToContinue
        if (!this.pauseFlag || TriggerTypesToContinue.includes(trigger.triggerType)) {
            pubsub.publish(trigger, data)
        }
    }

    // parse activities and add time/location based triggers
    parseActivity(activity: Activity, parentExperience?: ExperienceObject) {
        if (activity.isBringToLife()) {
            switch (activity.trigger.triggerType) {
                case TriggerType.onRandDelta:
                    const trigger = activity.trigger
                    // If a bring to life activity -> Limit the number of timer ticks to the maxExecutions of the experience
                    if (parentExperience && activity.isBringToLife()) {
                        this.scheduleManager.createNewRandDeltaTimer(trigger, parentExperience.maxExecutions);
                    } else {
                        // Otherwise, the activity's max number of executions is used
                        this.scheduleManager.createNewRandDeltaTimer(trigger, activity.maxExecutions);
                    }

                    // const randomTime = (Math.random() * (trigger.params.maxDelta - trigger.params.minDelta) + trigger.params.minDelta) * 1000
                    // const dataDict = { "minDelta": trigger.params.minDelta, "maxDelta": trigger.params.maxDelta }
                    // const self = this
                    // setTimeout(function () {
                    //     self.fireTrigger(trigger, dataDict)
                    // }, randomTime)
                    break;
                default:
                    break;
            }
        }
        switch (activity.trigger.triggerType) {
            case TriggerType.onReachScore:
                this.scoresToMonitor.push(activity.trigger)
            default:
                break;
        }
    }

    experienceStarted() {
        const triggerDataDict = {
            [TriggerDictKeys.triggerTypeKey]: TriggerType.onExperienceStart
        }
        const trigger = new Trigger(triggerDataDict)
        this.fireTrigger(trigger, {})
    }

    ///////// Schedule manager related methods
    randDeltaTimerTick(trigger: Trigger): void {
        this.fireTrigger(trigger, trigger.params)
    }

    ///////// UIManager notifications
    UIElementTapped(tag: string) {
        const triggerDataDict = {
            [TriggerDictKeys.triggerTypeKey]: TriggerType.onButtonTap,
            [TriggerParameterNamesDict.onButtonTap.id]: tag
        }
        const dataDict = { "buttonID": tag }
        const trigger = new Trigger(triggerDataDict)
        this.fireTrigger(trigger, dataDict)
    }

    UIElementInteraction(tag: string, elementType: UIElementType, interactionType: UIElementInteractionType) {
        // console.log("UIElementInteraction", tag, elementType, interactionType)
        switch (elementType) {
            case UIElementType.button:
                var triggerDataDict: Record<string, any>
                switch (interactionType) {
                    case UIElementInteractionType.click:
                        triggerDataDict = {
                            [TriggerDictKeys.triggerTypeKey]: TriggerType.onButtonTap,
                            [TriggerParameterNamesDict.onButtonTap.id]: tag
                        }
                        break;
                    case UIElementInteractionType.holdDown:
                        triggerDataDict = {
                            [TriggerDictKeys.triggerTypeKey]: TriggerType.onButtonHoldDown,
                            [TriggerParameterNamesDict.onButtonHoldDown.id]: tag
                        }
                        break;
                    case UIElementInteractionType.release:
                        triggerDataDict = {
                            [TriggerDictKeys.triggerTypeKey]: TriggerType.onButtonRelease,
                            [TriggerParameterNamesDict.onButtonRelease.id]: tag
                        }
                        break;
                }
                const dataDict = { "buttonID": tag }
                const trigger = new Trigger(triggerDataDict)
                this.fireTrigger(trigger, dataDict)
                break;
            default:
                break;
            // case UIElementType.image:
            //     break;
            // case UIElementType.lottie:
            //     break;
            // case UIElementType.stats:
            //     break;
            // case UIElementType.lottie:
            //     break;
        }
    }

    timeIsUp() {
        const triggerDataDict = {
            [TriggerDictKeys.triggerTypeKey]: TriggerType.onTimeEnd
        }
        const dataDict = {}
        const trigger = new Trigger(triggerDataDict)
        this.fireTrigger(trigger, dataDict)
    }

    timeIsAlmostUp(timeLeft: number) {
        const triggerDataDict = {
            [TriggerDictKeys.triggerTypeKey]: TriggerType.onTimeAlmostUp
        }
        const dataDict = { "timeLeft": timeLeft }
        const trigger = new Trigger(triggerDataDict)
        this.fireTrigger(trigger, dataDict)
    }

    scoreChange(counterID: string, newScore: number) {
        for (const trig of this.scoresToMonitor) {
            if (trig.triggerType == TriggerType.onReachScore) {
                const params = trig.params
                const trigCounterID = params[TriggerParameterNamesDict.onReachScore.id]
                const trigScore = params[TriggerParameterNamesDict.onReachScore.score]
                if (counterID == trigCounterID && newScore >= trigScore) {
                    const dataDict = { "score": newScore, "counterID": counterID }
                    this.fireTrigger(trig, dataDict)
                }
            }
        }
    }

    ///////// ARMAnager notifications
    startedAR() {

    }

    newXRFrame(frame: XRFrame) {
        const triggerDataDict = {
            [TriggerDictKeys.triggerTypeKey]: TriggerType.onUpdateARFrame
        }
        const dataDict = { "frame": frame }
        const trigger = new Trigger(triggerDataDict)
        // this.fireTrigger(trigger, dataDict)
    }

    pausePublishing() {
        this.pauseFlag = true
    }

    resumePublishing() {
        this.pauseFlag = false
    }

    trackingLost(frame: XRFrame) {
        console.log("Tracking lost...")
        const triggerDataDict = {
            [TriggerDictKeys.triggerTypeKey]: TriggerType.onTrackingLost
        }
        const dataDict = { "frame": frame }
        const trigger = new Trigger(triggerDataDict)
        this.fireTrigger(trigger, dataDict)
    }

    trackingEstablished(frame: XRFrame) {
        console.log("Tracking established...")
        const triggerDataDict = {
            [TriggerDictKeys.triggerTypeKey]: TriggerType.onTrackingEstablished
        }
        const dataDict = { "frame": frame }
        const trigger = new Trigger(triggerDataDict)
        this.fireTrigger(trigger, dataDict)
    }

    ///////// GestureManager
    // called on a hittest
    sceneHit(pose: XRPose) {
        const triggerDataDict = {
            [TriggerDictKeys.triggerTypeKey]: TriggerType.onSurfaceDetection,
            [TriggerParameterNamesDict.onSurfaceDetection.surfaceType]: ""
        }
        const dataDict = { "transform": pose }
        const trigger = new Trigger(triggerDataDict)
        // this.fireTrigger(trigger, dataDict)
    }
    // Called when the user taps the screen inside AR
    screenTap(tapLocation: Record<string, any>) {
        const triggerDataDict = {
            [TriggerDictKeys.triggerTypeKey]: TriggerType.onTapScreen
        }
        const dataDict = { "tapLocation": tapLocation }
        const trigger = new Trigger(triggerDataDict)
        this.fireTrigger(trigger, dataDict)
    }

    // When the screen is tapped and there is a hit in the scene at that location (plane / mesh)
    sceneTapHit(pose: XRPose, tap: { x: number, y: number }) {
        const triggerDataDict = {
            [TriggerDictKeys.triggerTypeKey]: TriggerType.onTapScene,
        }
        const dataDict = { "transform": pose, "tapLocation": tap }
        const trigger = new Trigger(triggerDataDict)
        this.fireTrigger(trigger, dataDict)
    }

    // When the screen is tapped and there is an object at that location
    // sceneObjectTapped(intersections: THREE.Intersection[]) {
    //     for (const intersect of intersections) {
    //         const tappedObject = intersect.object

    //         const triggerDataDict = {
    //             [TriggerDictKeys.triggerTypeKey]: TriggerType.onTapObject,
    //             [TriggerParameterNamesDict.onTapObject.id]: tappedObject.userData.experienceName
    //         }
    //         const dataDict = { "object": tappedObject, "transform": tappedObject.matrixWorld }
    //         const trigger = new Trigger(triggerDataDict)
    //         console.log("@@ Firing object taappp", trigger.triggerID, tappedObject)
    //         this.fireTrigger(trigger, dataDict)
    //     }
    // }
    sceneObjectTapped(tappedObject: THREE.Object3D) {
        const triggerDataDict = {
            [TriggerDictKeys.triggerTypeKey]: TriggerType.onTapObject,
            [TriggerParameterNamesDict.onTapObject.id]: tappedObject.userData.experienceName
        }
        const dataDict = { "object": tappedObject, "transform": tappedObject.matrixWorld }
        const trigger = new Trigger(triggerDataDict)
        this.fireTrigger(trigger, dataDict)
    }

    scenePlaneDetected(pose: XRPose) {
        const triggerDataDict = {
            [TriggerDictKeys.triggerTypeKey]: TriggerType.onSurfaceDetection,
            [TriggerParameterNamesDict.onSurfaceTap.surfaceType]: "table"
        }
        const dataDict = { "transform": pose }
        const trigger = new Trigger(triggerDataDict)
        this.fireTrigger(trigger, dataDict)
    }

    scenePlaneTapped(pose: XRPose) {
        const triggerDataDict = {
            [TriggerDictKeys.triggerTypeKey]: TriggerType.onSurfaceTap,
            [TriggerParameterNamesDict.onSurfaceTap.surfaceType]: "table"
        }
        const dataDict = { "transform": pose }
        const trigger = new Trigger(triggerDataDict)
        this.fireTrigger(trigger, dataDict)
    }

    //////// CollisionManager notifications
    collision(objectA: THREE.Object3D, objectB: THREE.Object3D) {
        // console.log("TriggerManager - Collision!", objectA, objectB)
        const triggerDataDict1 = {
            [TriggerDictKeys.triggerTypeKey]: TriggerType.onCollideWith,
            [TriggerParameterNamesDict.onCollideWith.id1]: objectA.userData.experienceID,
            [TriggerParameterNamesDict.onCollideWith.id2]: objectB.userData.experienceName
        }
        const objectATransform = objectA.matrixWorld //this.objectActionManager ? this.objectActionManager.getObjectTransformationWithoutActions(objectA) : objectA.matrixWorld
        const objectBTransform = objectB.matrixWorld //this.objectActionManager ? this.objectActionManager.getObjectTransformationWithoutActions(objectB) : objectB.matrixWorld

        const dataDict1 = {
            "object1": objectA,
            "object2": objectB,
            "transform": objectATransform//objectA.matrixWorld
        }
        const trigger1 = new Trigger(triggerDataDict1)
        this.fireTrigger(trigger1, dataDict1)

        const triggerDataDict2 = {
            [TriggerDictKeys.triggerTypeKey]: TriggerType.onCollideWith,
            [TriggerParameterNamesDict.onCollideWith.id1]: objectB.userData.experienceID,
            [TriggerParameterNamesDict.onCollideWith.id2]: objectA.userData.experienceName
        }
        const dataDict2 = {
            "object1": objectB,
            "object2": objectA,
            "transform": objectBTransform//objectB.matrixWorld
        }
        const trigger2 = new Trigger(triggerDataDict2)
        this.fireTrigger(trigger2, dataDict2)

        // Fire triggers containing only the objects' category names (and not the specific experience's id)
        const triggerDataDict3 = {
            [TriggerDictKeys.triggerTypeKey]: TriggerType.onCollideWith,
            [TriggerParameterNamesDict.onCollideWith.id1]: objectA.userData.experienceName,
            [TriggerParameterNamesDict.onCollideWith.id2]: objectB.userData.experienceName
        }
        const dataDict3 = {
            "object1": objectA,
            "object2": objectB,
            "transform": objectATransform//objectA.matrixWorld
        }
        const trigger3 = new Trigger(triggerDataDict3)
        this.fireTrigger(trigger3, dataDict3)

        const triggerDataDict4 = {
            [TriggerDictKeys.triggerTypeKey]: TriggerType.onCollideWith,
            [TriggerParameterNamesDict.onCollideWith.id1]: objectB.userData.experienceName,
            [TriggerParameterNamesDict.onCollideWith.id2]: objectA.userData.experienceName
        }
        const dataDict4 = {
            "object1": objectB,
            "object2": objectA,
            "transform": objectBTransform//objectB.matrixWorld
        }
        const trigger4 = new Trigger(triggerDataDict4)
        this.fireTrigger(trigger4, dataDict4)
    }
}

export const pubsub = (() => {
    // Use a Map for events to improve performance and readability
    const events: Map<string, Array<Subscription>> = new Map();
    let subscribersId = 0;

    // Define a subscription interface for better type safety
    interface Subscription {
        token: string;
        experienceName: string;
        func: (event: string, experienceName: string, data: Record<any, any>) => void;
    }

    function publish(trigger: Trigger, data: Record<any, any>): boolean {
        const subscribers = events.get(trigger.description);

        if (!subscribers) {
            return false; // No subscribers for this event
        }

        subscribers.forEach((subscriber) => {
            subscriber.func(trigger.triggerID, subscriber.experienceName, data);
        });

        return true;
    }

    function subscribe(
        event: string,
        experienceName: string,
        func: (event: string, experienceName: string, data: Record<any, any>) => void
    ): string {
        if (!events.has(event)) {
            events.set(event, []);
        }

        // Make sure that the experience is subscribed at most one time to each event 
        const existingSubscriber = events.get(event)?.find(subscriber => subscriber.experienceName === experienceName);
        if (existingSubscriber) {
            return existingSubscriber.token; // Return existing token
        }

        subscribersId += 1;
        const token = subscribersId.toString();
        events.get(event)?.push({
            token,
            experienceName,
            func,
        });

        return token;
    }

    function unsubscribe(token: string | undefined): string | null {
        if (!token) {
            return null; // Token is not provided
        }

        for (const [, subscribers] of events) {
            const index = subscribers.findIndex((subscriber) => subscriber.token === token);

            if (index !== -1) {
                subscribers.splice(index, 1);
                return token; // Token found and unsubscribed
            }
        }

        return null; // Token not found
    }

    function unsubscribeAll() {
        events.clear()
        subscribersId = 0
    }

    return {
        publish,
        subscribe,
        unsubscribe,
        unsubscribeAll,
    };
})();