import React, { useContext, useEffect, useState } from 'react';
import { IPostExperiencePage } from "../interfaces"
import { LeaderboardPage, LeaderboardEntry } from "../leaderboard-page/LeaderboardPage"
import { ServerManager } from '../../../Managers/ServerManager';
import { Button, Drawer, Spin } from 'antd'
import { RegistrationPage, IRegistrationResponse } from '../registration-page/RegistrationPage';
import {
    InnerContainer,
    RegisterButtonContainer,
    Paragraph,
} from './styled';
import { CloseOutlined } from '@ant-design/icons';
import { AppStore } from '../../../app-store/store';
import { Mixpanel } from '../../../Managers/AnalyticsClient/AnalyticsClient';
import { AnalyticsEventPropertyKeys, AnalyticsKeys } from '../../../Managers/AnalyticsClient/AnalyticsKeys';
import { CustomizedButton } from '../welcome-page/styled';
import { PageNames, Pages } from '../../../app-store/consts';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../localization/LocalizationKeys';

export enum PostGameSelection {
    playAgain = "playAgain",
    exit = "exit"
}

// includeLeaderboard == true and includeRegistration == false -> just get and present leaderboard
// includeLeaderboard == true and includeRegistration == true -> gets leaderboard, but it is presented only after the user registers (or registration data is found in local storage)
export const PostExperiencePage: React.FC<IPostExperiencePage> = ({ metadata, includeLeaderboard, includeRegistration, experienceEndResponse, onPageComplete }) => {

    const [leaderboardInput, setLeaderboardInput] = useState<LeaderboardEntry[]>(null)
    const [leaderboardInputLoading, setLeaderboardInputLoading] = useState<boolean>(true)
    const [showRegistrationDrawer, setShowRegistrationDrawer] = useState<boolean>(false);
    const [registrationResponse, setRegistrationResponse] = useState<IRegistrationResponse | null>(JSON.parse(localStorage.getItem(`user-registration`)));
    const [showRegistrationButton, setShowRegistrationButton] = useState<boolean>(false)
    const { experienceScore, userSession, globalTheme, pageComponents } = useContext(AppStore)

    const registrationPageEntry: Pages = pageComponents.find(page => page.pageName === PageNames.registrationPage);

    const { t } = useTranslation();

    useEffect(() => {
        Mixpanel.track(AnalyticsKeys.PostExperienceScreen, {
            [AnalyticsEventPropertyKeys.ExperienceType]: metadata.experienceType
        })
        if (registrationResponse === null) {
            setShowRegistrationButton(true)
        }
        // else {
        //     registedEndUserToCurrentExperience()
        // }
    }, [])

    useEffect(() => {
        if (registrationResponse !== null) {
            handleRegistrationComplete()
        }
    }, [registrationResponse])

    const registedEndUserToCurrentExperience = async () => {
        ServerManager.registerEndUser(registrationResponse, userSession, metadata.experienceID)
    }

    const handleRegistrationComplete = async () => {
        localStorage.setItem(`user-registration`, JSON.stringify(registrationResponse))
        registedEndUserToCurrentExperience()
        await writeScoreToLeaderboardTable()
        setShowRegistrationDrawer(false)
        setShowRegistrationButton(false)
    }

    const writeScoreToLeaderboardTable = async (): Promise<void> => {
        // const userSession = localStorage.getItem('user-session') ?? "NoUserID"
        const scoreDict: LeaderboardEntry = { userID: userSession, nickname: registrationResponse.nickname, score: experienceScore, fullName: registrationResponse.fullName, phoneNumber: registrationResponse.phoneNumber, email: registrationResponse.email }
        const leaderboardData = await ServerManager.writeLeaderboardScore(metadata.experienceID, scoreDict)
        setLeaderboardInput(leaderboardData)
        setLeaderboardInputLoading(false)
        return
    }

    const exitButtonTapped = () => {
        Mixpanel.track(AnalyticsKeys.ButtonTapped, {
            [AnalyticsEventPropertyKeys.buttonID]: "Exit"
        })
        onPageComplete(PostGameSelection.exit)
    }

    const tryAgaintButtonTapped = () => {
        Mixpanel.track(AnalyticsKeys.ButtonTapped, {
            [AnalyticsEventPropertyKeys.buttonID]: "Play Again"
        })
        onPageComplete(PostGameSelection.playAgain)
    }

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <Button
                onClick={() => exitButtonTapped()}
                style={{
                    position: 'absolute',
                    top: "20px",
                    zIndex: 9999,
                    left: "20px",
                    color: 'black',
                    backgroundColor: "#C0C0C0",
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'
                }} shape="circle" icon={<CloseOutlined />} />
            <Drawer
                // title="Register"
                placement={'bottom'}
                closable={true}
                onClose={() => setShowRegistrationDrawer(false)}
                open={showRegistrationDrawer}
                size='large'
                height={'500px'}
            >
                <RegistrationPage
                    showCloseButton={false}
                    params={
                        registrationPageEntry?.params
                            ? { ...registrationPageEntry.params, skipEnabled: false, backgroundImageUrl: undefined }
                            : { skipEnabled: false }
                    }
                    onPageComplete={(response: IRegistrationResponse, selectedImage?: File) => {
                        setRegistrationResponse(response)
                        setShowRegistrationDrawer(false)
                    }}
                />
            </Drawer>
            <InnerContainer>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '20px', filter: `${registrationResponse === null ? 'blur(10px)' : ''}` }}>
                    <LeaderboardPage leaderboardEntries={leaderboardInput || []} />
                    {
                        leaderboardInputLoading &&
                        <Spin size="large" />
                    }
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
                    <CustomizedButton style={{ marginTop: '20px' }} primaryColor={globalTheme.primaryColor} secondaryColor={globalTheme.secondaryColor} onClick={() => tryAgaintButtonTapped()}>
                        {t(LocalizationKeys.PostGameTryAgainButton)}
                    </CustomizedButton>
                    {
                        includeRegistration && showRegistrationButton &&
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
                            <CustomizedButton primaryColor={globalTheme.primaryColor} secondaryColor={globalTheme.secondaryColor} onClick={() => setShowRegistrationDrawer(true)}>
                                {t(LocalizationKeys.PostGameRegistrationButton)}
                            </CustomizedButton>
                            <Paragraph>{t(LocalizationKeys.PostGameRegistrationText)}</Paragraph>
                        </div>
                    }
                </div>
            </InnerContainer>
        </div>
    );
}