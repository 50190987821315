import { useIndoorDesignProvider } from "../../template-providers/IndoorDesignProvider"
import IndoorDesignMenu from "./IndoorDesignMenu"

export const IndoorDesignTemplateUI = () => {
    const { showMenu, template } = useIndoorDesignProvider()

    return (
        <div>
            {
                showMenu &&
                <IndoorDesignMenu
                    config={template.config}
                    selectionCallback={(category, index) => {
                        template.handleMenuSelection(category, index)
                    }}
                    onClose={() => template.closeMenu()}
                />
            }
        </div>
    )
}