export const BaselBurgerDemoExperience = `
{
   "styling": {
      "language": "ar",
      "font": "Comic Sans MS"
   },
   "metadata":{
      "experienceID": "baselburgershootersgame",
      "experienceType": "BaselBurgerShooters",
      "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2FBaselBurgerLogo.png?alt=media&token=fb4bba77-33a9-485a-836b-d2fccefbd2cf"
   },
   "theme":{
      "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2FBaselBurgerLogo.png?alt=media&token=fb4bba77-33a9-485a-836b-d2fccefbd2cf",
      "primaryColor":[
         100,
         200,
         35
      ],
      "secondaryColor":[
         0,
         0,
         0
      ],
      "plantLogoOnSurfaces":false
   },
   "pageRoutingConfig":[
      {
         "pageName":"welcome",
         "pageParamsName":"welcomeScreenInput"
      },
      {
         "pageName":"registration",
         "pageParamsName":"registrationScreenInput"
      },
      {
         "pageName":"game",
         "pageParamsName":"experience"
      },
      {
         "pageName":"postGame"
      }
   ],
   "registrationScreenInput":{
      "skipEnabled": true,
      "uploadImage": true,
      "giftSheetInput": {
         "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2FBaselBurgerLogo.png?alt=media&token=fb4bba77-33a9-485a-836b-d2fccefbd2cf",
         "description": "Hello world!"
      }   
   },
   "welcomeScreenInput":{
      "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Models%2FBaselBurger%2FBaselBurgerLogo.png?alt=media&token=7e75af27-daff-41a7-b080-8fef5d298117",
      "title":"helllooo",
      "description":"asfljasl lklaksjf ",
      "videoUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FVideos%2FBaselBurgerShootersTutorial.mov?alt=media&token=9b9ec41e-d7d8-4f74-905e-38fd42749fbe",
      "notes":[
         "qowfoq",
         "wqoirjqwo oqijwfojiqw"
      ],
      "giftSheetInput": {
         "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2FBaselBurgerLogo.png?alt=media&token=fb4bba77-33a9-485a-836b-d2fccefbd2cf",
         "description": "Hello world!"
      }
   },
   "experience":{
      "input":{
         "shootersListViewTheme":{
            "backgroundImageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FImages%2Ftest%2F7bb34af5-65a8-4111-b750-b4981eef2b64.JPG?alt=media&token=2e9aa909-4011-4604-9a0b-2ae353849cc4",
            "logo":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Models%2FBaselBurger%2FBaselBurgerLogo.png?alt=media&token=7e75af27-daff-41a7-b080-8fef5d298117",
            "title":"What's your favorite meal?",
            "listType": "largeIcons"
         },
         "shootersItems":[
            {
               "objectImage":{
                  "fileName":"1-ClassicBurger.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2F1-ClassicBurger.png?alt=media&token=7a32806a-78ea-46ca-a521-ae85e1c4cfd8"
               },
               "objectName":"Classic Burger"
            },
            {
               "objectImage":{
                  "fileName":"2-CrispyBurger.png",
                  "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2F2-CrispyBurger.png?alt=media&token=a0ab99b7-15b6-44f6-8221-83e4f660c85c"
               },
               "objectName":"Crispy Burger"
            }
         ]
      },
      "auxData":{
         "backgroundExperienceJSON":{
            "objects":[
               {
                  "id":"SelectedObject",
                  "name":"Selected Object",
                  "object":{
                     "fileName":"ORBAqua.png",
                     "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA.",
                     "settings": {
                        "allowUserGestures": true,
                        "size": 0.08,
                        "scaleAxis": 0,
                        "addPhysicsBody": true,
                        "physicsShapeType": 2,
                        "isAffectedByGravity": false
                    }
                  },
                  "activities":[
                     {
                        "trigger":{
                           "trigger":"onRandDelta",
                           "minDelta":1,
                           "maxDelta":1
                        },
                        "actions":[
                           {
                              "action":"bringToLife",
                              "loc":"infront"
                           },
                           {
                              "action":"moveRandom",
                              "duration": 20,
                              "durationPerMove": 2,
                              "speedFactor": 1
                           },
                           {
                              "action":"kill",
                              "delay":5
                           }
                        ]
                     },
                     {
                        "trigger":{
                           "trigger":"onCollideWith",
                           "id2":"bulletObject"
                        },
                        "actions":[
                           {
                              "action":"kill",
                              "delay":0
                           },
                           {
                              "action":"updateCounter",
                              "counterID":"stats123",
                              "delta":1
                           },
                           {
                              "action":"playSound",
                              "soundtrackName":"BubblePop.mp3",
                              "soundtrackUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Dev%2FStaticFiles%2FAudioFiles%2FBubblePop.mp3?alt=media&token=489d702a-d738-49e8-a912-387d7bea22df"
                           },
                           {
                              "action":"vibrate"
                           }
                        ]
                     }
                  ]
               },
               {
                  "id":"BulletObject",
                  "name":"bulletObject",
                  "object":{
                     "fileName":"ORBAqua.png",
                     "fileUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FORBAqua.png?alt=media&token=cb91b2a1-3549-48a5-922e-65ea1c519cc3&_gl=1*zifn84*_ga*Mzk0MjI5NDU1LjE2NzIwNjkwMjM.*_ga_CW55HF8NVT*MTY4NjUxMDk4My4zNy4xLjE2ODY1MTIzODQuMC4wLjA."
                  },
                  "activities":[
                     {
                        "trigger":{
                           "trigger":"onButtonTap",
                           "id":"button123"
                        },
                        "actions":[
                           {
                              "action":"bringToLife",
                              "loc":"userPos"
                           },
                           {
                              "action":"shoot"
                           },
                           {
                              "action":"kill",
                              "delay":5
                           }
                        ]
                     },
                     {
                        "trigger":{
                           "trigger":"onCollideWith",
                           "id2":"Selected Object"
                        },
                        "actions":[
                           {
                              "action":"kill",
                              "delay":0
                           }
                        ]
                     }
                  ]
               }
            ],
            "generalRules":[
               {
                  "activity":{
                     "trigger":{
                        "trigger":"onTimeEnd"
                     },
                     "actions":[
                        {
                           "action":"experienceFailed"
                        }
                     ]
                  }
               },
               {
                  "activity":{
                     "maxExecutions":-1,
                     "trigger":{
                        "trigger":"onTrackingLost"
                     },
                     "actions":[
                        {
                           "action":"pausePublishing"
                        }
                     ]
                  }
               },
               {
                  "activity":{
                     "maxExecutions":-1,
                     "trigger":{
                        "trigger":"onTrackingEstablished"
                     },
                     "actions":[
                        {
                           "action":"resumePublishing"
                        }
                     ]
                  }
               }
            ],
            "UIObjects":[
               {
                  "tag":"button123",
                  "type":"button",
                  "locX":0.5,
                  "locY":0.9,
                  "height":70,
                  "width":70,
                  "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2FBaselBurgerLogo.png?alt=media&token=fb4bba77-33a9-485a-836b-d2fccefbd2cf"
               },
               {
                  "tag":"stats123",
                  "type":"stats",
                  "locX":0.5,
                  "locY":0.1,
                  "height":100,
                  "width":100,
                  "useTimer":true,
                  "useScore":true,
                  "useLevels":false,
                  "maxTime":5,
                  "maxScore":100
               },
               {
                  "tag":"image1",
                  "type":"image",
                  "locX":0.5,
                  "locY":0.5,
                  "height":70,
                  "width":70,
                  "imageUrl":"https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Production%2FExperiences%2Fbaselburgershootersgame%2FImages%2FBaselBurgerShootingButton.png?alt=media&token=374f0585-c543-43af-a692-6b7da84ff3c0"
               }
            ]
         }
      }
   }
}
`