import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { ARObjectSettings, BrandTheme } from './ExperienceObjects/ExperienceObject';

THREE.Cache.enabled = true

export function getRandomFloat(min: number, max: number) {
    const num = (Math.random() * (max - min) + min)
    return num;
}

export function rgbaToHex(rgba: number[]): string {
    if (rgba.length !== 4) {
        throw new Error("RGBA array should have 4 elements (R, G, B, A).");
    }

    // Extract the R, G, B, and A values
    var [r, g, b, a] = rgba;

    r = Math.round(r * 255)
    g = Math.round(g * 255)
    b = Math.round(b * 255)

    // Ensure that the values are in the valid range (0-255 for R, G, B, 0-1 for A)
    if (r < 0 || r > 255 || g < 0 || g > 255 || b < 0 || b > 255 || a < 0 || a > 1) {
        throw new Error("RGBA values should be within valid range.");
    }

    // Convert R, G, B to hexadecimal values
    const rHex = r.toString(16).padStart(2, "0");
    const gHex = g.toString(16).padStart(2, "0");
    const bHex = b.toString(16).padStart(2, "0");

    // Convert A to a hexadecimal value (alpha is usually represented as a number from 0 to 1)
    const aHex = Math.round(a * 255).toString(16).padStart(2, "0");

    // Combine the hexadecimal values and return the hex color string
    return `#${rHex}${gHex}${bHex}`;
}

export function createSpinAnimation(mesh: THREE.Mesh, speed: number = 0.01): void {
    // Ensure the mesh is valid
    if (!mesh) return;

    // This function will be called on each frame
    function animate() {
        requestAnimationFrame(animate); // Request the next frame

        // Update the rotation of the mesh around the Y-axis
        mesh.rotation.y += speed; // Adjust the speed here for slower or faster rotation
    }

    animate(); // Start the animation
}

export function createFadeInAndMoveUpAnimation(mesh: THREE.Mesh, movementAmount: number, duration: number): void {
    if (!mesh) return;

    // Ensure the material can handle transparency
    const material = mesh.material as THREE.MeshBasicMaterial; // Cast to appropriate material type
    material.transparent = true; // Enable transparency
    material.opacity = 0; // Start with opacity 0

    // Calculate the time step for movement and opacity
    const startTime = performance.now(); // Get the current time
    const initialPositionY = mesh.position.y - movementAmount; // Initial Y position

    // Animation loop
    function animate() {
        const currentTime = performance.now();
        const elapsedTime = (currentTime - startTime) / 1000; // Convert to seconds

        // Calculate the new Y position
        const newYPosition = initialPositionY + movementAmount * (elapsedTime / duration);
        mesh.position.y = newYPosition;

        // Calculate opacity based on elapsed time
        const newOpacity = Math.min(elapsedTime / duration, 1); // Ensure opacity doesn't exceed 1
        material.opacity = newOpacity;

        // Check if the animation is complete
        if (elapsedTime < duration) {
            requestAnimationFrame(animate); // Request the next frame if not finished
        }
    }

    animate(); // Start the animation
}

export async function loadTexture(url: string): Promise<THREE.Texture> {
    const loader = new THREE.TextureLoader();
    return new Promise((resolve, reject) => {
        loader.load(
            url,
            (texture) => {
                resolve(texture);
            },
            undefined,
            (error) => {
                reject(error);
            }
        );
    });
}

export class Reticle extends THREE.Object3D {
    lastHitPose: XRPose | null;

    constructor(imagePath: string | null = null, planeWidth: number = 0.1, planeHeight: number = 0.1, reticle3DModelPath: string | null = null) {
        super();
        this.lastHitPose = null;

        // If an imagepath is given, then load the reticle as a 2D image. Otherwise, load the 3D model of a reticle
        if (imagePath) {
            console.log(`loading reticle texture...`)
            var loadTime = performance.now()
            const texture = new THREE.TextureLoader().load(imagePath, () => {
                loadTime = (performance.now() - loadTime) / 1000
                // console.log("TEXTURE LOADING TIME", loadTime)
            });
            const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide, transparent: true });

            const geometry = new THREE.PlaneGeometry(planeWidth, planeHeight);
            const plane = new THREE.Mesh(geometry, material);
            plane.rotateX(- Math.PI / 2);

            this.add(plane)
        } else {
            const modelPath = reticle3DModelPath ?? "https://immersive-web.github.io/webxr-samples/media/gltf/reticle/reticle.gltf";
            const loader = new GLTFLoader();
            loader.load(modelPath, (gltf) => {
                this.add(gltf.scene);
            })
        }
        this.visible = false;
        this.userData.settings = new ARObjectSettings({ "addPhysicsBody": false, "addToAnalytics": false })
    }

    updateReticleObject(hitPose: XRPose) {
        // Update the reticle position
        this.visible = true;
        // console.log(hitPose.transform.position)
        this.position.set(hitPose.transform.position.x, hitPose.transform.position.y, hitPose.transform.position.z)
        this.updateMatrixWorld(true);
        this.lastHitPose = hitPose
    }
}

export class ObjectTorus extends THREE.Object3D {
    torusObject: THREE.Object3D

    constructor(object: THREE.Object3D, brandTheme?: BrandTheme) {
        super();

        // Compute the bounding box of the object
        const boundingBox = new THREE.Box3().setFromObject(object);

        // Calculate the diameter of the circular plane
        const diameter = Math.max(boundingBox.getSize(new THREE.Vector3()).x, boundingBox.getSize(new THREE.Vector3()).z);
        // console.log("diameter", diameter, object.scale.x)
        // Create a torus geometry
        const torusGeometry = new THREE.TorusGeometry(diameter + 0.003, 0.002, 32, 100);

        // Create a material for the torus
        const torusMaterial = new THREE.MeshBasicMaterial({ color: brandTheme.primaryColor ?? "#FFFFFF" });

        // Create a torus mesh
        const torus = new THREE.Mesh(torusGeometry, torusMaterial);
        torus.rotateX(- Math.PI / 2);

        // Position the torus underneath the object
        const position = boundingBox.getCenter(new THREE.Vector3());
        position.setY(boundingBox.min.y - 0.01); // Adjust the height slightly below the object

        // Add the torus to this object
        this.torusObject = torus

        // this.position.copy(position);
        // this.scale.copy(object.scale.clone())
        // const inverseScale = new THREE.Vector3(1 / object.scale.x, 1 / object.scale.y, 1 / object.scale.z);
        // this.scale.copy(inverseScale);
        this.add(torus);

        this.userData.settings = new ARObjectSettings({ "addPhysicsBody": false, "addToAnalytics": false })
    }

    createAnimation() {
        // Define the upward and downward movement animations
        const moveUpAnimation = new THREE.AnimationClip('MoveUp', 3, [
            new THREE.VectorKeyframeTrack('.position', [0, 3], [this.torusObject.position.x, this.torusObject.position.y, this.torusObject.position.z, this.torusObject.position.x, this.torusObject.position.y + 0.5, this.torusObject.position.z])
        ]);
        const moveDownAnimation = new THREE.AnimationClip('MoveDown', 1, [
            new THREE.VectorKeyframeTrack('.position', [0, 1], [this.torusObject.position.x, this.torusObject.position.y + 0.5, this.torusObject.position.z, this.torusObject.position.x, this.torusObject.position.y, this.torusObject.position.z])
        ]);

        // Create the animation mixer
        const mixer = new THREE.AnimationMixer(this.torusObject);

        // Create animation actions for both animations
        const moveUpAction = mixer.clipAction(moveUpAnimation);
        const moveDownAction = mixer.clipAction(moveDownAnimation);

        // Crossfade between the two animations
        moveUpAction.crossFadeTo(moveDownAction, 1, true);
        moveDownAction.crossFadeTo(moveUpAction, 1, true);

        // Start the animation
        moveUpAction.play();

        // Update the animation mixer in the render loop
        this.onBeforeRender = () => {
            mixer.update(1 / 60); // Assuming 60 FPS
        };
    }
}

export class DispatchGroup {
    private count: number = 0;
    private resolveFunc: (() => void) | null = null;
    private resolved: boolean = false;

    enter() {
        this.count++;
    }

    leave() {
        if (this.count > 0) {
            this.count--;
            if (this.count === 0 && this.resolveFunc && !this.resolved) {
                this.resolved = true;
                this.resolveFunc();
            }
        }
    }

    async wait() {
        if (this.count === 0) {
            return;
        }

        await new Promise<void>((resolve) => {
            this.resolveFunc = resolve;
        });
    }
}

// Helper function to load the image from the file
export const loadImage = (file: File): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = e.target?.result as string;
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
};

// Helper function to resize an image
export const resizeImage = (
    img: HTMLImageElement,
    maxWidth: number,
    maxHeight: number,
    outputFormat: string = 'image/jpeg'
): Promise<Blob | null> => {
    return new Promise((resolve) => {
        const canvas = document.createElement('canvas');

        let width = img.width;
        let height = img.height;

        // Calculate new dimensions while maintaining aspect ratio
        if (width > maxWidth || height > maxHeight) {
            if (width / height > maxWidth / maxHeight) {
                // Width is the constraining dimension
                height *= maxWidth / width;
                width = maxWidth;
            } else {
                // Height is the constraining dimension
                width *= maxHeight / height;
                height = maxHeight;
            }
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob(
                (blob) => {
                    resolve(blob);
                },
                outputFormat,
                0.8 // Initial quality (for JPEG)
            );
        } else {
            resolve(null);
        }
    });
};

export const resetTranslation = (matrix: THREE.Matrix4): THREE.Matrix4 => {
    // Set the translation components to zero
    matrix.elements[12] = 0; // m41
    matrix.elements[13] = 0; // m42
    matrix.elements[14] = 0; // m43

    return matrix
}


// export class DispatchGroup {
//     count: number
//     resolveFunc: (() => void) | null
//     constructor() {
//         this.count = 0;
//         this.resolveFunc = null;
//     }

//     enter() {
//         this.count++;
//     }

//     leave() {
//         this.count--;
//         if (this.count === 0 && this.resolveFunc) {
//             this.resolveFunc();
//         }
//     }

//     async wait() {
//         if (this.count === 0) {
//             return;
//         }

//         await new Promise((resolve) => {
//             this.resolveFunc = resolve;
//         });
//     }
// }