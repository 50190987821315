import styled from "styled-components";


export const Divider = styled.div`
  width: 70%;
  border-bottom: 1px solid #ccc;
  margin: auto;
  margin-bottom: 20px;
`;

export const CustomerLogo = styled.img`
  width:150px;
  height:150px;
  object-fit:contain;

`
