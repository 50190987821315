import { ARObjectLoader } from "../../ExperienceObjects/ExperienceObject";
import { pubsub } from "../../TriggerAction/TriggerManager";
import { Trigger, TriggerType } from "../../TriggerAction/Triggers";
import { loadTexture } from "../../utils";
import { PlatformManager } from "../CustomTemplate/PlatformManager";
import { ITemplatesBasics } from "../TemplatesInterface";
import { TilesGrid } from "./GridStruct";
import { IndoorDesignConfig, IndoorDesignNetworkConfig, IndoorDesignObject, IndoorDesignTile } from "./IndoorDesignTemplateInput";
import * as THREE from 'three';

import { IndoorDesignCategory } from "./IndoorDesignMenu";

interface IIndoorDesignTemplate extends ITemplatesBasics { }

export class IndoorDesignTemplate
    extends PlatformManager
    implements IIndoorDesignTemplate {

    config: IndoorDesignConfig;

    intervalId: any = null
    tilesGrid?: TilesGrid;

    menuElement: HTMLElement | null = null;

    /////// External UI
    setShowMenu: (status: boolean) => void | null = null;

    ///////////// Parse input json //////////////////
    static async parseInput(
        json: Record<string, any>
    ): Promise<IndoorDesignConfig> {
        const networkConfig = new IndoorDesignNetworkConfig(json);
        const objects: IndoorDesignObject[] = [];
        const tiles: IndoorDesignTile[] = [];

        for (const networkItem of networkConfig.objects) {
            const loader = new ARObjectLoader(networkItem.object)
            if (loader) {
                const object = await loader.loadARObject()
                objects.push(new IndoorDesignObject(object, networkItem))
            }
        }

        for (const networkItem of networkConfig.tiles) {
            const tileTexture = await loadTexture(networkItem.tileImage.fileUrl);
            tiles.push(new IndoorDesignTile(networkItem, tileTexture))
        }

        const config = new IndoorDesignConfig(networkConfig, objects, tiles);
        return config;
    }

    startExperience(): void {
        super.startExperience();

        this.finishSetup()
        this.presentMenu()
    }

    cleanup() {
        super.cleanup()
        clearInterval(this.intervalId)
    }

    finishSetup() {
        this.intervalId = setInterval(this.handleTileAtScreenCenter.bind(this), 100);
        this.addSubscribers()
    }

    placeSelectedObjectInScene(index: number) {
        const object = this.config.objects[index]
        if (object) {
            this.ARManager.addARObjectToSceneViaFS(object.object)
        }


        // const portal = this.ARManager.createTexturedPortalSphere(this.config.tiles[0].tileImage)
        // console.log("@@@ portal", portal)
        // this.ARManager.addARObjectToSceneViaFS(portal)
    }

    placeSelectedTileInScene(index: number) {
        const tileObject = this.config.tiles[index]
        if (tileObject) {
            if (this.tilesGrid) {
                this.tilesGrid.changeGridTileImage(tileObject.tileImage)
            } else {
                this.placeFirstTile(tileObject)
            }
        }
    }

    placeFirstTile(tileObject: IndoorDesignTile) {
        this.ARManager.getTransformViaFS(async (transform: THREE.Matrix4) => {
            this.createTilesGrid(tileObject, transform)
            this.rendererRef?.placeScopeImage()
        })
    }

    createTilesGrid(tileObject: IndoorDesignTile, transform: THREE.Matrix4) {
        const grid = new TilesGrid(tileObject.tileSize.x,
            tileObject.tileSize.y,
            this.config.settings.gridSize.x,
            this.config.settings.gridSize.y,
            transform,
            tileObject.tileImage
        )

        const tilesGridParent = grid.getParentNode()
        this.ARManager.addARObjectToScene(tilesGridParent);
        this.tilesGrid = grid
    }

    handleTileAtScreenCenter() {
        if (!this.tilesGrid) {
            return
        }

        const intersections = this.ARManager.getScreenCenterIntersections(this.tilesGrid.getTilesParentNode().children)

        for (const obj of intersections) {
            if (obj instanceof THREE.Mesh) {
                const objName = obj.name
                if (objName !== "") {
                    this.tilesGrid.userPointingAtTile(objName)
                }
            }
        }
    }

    addSubscribers() {
        this.subscribeToButtonTaps()
        this.subscribeToScreenTaps()
    }

    subscribeToScreenTaps() {
        const dict = { "trigger": "onTapScreen" }
        const trigger = new Trigger(dict)

        pubsub.subscribe(trigger.description, trigger.description, this.onScreenTapFunc.bind(this))
    }

    subscribeToButtonTaps() {
        const buttonTapTriggerDict = { "trigger": "onButtonTap", "id": "" }
        const buttonTapTrigger = new Trigger(buttonTapTriggerDict)

        pubsub.subscribe(buttonTapTrigger.description, buttonTapTrigger.description, this.onButtonTapFunc.bind(this))
    }

    onScreenTapFunc() {
        this.tilePlacementButtonTapped()
    }

    onButtonTapFunc(triggerID: string, experienceObjectName: string, data: Record<string, any>) {
        if (!("buttonID" in data)) {
            return
        }
        const buttonID = data.buttonID
        switch (buttonID) {
            case "menuButton":
                this.presentMenu()
                break;
        }
    }

    tilePlacementButtonTapped() {
        this.tilesGrid?.placeTileImage()
    }

    presentMenu() {
        this.setShowMenu && this.setShowMenu(true);
        // const container = document.querySelector("#overlay-container");
        // if (container) {
        //     const newElement = document.createElement('div');
        //     this.menuElement = newElement
        //     const root = createRoot(newElement);

        //     const props: IndoorDesignMenuProps = {
        //         config: this.config,
        //         selectionCallback: (category, index) => {
        //             this.handleMenuSelection(category, index)
        //         },
        //         onClose: () => {
        //             this.closeMenu()
        //         }
        //     };
        //     root.render(
        //         React.createElement(IndoorDesignMenu, props)
        //     );
        //     container.appendChild(newElement);
        // }
    }

    closeMenu() {
        this.setShowMenu && this.setShowMenu(false);
        // if (this.menuElement) {
        //     this.menuElement.remove(); // Remove the menu element from the DOM
        //     this.menuElement = null; // Reset the reference to prevent memory leaks
        // }
    }

    handleMenuSelection(category: IndoorDesignCategory, index: number) {
        this.closeMenu()

        switch (category) {
            case IndoorDesignCategory.Furniture:
                this.placeSelectedObjectInScene(index)
                break;
            case IndoorDesignCategory.Tiles:
                this.placeSelectedTileInScene(index)
                break;
        }
    }
}