export let ENV = "dev";

if (
  import.meta.env.MODE === "production" &&
  window.location.hostname.indexOf("dev.") === -1
) {
  ENV = "prod";
}

if (ENV !== "prod") {
  console.log("The ENV IS:", ENV);
}

const prodMainServiceUrl: string =
  "https://files-dot-arplatform-99ab9.ew.r.appspot.com";
const prodAnalyticsServiceUrl: string =
  "https://analytics-dot-arplatform-99ab9.ew.r.appspot.com";
const prodCustomerAuth: string =
  "https://customers-auth-dot-arplatform-99ab9.ew.r.appspot.com";
const prodExperiences: string =
  "https://experiences-dot-arplatform-99ab9.ew.r.appspot.com";

const devMainServiceUrl: string =
  "https://dev-files-dot-arplatform-99ab9.ew.r.appspot.com";
const devAnalyticsServiceUrl: string =
  "https://dev-analytics-dot-arplatform-99ab9.ew.r.appspot.com";
const devCustomerAuth: string =
  "https://dev-customers-auth-dot-arplatform-99ab9.ew.r.appspot.com";
const devExperiences: string =
  "https://dev-experiences-dot-arplatform-99ab9.ew.r.appspot.com";


const localIPAddress: string = "http://192.168.1.160"
const localMainServiceUrl: string =
  localIPAddress+":3002";
const localAnalyticsServiceUrl: string =
  localIPAddress+":3001";
const localCustomerAuth: string =
  localIPAddress+":3000";
const localExperiences: string =
  localIPAddress+":3003";

interface ApiBaseUrls {
  customersAuth: string;
  analytics: string;
  files: string;
  experiences: string
}

export const servicesUrlsByName: ApiBaseUrls = {
  customersAuth: ENV === "prod" 
    ? prodCustomerAuth 
    : ENV === "dev" 
    ? devCustomerAuth 
    : localCustomerAuth,

  analytics: ENV === "prod" 
    ? prodAnalyticsServiceUrl 
    : ENV === "dev" 
    ? devAnalyticsServiceUrl 
    : localAnalyticsServiceUrl,

  files: ENV === "prod" 
    ? prodMainServiceUrl 
    : ENV === "dev" 
    ? devMainServiceUrl 
    : localMainServiceUrl,

  experiences: ENV === "prod" 
    ? prodExperiences 
    : ENV === "dev" 
    ? devExperiences 
    : localExperiences,
};
