import './App.css'
import { AppRouter } from './components/routers/AppRouter.tsx';

function App() {
    return (
        <main>
            <AppRouter />
            
        </main>
    )
}

export default App;
