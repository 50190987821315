import styled from "styled-components";

export const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  width: 90%;
`;

export const ModalContentBody = styled.div`
  background-color: white;
  z-index: 999;
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;  /* Center content horizontally */
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

export const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;  /* Ensures buttons take up full width for even spacing */
`;

export const ModalTitle = styled.p`
  font-weight: bold;
  color: black;
  text-align: center;  /* Center the text within the title element */
  margin: 0;  /* Remove any default margin */
`;
