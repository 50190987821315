import {v4 as uuidv4} from 'uuid';

// export const TriggerType: Record<string, string> = {
//     onRandDelta: 'onRandDelta',
//     onTapScreen: 'onTapScreen',
//     onTapObject: 'onTapObject',
//     onSurfaceDetection: 'onSurfaceDetection',
//     onSurfaceTap: 'onSurfaceTap',
//     onCollideWith: 'onCollideWith',
//     onButtonTap: 'onButtonTap',
//     onButtonHoldDown: 'onButtonHoldDown',
//     onButtonRelease: 'onButtonRelease',
//     onUpdateARFrame: 'onUpdateARFrame',
//     // onUpdateARAnchor: 'onUpdateARAnchor',
//     // onReachGPS: 'onReachGPS',
//     // onActivityEnd: 'onActivityEnd',
//     // onSliderSelection: 'onSliderSelection',
//     // onNewMapAnnotation: 'onNewMapAnnotation',
//     onTimeEnd: 'onTimeEnd',
//     // onLevelChange: 'onLevelChange',
//     // onReferenceImageDetection: 'onReferenceImageDetection',
//     onTrackingLost: 'onTrackingLost',
//     onTrackingEstablished: 'onTrackingEstablished',
//     unknown: 'unknown',
// };

export enum TriggerType {
    onRandDelta = 'onRandDelta',
    onTapScreen = 'onTapScreen',
    onTapScene = 'onTapScene',
    onTapObject = 'onTapObject',
    onSurfaceDetection = 'onSurfaceDetection',
    onSurfaceTap = 'onSurfaceTap',
    onCollideWith = 'onCollideWith',
    onButtonTap = 'onButtonTap',
    onButtonHoldDown = 'onButtonHoldDown',
    onButtonRelease = 'onButtonRelease',
    onUpdateARFrame = 'onUpdateARFrame',
    onTimeEnd = 'onTimeEnd',
    onTrackingLost = 'onTrackingLost',
    onTrackingEstablished = 'onTrackingEstablished',
    onReachScore = 'onReachScore',
    onExperienceStart = 'onExperienceStart',
    onTimeAlmostUp = 'onTimeAlmostUp',
    unknown = 'unknown',
};

export const TriggerTypesToContinue = [
    TriggerType.onRandDelta,
    TriggerType.onTrackingEstablished,
    TriggerType.onTrackingLost,
    TriggerType.onTimeEnd
]

const TriggerDescription: Record<TriggerType, string> = {
    onRandDelta: 'com.trigger.randDelta',
    onTapScreen: 'com.trigger.screenTap',
    onTapScene: 'com.trigger.sceneTap',
    onTapObject: 'com.trigger.objectTap',
    onSurfaceDetection: 'com.trigger.surfaceDetection',
    onSurfaceTap: 'com.trigger.surfaceTap',
    onCollideWith: 'com.trigger.collideWith',
    onButtonTap: 'com.trigger.buttonTap',
    onButtonHoldDown: 'com.trigger.buttonHoldDown',
    onButtonRelease: 'com.trigger.buttonRelease',
    onUpdateARFrame: 'com.trigger.updateARFrame',
    onTimeEnd: 'com.trigger.onTimeEnd',
    onTrackingLost: 'com.trigger.trackingLost',
    onTrackingEstablished: 'com.trigger.trackingEstablished',
    onReachScore: 'com.trigger.reachedScore',
    onExperienceStart: 'com.trigger.experienceStart',
    onTimeAlmostUp: 'com.trigger.timeAlmostUp',
    unknown: 'com.trigger.unknown',
};

const TriggerParameterList: Record<TriggerType, Record<string, string>> = {
    onRandDelta: {"counterID": "UUID", "minDelta": "number", "maxDelta": "number"},
    onTapScreen: {},
    onTapScene: {},
    onTapObject: {"id": "string"},
    onSurfaceDetection: {"surfaceType": "string"},
    onSurfaceTap: {"surfaceType": "string"},
    onCollideWith: {"id1": "string", "id2": "string"},
    onButtonTap: {"id": "string"},
    onButtonHoldDown: {"id": "string"},
    onButtonRelease: {"id": "string"},
    onUpdateARFrame: {},
    onTimeEnd: {},
    onTrackingLost: {},
    onTrackingEstablished: {},
    onReachScore: {"id": "string", "score": "number"},
    onExperienceStart: {},
    onTimeAlmostUp: {},
    unknown: {},
};

export const TriggerParameterNamesDict: Record<string, Record<string, string>> = {
    onRandDelta: {UUID: "timerID", minDelta: "minDelta", maxDelta: "maxDelta"},
    onTapScreen: {},
    onTapScene: {},
    onTapObject: {id: "id"},
    onSurfaceDetection: {surfaceType: "surfaceType"},
    onSurfaceTap: {surfaceType: "surfaceType"},
    onCollideWith: {id1: "id1", id2: "id2"},
    onButtonTap: {id: "id"},
    onButtonHoldDown: {id: "id"},
    onButtonRelease: {id: "id"},
    onUpdateARFrame: {},
    onTimeEnd: {},
    onTrackingLost: {},
    onTrackingEstablished: {},
    onReachScore: {id: "id", score: "score"},
    onExperienceStart: {},
    onTimeAlmostUp: {},
    unknown: {},
};

export const TriggerParametersDefaults: Record<string, Record<string, string>> = {
    onRandDelta: {},
    onTapScreen: {},
    onTapScene: {},
    onTapObject: {},
    onSurfaceDetection: {},
    onSurfaceTap: {},
    onCollideWith: {id1: ""},
    onButtonTap: {},
    onButtonHoldDown: {},
    onButtonRelease: {},
    onUpdateARFrame: {},
    onTimeEnd: {},
    onTrackingLost: {},
    onTrackingEstablished: {},
    onReachScore: {},
    onExperienceStart: {},
    onTimeAlmostUp: {},
    unknown: {}
};

export const TriggerDictKeys: Record<string,string> = {triggerTypeKey: "trigger"}

export class Trigger {
    triggerType: TriggerType
    description: string
    triggerID: string
    params: Record<string, any>
    data: Record<string, any>

    constructor(triggerDataDict: Record<string, any>) {
        const triggerType = triggerDataDict[TriggerDictKeys.triggerTypeKey]

        if (!(Object.values(TriggerType).includes(triggerType))) {
            throw new Error(`Bad trigger type ${triggerType}! Options: ${Object.values(TriggerType)}`);
        }

        this.triggerType = triggerType
        this.description = TriggerDescription[triggerType];
        this.params = {}
        this.data = triggerDataDict

        for (const [paramNameKey, paramName] of Object.entries(TriggerParameterNamesDict[triggerType])) {
            // If a parameter is a UUID, then it should be generated locally (not given as input.)
            // Otherwise, this parameter should be given as input (if not, we throw an error)
            if (paramNameKey == "UUID") {
                this.params[paramName] = uuidv4()
            } else if (paramName in triggerDataDict) {
                this.params[paramName] = triggerDataDict[paramName]
            } else if (paramNameKey in TriggerParametersDefaults[triggerType]) {
                this.params[paramName] = TriggerParametersDefaults[triggerType][paramNameKey]
            } else {
                throw new Error(`Parameter ${paramName} is missing for trigger ${triggerType}`);
            }
            // this.triggerID = this.triggerID + "." + this.params[paramName]
        }

        this.computeTriggerID()
    }

    computeTriggerID() {
        this.triggerID = this.description
        for (const [paramNameKey, paramName] of Object.entries(TriggerParameterNamesDict[this.triggerType])) {
            this.triggerID = this.triggerID + "." + this.params[paramName]
        }
    }

    clone() {
        const trigger = new Trigger(this.data)
        return trigger
    }

    isDeltaTrigger() {
        if (this.triggerType in [TriggerType.onRandDelta]) {
            return true
        }
        return false
    }
}