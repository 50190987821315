import { useBaselBurgerShootersProvider } from "../../template-providers/BaselBurgerShootersProvider"
import { BaselBurgerSelectionScreen } from "./basel-burger-shooters-list-component/BaselBurgerShootersListView"

export const BaselBurgerShootersTemplateUI = () => {
    const { showSelectionScreen, template } = useBaselBurgerShootersProvider()

    return (
        <div>
            {
                showSelectionScreen &&
                <BaselBurgerSelectionScreen
                    list={template.listItems}
                    theme={template.config.shootersListViewTheme}
                    onItemClick={(item: string, index: number) => template.doneButtonClicked(item, index)}
                />
            }
        </div>
    )
}