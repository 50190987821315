
import * as THREE from "three";

export class WebXRGestureManager {
    session: XRSession;
    renderer: THREE.WebGLRenderer;
    scene: THREE.Scene;
    camera: THREE.PerspectiveCamera;

    hitTestSource: XRHitTestSource | null | undefined;
    hitTestSourcePlanes: XRHitTestSource | null | undefined;
    tapHitTestSource: XRHitTestSource | null;
    viewerReferenceSpace: XRReferenceSpace | null;
    transientHitTestSource: XRTransientInputHitTestSource | null | undefined;
    localReferenceSpace: XRReferenceSpace | null;

    onTapFun: ((tap: { x: number, y: number }) => void) | null;
    onHitTestFun: ((hitPose: XRPose) => void) | null;
    onTapHitTestFun: ((hitPose: XRPose, tap: { x: number, y: number }) => void) | null;
    onObjectTapFun: ((rayIntersects: THREE.Intersection[]) => void) | null;
    onPlaneDetectionFun: ((pose: XRPose) => void) | null;
    onPlaneTapFun: ((hitPose: XRPose) => void) | null;
    onPinchFun: ((delta: number) => void) | null;
    onPinchEndFun: ((delta: number) => void) | null;
    onRotateFun: ((deltaYaw: number) => void) | null;
    onRotateEndFun: ((deltaYaw: number) => void) | null;
    onFinishFun: (() => void) | null;

    // planes: Map<XRPlane, number>;
    lastAngle: number;
    initScale: number;
    needInitScale: boolean;
    lastScreenTouch: { x: number, y: number };
    screenTapped: boolean = false
    touchEnded: boolean = false
    lastFrame: XRFrame | null

    finalSeparation: number | null = null
    finalDeltaYaw: number | null = null
    tapEndFlag: boolean = true

    // Event handlers
    private touchStartListener: (event: TouchEvent) => void;
    private touchEndHandler: (event: TouchEvent) => void;
    private selectStartHandler = () => {
        this.needInitScale = true;
    };

    constructor(session: XRSession, renderer: THREE.WebGLRenderer, scene: THREE.Scene, camera: THREE.PerspectiveCamera) {
        this.session = session
        this.renderer = renderer
        this.scene = scene
        this.camera = camera

        this.hitTestSource = null
        this.hitTestSourcePlanes = null
        this.tapHitTestSource = null
        this.viewerReferenceSpace = null
        this.transientHitTestSource = null
        this.localReferenceSpace = null

        this.onTapFun = null
        this.onHitTestFun = null
        this.onTapHitTestFun = null
        this.onObjectTapFun = null
        this.onPlaneDetectionFun = null
        this.onPlaneTapFun = null
        this.onPinchFun = null
        this.onPinchEndFun = null
        this.onRotateFun = null
        this.onRotateEndFun = null
        this.onFinishFun = null

        // this.planes = new Map();
        this.lastAngle = 0;
        this.initScale = 1
        this.needInitScale = true
        this.lastScreenTouch = { x: 0, y: 0 }
        this.lastFrame = null

        this.touchStartListener = (event: TouchEvent) => {
            event.preventDefault();
            const touches = event.changedTouches;
            if (touches.length) {
                this.screenTapped = true;
                // this.lastScreenTouch = {
                //     x: 2 * (touches[0].screenX / window.screen.width) - 1,
                //     y: 1 - 2 * (touches[0].screenY / window.screen.height)
                // }
                this.lastScreenTouch = {
                    x: 2 * (touches[0].screenX / window.innerWidth) - 1,
                    y: 1 - 2 * (touches[0].screenY / window.innerHeight)
                }
                if (this.onTapFun) {
                    this.onTapFun(this.lastScreenTouch)
                }
            }
        };

        this.touchEndHandler = (event: TouchEvent) => {
            event.preventDefault();
            this.touchEnded = true;
        };

        this.session.addEventListener("selectstart", this.selectStartHandler)
        this.session.addEventListener("selectend", this.selectEndHandler.bind(this))

        document.getElementById('touch-canvas')?.addEventListener('touchstart', this.touchStartListener);
        document.getElementById('touch-canvas')?.addEventListener('touchend', this.touchEndHandler);
    }

    async init() {
        this.viewerReferenceSpace = await this.session.requestReferenceSpace("viewer");
        this.localReferenceSpace = await this.session.requestReferenceSpace("local");
        this.transientHitTestSource = await this.session.requestHitTestSourceForTransientInput?.({ profile: "generic-touchscreen" })
        this.hitTestSource = await this.session.requestHitTestSource?.({ space: this.viewerReferenceSpace, entityTypes: ["mesh", "point"] });
        this.hitTestSourcePlanes = await this.session.requestHitTestSource?.({ space: this.viewerReferenceSpace, entityTypes: ["plane"] });
    }

    cleanup() {
        this.session.removeEventListener("selectstart", this.selectStartHandler);
        document.getElementById('touch-canvas')?.removeEventListener('touchstart', this.touchStartListener);
        document.getElementById('touch-canvas')?.removeEventListener('touchend', this.touchEndHandler);
    }

    onSessionEnded() {
        if (this.onFinishFun) this.onFinishFun()
        console.log("onSessionEnded")
    }

    onHitTest(callback: (hitPose: XRPose) => void) {
        this.onHitTestFun = callback
    }
    onTapHitTest(callback: (hitPose: XRPose, tap: { x: number, y: number }) => void) {
        this.onTapHitTestFun = callback
    }
    onObjectTap(callback: (rayIntersects: THREE.Intersection[]) => void) {
        this.onObjectTapFun = callback
    }
    onPlaneDetection(callback: (pose: XRPose) => void) {
        this.onPlaneDetectionFun = callback
    }
    onPlaneTap(callback: (hitPose: XRPose) => void) {
        this.onPlaneTapFun = callback
    }
    onTap(callback: (tap: Record<string, number>) => void) {
        this.onTapFun = callback
    }
    onPinch(callback: (delta: number) => void) {
        this.onPinchFun = callback
    }
    onPinchEnd(callback: (delta: number) => void) {
        this.onPinchEndFun = callback
    }
    onRotate(callback: (deltaYaw: number) => void) {
        this.onRotateFun = callback
    }
    onRotateEnd(callback: (deltaYaw: number) => void) {
        this.onRotateEndFun = callback
    }
    onFinish(callback: () => void) {
        this.onFinishFun = callback
    }

    selectEndHandler() {
        this.tapEndFlag = true

        if (this.onPinchEndFun !== null && this.finalSeparation !== null) {
            this.onPinchEndFun(this.finalSeparation)
        }

        if (this.onRotateEndFun !== null && this.finalDeltaYaw !== null) {
            this.onRotateEndFun(this.finalDeltaYaw * 180 / Math.PI)
        }

        this.finalSeparation = null
        this.finalDeltaYaw = null
    }

    async tick(frame: XRFrame) {
        // Find if there is a hit with a plane (from center of screen)
        if (this.onPlaneDetectionFun && this.hitTestSourcePlanes) {
            const hitTestResults1 = frame.getHitTestResults(this.hitTestSourcePlanes);
            if (hitTestResults1.length && this.localReferenceSpace) {
                const hitPose = hitTestResults1[0].getPose(this.localReferenceSpace);
                if (hitPose) {
                    this.onPlaneDetectionFun(hitPose)
                }
            }
        }

        // Find if there is a hit with a mesh / point (from center of screen)
        if (this.onHitTestFun && this.hitTestSource) {
            const hitTestResults2 = frame.getHitTestResults(this.hitTestSource);
            if (hitTestResults2.length && this.localReferenceSpace) {
                const hitPose = hitTestResults2[0].getPose(this.localReferenceSpace);
                if (hitPose) {
                    this.onHitTestFun(hitPose)
                }
            }
        }

        if (!this.transientHitTestSource) {
            return
        }

        const fingers = frame.getHitTestResultsForTransientInput(this.transientHitTestSource);
        // For the 2-finger tap case, use the fingers struct at every XRFrame.
        if (fingers.length === 2) {
            const { separation, deltaYaw } = this.fingerPolar(fingers);

            if (Math.abs(deltaYaw) < 0.5) {
                if (this.onRotateFun) {
                    // console.log("GESTURE MANAGER - ROTATING", deltaYaw)
                    this.onRotateFun(deltaYaw)
                }

                if (this.finalDeltaYaw === null) {
                    this.finalDeltaYaw = deltaYaw
                } else {
                    this.finalDeltaYaw += deltaYaw
                }
            }

            if (this.needInitScale) {
                this.initScale = separation
                this.needInitScale = false
            }

            if (this.onPinchFun) {
                // console.log("GESTURE MANAGER - SCALING", separation, this.initScale)
                this.onPinchFun(separation / this.initScale)
            }

            this.finalSeparation = separation / this.initScale

            // For the 1 finger case, use the last update screen canvas touch (if available), then set it to null. This is simulate an "onTouchBegin" behaviour.
        } else if (fingers.length === 1) {
            // To prevent a single contineous tap from counting as many taps
            if (this.tapEndFlag == false) { return }
            this.tapEndFlag = false

            var mouse = {
                x: fingers[0].inputSource.gamepad.axes[0],
                y: -fingers[0].inputSource.gamepad.axes[1]
            }

            // Order of tests: object tap test, plane tap test, scene tap test (actually hitting something), and lastly "otherwise"
            var hitFound: boolean = false
            if (this.onObjectTapFun) {
                var ray = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3());
                ray.setFromCamera(new THREE.Vector2(mouse.x, mouse.y), this.camera)
                // ray.setFromCamera(new THREE.Vector2(0, 0), this.camera)  // Use this to simulate ray from center of screen
                var rayIntersects = ray.intersectObjects(this.scene.children, true);
                // console.log("rayIntersects", rayIntersects, this.scene.children) // TODO: try to speed up the intersection. works bad with downlaoded
                if (rayIntersects[0]) {
                    this.onObjectTapFun(rayIntersects)
                    hitFound = true
                }
            }

            const offsetDirection = new THREE.Vector4(mouse.x * 0.35, mouse.y * 0.65, -1, 0).normalize()
            const XrayOffset = new XRRay(new DOMPoint(0, 0, 0), offsetDirection);
            // Perform hit test with planes
            if (this.onPlaneTapFun && !hitFound) {
                frame.session.requestHitTestSource({ space: this.viewerReferenceSpace, entityTypes: ["plane"], offsetRay: XrayOffset }).then((source) => {
                    this.tapHitTestSource = source;
                }).catch((error) => {
                    console.log("UNABLE TO REQUEST HIT TEST SOURCE")
                })
                if (this.tapHitTestSource && this.touchEnded) {
                    const hitTestResults4 = frame.getHitTestResults(this.tapHitTestSource);
                    if (hitTestResults4.length && this.localReferenceSpace) {
                        const hitPose = hitTestResults4[0].getPose(this.localReferenceSpace);
                        if (hitPose) {
                            this.onPlaneTapFun(hitPose)
                            hitFound = true
                        }
                    }
                }
            }

            // Perform hit test with objects
            if (this.onTapHitTestFun && !hitFound) {
                frame.session.requestHitTestSource({ space: this.viewerReferenceSpace, offsetRay: XrayOffset }).then((source) => {
                    this.tapHitTestSource = source
                }).catch((error) => {
                    console.log("UNABLE TO REQUEST HIT TEST SOURCE")
                })
                if (this.tapHitTestSource && this.touchEnded) {
                    const hitTestResults3 = frame.getHitTestResults(this.tapHitTestSource);
                    if (hitTestResults3.length && this.localReferenceSpace) {
                        const hitPose = hitTestResults3[0].getPose(this.localReferenceSpace);
                        if (hitPose) {
                            this.onTapHitTestFun(hitPose, this.lastScreenTouch)
                            hitFound = true
                        }
                    }
                }
            }

            this.screenTapped = false
        }
        this.lastFrame = frame
        this.touchEnded = false
    }

    fingerPolar(fingers: XRTransientInputHitTestResult[]) {
        const fingerOne = fingers[0].inputSource.gamepad.axes;
        const fingerTwo = fingers[1].inputSource.gamepad.axes;
        const deltaX = fingerTwo[0] - fingerOne[0];
        const deltaY = fingerTwo[1] - fingerOne[1];
        const angle = Math.atan2(deltaY, deltaX);
        let deltaYaw = this.lastAngle - angle;
        if (deltaYaw > Math.PI) {
            deltaYaw -= 2 * Math.PI;
        } else if (deltaYaw < -Math.PI) {
            deltaYaw += 2 * Math.PI;
        }
        this.lastAngle = angle;
        return {
            separation: Math.sqrt(deltaX * deltaX + deltaY * deltaY),
            deltaYaw: deltaYaw,
        };
    }

    // handlePlaneDetection(frame: XRFrame) {
    //     const detectedPlanes = frame.worldInformation?.detectedPlanes
    //     if (!detectedPlanes) {
    //         return
    //     }

    //     // First, let’s check if any of the planes we knew about is no longer tracked:
    //     for (const [plane, timestamp] of this.planes) {
    //         if(!detectedPlanes.has(plane)) {
    //             // Handle removed plane - `plane` was present in previous frame,
    //             // but is no longer tracked.

    //             // We know the plane no longer exists, remove it from the map:
    //             this.planes.delete(plane);
    //         }
    //     }

    //     // Then, let’s handle all the planes that are still tracked.
    //     // This consists both of tracked planes that we have previously seen (may have
    //     // been updated), and new planes.
    //     detectedPlanes.forEach(plane => {
    //         if (this.planes.has(plane)) {
    //         // Handle previously-seen plane:

    //             if(plane.lastChangedTime > this.planes.get(plane)!) {
    //                 // Handle previously seen plane that was updated.
    //                 // It means that one of the plane’s properties is different than
    //                 // it used to be - most likely, the polygon has changed.

    //                 // Render / prepare the plane for rendering, etc.

    //                 // Update the time when we have updated the plane:
    //                 this.planes.set(plane, plane.lastChangedTime);
    //             } else {
    //                 // Handle previously seen plane that was not updated in current frame.
    //                 // Note that plane’s pose relative to some other space MAY have changed.
    //             }
    //         } else {
    //             // Handle new plane.
    //             const pose = frame.getPose(plane.planeSpace, this.localReferenceSpace!)
    //             if (pose)
    //                 this.onPlaneDetectionFun?.(plane, pose)

    //             // Set the time when we have updated the plane:
    //             this.planes.set(plane, plane.lastChangedTime);
    //         }

    //         // Irrespective of whether the plane was previously seen or not,
    //         // & updated or not, its pose MAY have changed:
    //         // const planePose = frame.getPose(plane.planeSpace, this.viewerReferenceSpace!);
    //     });
    // }
}

