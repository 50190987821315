import styled from "styled-components";

interface FlexContainerProps {
  backgroundImage: string;
}

export const FlexContainer = styled.div<FlexContainerProps>`
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  z-index: 90;
  height: 100%;
  width: 100%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  padding-top: 50px;
`;

export const Title = styled.h1`
  color: white;
  font-weight: bolder;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 5px;
  & > img {
    width: 100px;
    height: 100px;
  }
`;

export const CustomizedText = styled.span<{
  primaryColor: string;
  secondaryColor: string;
}>`
  background: ${({ primaryColor, secondaryColor }) =>
    `linear-gradient(to right, rgba(${hexToRgb(primaryColor)}, 0.7), rgba(${hexToRgb(secondaryColor)}, 0.7))`};
  color: ${({ primaryColor }) =>
    primaryColor.toLowerCase() === "#ffffff" ? "black" : "white"};
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
  height: 35px; /* Set a constant height */
  line-height: 30px; /* Ensure vertical centering */
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
`;

// Helper function to convert hex to rgb
const hexToRgb = (hex: string): string => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
};

export const ItemContainer = styled.div<{
  isSelected: boolean;
  primaryColor: string;
  secondaryColor: string;
}>`
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
  position: relative;

  // Main border
  border: ${(props) => 
    props.isSelected ? `2px solid ${props.primaryColor}` : 'none'};
  
  // Secondary border effect using outline
  outline: ${(props) => 
    props.isSelected ? `2px solid ${props.secondaryColor}` : 'none'};
  outline-offset: -4px; // Adjust offset to align with the main border

  // Inner content styling
  & > div {
    position: relative;
    background-color: white;
    border-radius: 8px; // Match the outer border radius
    padding: 10px;
    z-index: 1; // Ensure it sits above the outline
  }
`;

// export const ItemContainer = styled.div<{
//   isSelected: boolean;
//   primaryColor: string;
//   secondaryColor: string;
// }>`
//   position: relative; // Positioning context for the pseudo-element
//   margin-top: 20px;
//   cursor: pointer;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 48%;
//   text-align: center;

//   // Pseudo-element for the gradient border
//   &::before {
//     content: '';
//     position: absolute;
//     top: -2px; // Move the border outward
//     left: -2px; // Move the border outward
//     right: -2px; // Move the border outward
//     bottom: -2px; // Move the border outward
//     border-radius: 8px; // Adjust for rounded corners
//     background: linear-gradient(90deg, ${primaryColor}, ${(props) => props.theme.secondaryColor});
//     opacity: ${(props) => (props.isSelected ? 1 : 0)}; // Only show when selected
//     z-index: 0; // Behind the content
//   }

//   // Inner content styling
//   & > div {
//     position: relative; // Position context for inner content
//     background-color: white; // Background for the inner content
//     border-radius: 8px; // Match the outer border radius
//     padding: 10px; // Inner padding
//     z-index: 1; // Ensure it sits above the gradient
//   }
// `;
