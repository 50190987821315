import { MenuListView } from "./menu-list-component/MenuListView"
import { useMenuProvider } from "../../template-providers/MenuProvider"

export const MenuTemplateUI = () => {
    const { showMenu, template } = useMenuProvider()

    return (
        <div>
            {
                showMenu &&
                <MenuListView
                    menuList={template.config.menuItems}
                    menuTheme={template.config.menuListViewTheme}
                    onItemClick={(item: string, index: number) => template.menuItemSelected(item, index)}
                />
            }
        </div>
    )
}