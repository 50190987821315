import { PropsWithChildren, createContext, useContext, useState } from "react";
import { MenuTemplate } from "../Templates/MenuTemplate/MenuTemplate";

const MenuContext = createContext<any>(null)

type MenuProviderProps = PropsWithChildren & { template: MenuTemplate }
const MenuProvider = ({ children, template }: MenuProviderProps) => {
    const [showMenu, setShowMenu] = useState<boolean>(false)

    template.setShowMenu = setShowMenu

    return <MenuContext.Provider value={{ showMenu, setShowMenu, template }}>{children}</MenuContext.Provider>
}

interface MenuContextValues {
    template: MenuTemplate,
    showMenu: boolean,
    setShowMenu: (status: boolean) => void
}
export const useMenuProvider = (): MenuContextValues => {
    const context = useContext(MenuContext);
    if (context === undefined) {
        throw new Error('useGlobalProvider must be used within an GlobalProvider');
    }
    return context;
};
export default MenuProvider