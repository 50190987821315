import { Button } from "antd";
import { ReactNode } from "react";
import styled from "styled-components";

const CustomizedButton = styled(Button)`
    background-color: ${(props) => props.theme.primaryBackground};
    min-height:40px;
    min-width: 110px;
    max-width: 40%;
    word-break: break-word;
    white-space: normal;
    text-align: center;
    border-radius:${(props) => props.theme.borderRadius};
    font-weight:bold;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`;

const AppButton: React.FC<{ children: ReactNode } & React.ComponentProps<typeof Button>> = ({ children, ...rest }) => (
    <CustomizedButton {...rest}>{children}</CustomizedButton>
);

export default AppButton;
