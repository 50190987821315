import { useTranslation } from "react-i18next";
import { ModalButtonsContainer, ModalContentBody, ModalTitle, ModalWrapper } from "./styled";
import { Button } from "antd";
import { LocalizationKeys } from "../../localization/LocalizationKeys";

export const CloseButtonModal: React.FC<{ closeModal: () => void, handleCloseExperience: () => void }> = ({ closeModal, handleCloseExperience }) => {
    const { t } = useTranslation();

    return (
        <ModalWrapper>
            <ModalContentBody>
                <ModalTitle>{t(LocalizationKeys.ExitModalText)}</ModalTitle>
                <ModalButtonsContainer>
                    <Button onClick={closeModal}>{t(LocalizationKeys.ExitModalCancelButton)}</Button>
                    <Button onClick={handleCloseExperience}>{t(LocalizationKeys.ExitModalOkButton)}</Button>
                </ModalButtonsContainer>
            </ModalContentBody>
        </ModalWrapper>
    )
}