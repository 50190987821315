import { ARSurveyTemplate } from "../../Templates/ARSurveyTemplate/ARSurveyTemplate";
import { BaselBurgerShootersTemplate } from "../../Templates/BaselBurgerShooters/BaselBurgerShooters";
import { PlatformManager } from "../../Templates/CustomTemplate/PlatformManager";
import { GridMetaTemplate } from "../../Templates/GridMetaTemplate/GridMetaTemplate";
import { IndoorDesignTemplate } from "../../Templates/IndoorDesign/IndoorDesignTemplate";
import { MenuTemplate } from "../../Templates/MenuTemplate/MenuTemplate"
import { PhotoAlbum } from "../../Templates/PhotoAlbumTemplate/PhotoAlbum";
import { PortalTemplate } from "../../Templates/PortalTemplate/PortalTemplate";

import MenuProvider from "../../template-providers/MenuProvider";
import BaselBurgerShootersProvider from "../../template-providers/BaselBurgerShootersProvider";
import IndoorDesignProvider from "../../template-providers/IndoorDesignProvider";
import PortalTemplateProvider from "../../template-providers/PortalTemplateProvider";

import { MenuTemplateUI } from "../../Templates/MenuTemplate/MenuTemplateUI";
import { BaselBurgerShootersTemplateUI } from "../../Templates/BaselBurgerShooters/BaselBurgerShootersUI";
import { IndoorDesignTemplateUI } from "../../Templates/IndoorDesign/IndoorDesignUI";
import { PortalTemplateTemplateUI } from "../../Templates/PortalTemplate/PortalTemplateUI";

export type TemplateClassType = PlatformManager |
    MenuTemplate |
    BaselBurgerShootersTemplate |
    IndoorDesignTemplate |
    PhotoAlbum |
    PortalTemplate |
    ARSurveyTemplate |
    GridMetaTemplate;

export const TemplateSpecificUIRenderer: React.FC<{ template: TemplateClassType }> = ({ template }) => {

    if (template instanceof MenuTemplate) {
        return (
            <>
                <MenuProvider template={template}>
                    { <MenuTemplateUI /> }
                </MenuProvider>
            </>
        );
    } else if (template instanceof BaselBurgerShootersTemplate) {
        return (
            <>
                <BaselBurgerShootersProvider template={template}>
                { <BaselBurgerShootersTemplateUI /> }
                </BaselBurgerShootersProvider>
            </>
        );
    } else if (template instanceof PortalTemplate) {
        return (
            <>
                <PortalTemplateProvider template={template}>
                { <PortalTemplateTemplateUI /> }
                </PortalTemplateProvider>
            </>
        );
    } else if (template instanceof IndoorDesignTemplate) {
        return (
            <>
                <IndoorDesignProvider template={template}>
                { <IndoorDesignTemplateUI /> }
                </IndoorDesignProvider>
            </>
        );
    // } else if (template instanceof PlatformManager) {   // IMPORTANT: make sure this comes after all other classes which inherit from PlatformManager
    //     return (<></>);
    } else {
        return (<></>);
    }
}