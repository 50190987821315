import { PropsWithChildren, createContext, useContext, useState } from "react";
import { BaselBurgerShootersTemplate } from "../Templates/BaselBurgerShooters/BaselBurgerShooters";

const BaselBurgerShootersContext = createContext<any>(null)

type BaselBurgerShootersProviderProps = PropsWithChildren & { template: BaselBurgerShootersTemplate }
const BaselBurgerShootersProvider = ({ children, template }: BaselBurgerShootersProviderProps) => {
    const [showSelectionScreen, setShowSelectionScreen] = useState<boolean>(false)

    template.setShowSelectionScreen = setShowSelectionScreen

    return <BaselBurgerShootersContext.Provider value={{ showSelectionScreen, setShowSelectionScreen, template }}>{children}</BaselBurgerShootersContext.Provider>
}

interface MenuContextValues {
    template: BaselBurgerShootersTemplate,
    showSelectionScreen: boolean,
    setShowSelectionScreen: (status: boolean) => void
}
export const useBaselBurgerShootersProvider = (): MenuContextValues => {
    const context = useContext(BaselBurgerShootersContext);
    if (context === undefined) {
        throw new Error('useGlobalProvider must be used within an GlobalProvider');
    }
    return context;
};
export default BaselBurgerShootersProvider