import { createContext, useEffect, useState, lazy, useReducer, ReactNode } from "react";
import { convertToRequestSource, convertToRequestType, ICustomerResponse, RequestSource, RequestType, ServerManager } from "../Managers/ServerManager.ts";
import { v4 as uuidv4 } from 'uuid';
import { Mixpanel } from '../Managers/AnalyticsClient/AnalyticsClient.ts';
import { AnalyticsEventPropertyKeys, AnalyticsKeys } from "../Managers/AnalyticsClient/AnalyticsKeys.ts";
import { ExperienceType, IExperienceJson } from "../TriggerAction/Config.ts";
import { BrandTheme, ExperienceMetadata, ExperienceStyling } from "../ExperienceObjects/ExperienceObject.ts";
// import { PageRoutingConfig } from "../components/PageRoutingInterface.ts";
import { DefaultPages, PageNames, Pages } from "./consts.ts";
import { ErrorType } from "../components/pages/error-page/errorTypes.ts";
import { HomePage, IntroScreen } from "../components/pages/index.ts";
import { DoubleShootersExperience, ShootersDemo1234, legoExperience, surveyExperience, testReplaceObject } from "../ExperienceObjects/ExperienceLoader.ts";


/////// Firebase config
import { initializeApp } from 'firebase/app';
import { RemoteConfig, getRemoteConfig, getValue, fetchAndActivate, Value } from "firebase/remote-config";
import { BaselBurgerDemoExperience } from "../Templates/BaselBurgerShooters/BaselBurgerShootersDemo.ts";
import { menuTemplateDemoExperience } from "../Templates/MenuTemplate/MenuTemplateDemoExperience.ts";
import { GridMetaTemplateDemoExperience } from "../Templates/GridMetaTemplate/GridMetaTemplateDemo.ts";
import { IndoorDesignDemoExperience } from "../Templates/IndoorDesign/IndoorDesignTemplateDemo.ts";
import { PhotoAlbumDemoExperience } from "../Templates/PhotoAlbumTemplate/PhotoAlbumDemo.ts";
import { PortalTemplateDemoExperience } from "../Templates/PortalTemplate/PortalTemplateDemo.ts";

import i18n from 'i18next';
import * as Sentry from '@sentry/react';
import { DoritosExperience } from "../Templates/DemoExperiences/DoritosExperience.ts";
import { DoritosExperience0 } from "../Templates/DemoExperiences/DoritosExperience0.ts";
import { DoritosExperience1 } from "../Templates/DemoExperiences/DoritosExperience1.ts";

const firebaseConfig = {
    apiKey: "AIzaSyCjSY3-clsfq4EO8fjnk7H7zcN27cWQS1o",
    authDomain: "arplatform-99ab9.firebaseapp.com",
    databaseURL: "https://arplatform-99ab9-default-rtdb.firebaseio.com",
    projectId: "arplatform-99ab9",
    storageBucket: "arplatform-99ab9.appspot.com",
    messagingSenderId: "376850214003",
    appId: "1:376850214003:web:dcd64f96c7fef2cad64e06",
    measurementId: "G-F6XWKZ105K"
};

const app = initializeApp(firebaseConfig);
////////

export interface IUrlParamsDict {
    id: string;
    type: RequestType;
    source: RequestSource;
    qrId?: string; 
}

export const AppStore = createContext<any>({} as any)
Mixpanel.init()

export const AppStoreProvider = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [pageComponents, setComponents] = useState<Pages[]>([])
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [errorType, setErrorType] = useState<string>("")
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [showErrorPage, setShowErrorPage] = useState<boolean>(false)
    const [globalMetadata, setMetadata] = useState<ExperienceMetadata>()
    const [globalTheme, setTheme] = useState<BrandTheme>()
    const [loadingExperienceJson, setLoadingExperienceJson] = useState<boolean>(false)
    const [startExperience, setStartExperience] = useState<boolean>(false)
    const [userRegistered, setUserRegistered] = useState<boolean>(false)
    const [urlParams, setUrlParams] = useState<IUrlParamsDict | null>(null)
    const [experienceUrl, setExperienceUrl] = useState<string | null>(null)
    const [allExperiences, setAllExperiences] = useState<ICustomerResponse[] | null>(null)
    const [appComponentToRender, setAppComponentToRender] = useState<ReactNode | null>(null)
    const [defaultPage, setDefaultPage] = useState<DefaultPages | null>(null)
    const [experienceScore, setExperienceScore] = useState<number>(0)
    const [userSession, setUserSession] = useState<string>("noUserID")

    const [introDescription, setIntroDescription] = useState<string | null>(null)
    const [introTitle, setIntroTitle] = useState<string | null>(null)
    const [introLogoUrl, setIntroLogoUrl] = useState<string | null>(null)
    const [defaultInvocationId, setDefaultInvocationId] = useState<string | null>(null)
    const [defaultInvocationType, setDefaultInvocationType] = useState<RequestType | null>(null)
    const [experienceStyling, setExperienceStyling] = useState<ExperienceStyling | null>(null)
    
    let remoteConfig: RemoteConfig | null = null
    let components: Array<Pages> = []
    var pageJsonParams: Record<string, Record<string, any>> = {}

    useEffect(() => {
        if (!userRegistered)
            init()
    }, [userRegistered])

    useEffect(() => {
        if (userRegistered && urlParams && urlParams.id && urlParams.type) {
            fetchData()
        }

        if (urlParams && (defaultInvocationId !== null && defaultInvocationType !== null) && (urlParams.id === null || urlParams.type === null)) {
            Mixpanel.track(AnalyticsKeys.DefaultParametersAssigned, {
                [AnalyticsEventPropertyKeys.ExperienceID]: defaultInvocationId,
                [AnalyticsEventPropertyKeys.ExperienceType]: defaultInvocationType
            })
            setUrlParams({ ...urlParams, id: defaultInvocationId, type: defaultInvocationType })
        }
    }, [urlParams, userRegistered, defaultInvocationId, defaultInvocationType])

    const init = async () => {
        setAppComponentToRender(<IntroScreen />)
        initFirebaseRemoteConfig()
        await handleUserSessionID()
        parseUrl()
    }

    const initFirebaseRemoteConfig = async () => {
        remoteConfig = getRemoteConfig(app);
        const rcDefaultsFile = await fetch('/assets/Files/remote_config_defaults.json');
        const rcDefaultsJson = await rcDefaultsFile.json();
        remoteConfig.defaultConfig = rcDefaultsJson;

        fetchAndActivate(remoteConfig)
            .then(() => {
                getRemoteConfigValues()
            })
            .catch((err) => {
                console.log("Error fetching remote config!!", err)
            })
    }

    const getRemoteConfigValues = () => {
        const description = getValue(remoteConfig, "intro_description").asString()
        setIntroDescription(description)
        const title = getValue(remoteConfig, "intro_title").asString()
        setIntroTitle(title)
        const OrbIntroLogo = getValue(remoteConfig, "orb_intro_empty_logo").asString()
        setIntroLogoUrl(OrbIntroLogo)

        setDefaultInvocationId(getValue(remoteConfig, "default_invocation_id").asString())
        const defaultType = convertToRequestType(getValue(remoteConfig, "default_invocation_type").asString())
        setDefaultInvocationType(defaultType)
    }

    const addEndUser = async (urlParams: IUrlParamsDict) => {
        const currentDate = new Date().toISOString()
        var prevDate = currentDate
        const storedEntranceCount = Number(localStorage.getItem(`entranceCount_${urlParams.id}`))
        const count = storedEntranceCount ? storedEntranceCount + 1 : 1
        if (storedEntranceCount) {
            prevDate = localStorage.getItem(`Timestamp_${storedEntranceCount}_${urlParams.id}`)
        }

        localStorage.setItem(`entranceCount_${urlParams.id}`, `${count}`)
        localStorage.setItem(`Timestamp_${count}_${urlParams.id}`, `${currentDate}`)

        ServerManager.writeNewUserSession({
            id: urlParams.id,
            type: urlParams.type,
            userID: localStorage.getItem('user-session'),
            source: urlParams.source,
            ...(urlParams.qrId !== undefined && { qrId: urlParams.qrId }), // Conditionally add qrId
            count,
            timestamp: currentDate,
            prevTimestamp: prevDate
        })
    }

    const parseUrl = async () => {
        const shareUrlPrefix = `https://arplatform-redirection.web.app/`
        const shareUrlParams = location.search
        const params = new URLSearchParams(shareUrlParams);
        if (params.has('fbclid')) {
          params.delete('fbclid');
        }
        const updatedShareUrlParams = "?" + params.toString();
        const shareUrl = shareUrlPrefix + updatedShareUrlParams
        setExperienceUrl(shareUrl)
        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get('id');
        const sourceString = searchParams.get('source')
        const source: RequestSource = convertToRequestSource(sourceString)
        let typeString = searchParams.get('type')
        let type: RequestType = convertToRequestType(typeString)
        const qrId = searchParams.get('qrId') || undefined
        const urlParamsDict: IUrlParamsDict = {
            id,
            type,
            source,
            qrId
        }
        setUrlParams(urlParamsDict)
        Mixpanel.track(AnalyticsKeys.AppLaunch, {
            [AnalyticsEventPropertyKeys.LaunchURL]: location.toString(),
            [AnalyticsEventPropertyKeys.invocationID]: id,
            [AnalyticsEventPropertyKeys.invocationType]: type,
            [AnalyticsEventPropertyKeys.invocationSource]: source,
            [AnalyticsEventPropertyKeys.invocationQrId]: qrId
        })
    }

    const handleUserSessionID = async () => {
        var userSession = localStorage.getItem('user-session')
        if (!userSession) {
            const newUserSession = uuidv4()
            localStorage.setItem('user-session', newUserSession)
            setUserSession(newUserSession)
            identifyUserInLoggingSystems(newUserSession)
            Mixpanel.track(AnalyticsKeys.UserIDAssigned, { [AnalyticsEventPropertyKeys.userID]: newUserSession, [AnalyticsEventPropertyKeys.isNewUserID]: "true" })
            userSession = newUserSession
        } else {
            identifyUserInLoggingSystems(userSession)
            setUserSession(userSession)
            Mixpanel.track(AnalyticsKeys.UserIDAssigned, { [AnalyticsEventPropertyKeys.userID]: userSession, [AnalyticsEventPropertyKeys.isNewUserID]: "false" })
        }
        if (!localStorage.getItem('token')) {
            const token = await ServerManager.silentLogin(userSession)
            localStorage.setItem('token', token)
        }
        // console.log(`User identified with ID: ${localStorage.getItem('user-session')}`)
        setUserRegistered(true)
    }

    const identifyUserInLoggingSystems = (id: string) => {
        Sentry.setUser({
            id: id
        });
        Mixpanel.identify(id)
    }

    const fetchData = async () => {
        switch (urlParams.type) {
            case RequestType.CUSTOMER:
                setDefaultPage(DefaultPages.homePage)
                await handleCustomerId(urlParams.id, urlParams.qrId)
                break;
            case RequestType.EXPERIENCE:
                setDefaultPage(DefaultPages.introScreen)
                await handleSingleExperience(urlParams.id)
                break;
            default:
                setErrorType(ErrorType.BadIDType)
                setErrorMessage("Bad Type in params!")
                setShowErrorPage(true)
                break;
        }
        setLoading(false)
    }

    const handleCustomerId = async (customerId: string, qrId?: string) => {
        try {
            let response: ICustomerResponse[]
            if (qrId) {
                response = await ServerManager.getCustomerExperiencesSubset(customerId, qrId)
            } else {
                response = await ServerManager.getCustomerExperiences(customerId)
            }
            setAllExperiences(response)
        } catch (err) {
            setErrorMessage(err.message)
            setErrorType(ErrorType.ServerError)
            setShowErrorPage(true)
        }
    }

    const handleSingleExperience = async (expId: string) => {
        var jsonFile: IExperienceJson
        try {
            switch (expId) {
                case ExperienceType.BUILDYOUROWN:
                    // jsonFile = JSON.parse(ShootersDemo1234);
                    // jsonFile = JSON.parse(DoubleShootersExperience);
                    // jsonFile = JSON.parse(testReplaceObject);
                    // jsonFile = JSON.parse(DoritosExperience);
                    jsonFile = JSON.parse(DoritosExperience0);
                    // jsonFile = JSON.parse(DoritosExperience1);
                    break
                case ExperienceType.INDOORDESIGN:
                    jsonFile = JSON.parse(IndoorDesignDemoExperience);
                    break
                case ExperienceType.PHOTOALBUM:
                    jsonFile = JSON.parse(PhotoAlbumDemoExperience);
                    break
                case ExperienceType.PORTALTEMPLATE:
                    jsonFile = JSON.parse(PortalTemplateDemoExperience);
                    break
                case ExperienceType.BASELBURGERSHOOTERS:
                    jsonFile = JSON.parse(BaselBurgerDemoExperience);
                    break
                case ExperienceType.GRIDTEMPLATE:
                    jsonFile = JSON.parse(GridMetaTemplateDemoExperience);
                    break
                case ExperienceType.SURVEY:
                    jsonFile = JSON.parse(surveyExperience);
                    break;
                default:
                    const jsonData = await ServerManager.getExperienceJsonFile(expId)
                    jsonFile = JSON.parse(jsonData);
            }
            Mixpanel.track(AnalyticsKeys.JSONDownloaded, { [AnalyticsEventPropertyKeys.ExperienceID]: expId })
            parseExperienceJsonFile(jsonFile)
        } catch (error) {
            setErrorType(ErrorType.ServerError)
            setErrorMessage("Could not load experience properties...")
            setShowErrorPage(true)
            throw new Error(`Error - could not handle single experience ${expId}`)
        }
    }

    const experienceSelected = async (expId: string) => {
        setLoadingExperienceJson(true)
        await handleSingleExperience(expId)
        setLoadingExperienceJson(false)
        const urlParamsDict: IUrlParamsDict = {
            id: expId,
            type: RequestType.EXPERIENCE,
            source: RequestSource.APP,
            ...(urlParams.qrId !== undefined && { qrId: urlParams.qrId }) // Conditionally add qrId
        }
        addEndUser(urlParamsDict)
        setStartExperience(true)
    }


    const parseExperienceJsonFile = (json: IExperienceJson) => {
        try {
            let metadata = new ExperienceMetadata(json.metadata)
            let theme = new BrandTheme(json.theme)
            setMetadata(metadata)
            setTheme(theme)

            let styling = new ExperienceStyling(json.styling)
            handleAppStyling(styling)

            const pageRoutingConfig = json.pageRoutingConfig;
            Mixpanel.track(AnalyticsKeys.MetadataExtraction, {
                [AnalyticsEventPropertyKeys.ExperienceType]: metadata.experienceType,
                [AnalyticsEventPropertyKeys.ExperienceID]: metadata.experienceID
            })
            Mixpanel.track(AnalyticsKeys.ThemeExtraction)
            Mixpanel.track(AnalyticsKeys.RoutingConfigExtraction)
            for (const entry of pageRoutingConfig) {
                let pageConfigs: Pages = {} as any
                let pageName = entry.pageName as PageNames
                pageConfigs.pageName = pageName
                if (entry.pageParamsName && entry.pageParamsName !== undefined && entry.pageParamsName in json) {
                    pageJsonParams[pageName] = json[entry.pageParamsName]
                    pageConfigs.params = json[entry.pageParamsName]
                } else if (entry.pageParamsName && entry.pageParamsName !== undefined) {
                    throw new Error(`Json key error - key ${entry.pageParamsName} not found`)
                }
                components.push(pageConfigs)
            }
            setComponents(components)
        } catch (error) {
            setErrorType(ErrorType.BadJSON)
            setErrorMessage(error.message)
            setShowErrorPage(true)
        }
    }

    const handleAppStyling = (styling?: ExperienceStyling) => {
        if (styling) {
            setExperienceStyling(styling)
            if (styling.language) {
                i18n.changeLanguage(styling.language)
            }
    
            if (styling.font) {
                document.documentElement.style.setProperty('--font-family', styling.font);
            }
        }
    }

    const handleIntroPageComplete = () => {
        if (!localStorage.getItem('policyAccepted')) {
            localStorage.setItem('policyAccepted', new Date().toISOString())
        }
        addEndUser(urlParams)
        switch (defaultPage) {
            case DefaultPages.introScreen:
                setStartExperience(true)
                break;
            case DefaultPages.homePage:
                setAppComponentToRender(<HomePage />)
                break;
            default:
                setErrorMessage("Something went wrong!")
                setErrorType(ErrorType.Other)
                setShowErrorPage(true)
                break;
        }
    }

    return (
        <AppStore.Provider value={{
            loading,
            pageComponents,
            errorType,
            errorMessage,
            showErrorPage,
            setShowErrorPage,
            globalTheme,
            globalMetadata,
            loadingExperienceJson,
            startExperience,
            handleIntroPageComplete,
            allExperiences,
            experienceSelected,
            appComponentToRender,
            setStartExperience,
            experienceUrl,
            introDescription,
            introTitle,
            introLogoUrl,
            showAlert,
            setShowAlert,
            setErrorMessage,
            setErrorType,
            setExperienceScore,
            experienceScore,
            urlParams,
            addEndUser,
            userSession,
            experienceStyling
        }}>
            {children}
        </AppStore.Provider>
    )
}