import { Modal, Popover } from "antd";
import { IGiftSheet, IWelcomePage } from "../interfaces";
import { CustomizedButton, MainWrapper, Note, Image, WelcomeScreenButtonsWrapper, NotesContainer, ButtonsAndNotesContainer } from "./styled";
import { CloseOutlined, GiftOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { ExperienceStore } from "../../../app-store/experienceStore";
import { Mixpanel } from "../../../Managers/AnalyticsClient/AnalyticsClient";
import { AnalyticsEventPropertyKeys, AnalyticsKeys } from "../../../Managers/AnalyticsClient/AnalyticsKeys";
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from "../../../localization/LocalizationKeys"
import { PageCloseButton } from "../exit-button/styled";
import GiftSheetPopover from "../../utils/GiftSheet";
import "./styles.css"

export interface WelcomeScreenInput {
    imageUrl: string;
    title: string;
    description: string;
    notes: string[];
    videoUrl?: string;
    giftSheetInput?: IGiftSheet;
    backgroundImageUrl?: string;
    policyUrl?: string;
}

export const WelcomeScreen: React.FC<IWelcomePage> = ({ metadata, theme, input, onPageComplete }) => {
    const { imageUrl, description, notes, videoUrl, title, giftSheetInput, backgroundImageUrl, policyUrl } = input;
    const { handleExitExperience } = useContext(ExperienceStore)
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [pdfModalVisible, setPdfModalVisible] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        Mixpanel.track(AnalyticsKeys.WelcomeScreen, {})
    }, []);

    const continueButtonTapped = () => {
        Mixpanel.track(AnalyticsKeys.ButtonTapped, {
            [AnalyticsEventPropertyKeys.buttonID]: "Continue"
        })

        onPageComplete();
        // setTimeout(() => {
        //     onPageComplete();
        // }, 5000);
    }

    const closeButtonTapped = () => {
        Mixpanel.track(AnalyticsKeys.ButtonTapped, {
            [AnalyticsEventPropertyKeys.buttonID]: "Exit"
        })
        handleExitExperience()
    }

    const handlePopoverOpen = () => {
        setPopoverVisible(true);
    };

    const handlePopoverClose = () => {
        setPopoverVisible(false);
    };

    const handlePdfModalClose = () => {
        setPdfModalVisible(false);
    };

    const handlePdfModalOpen = () => {
        setPdfModalVisible(true);
    };

    return (
        <MainWrapper backgroundImageUrl={backgroundImageUrl}>
            <PageCloseButton
                onClick={closeButtonTapped}
                shape="circle"
                icon={<CloseOutlined />}
            />
            {popoverVisible &&
                <Modal
                    className="custom-modal"
                    open={true}
                    centered
                    onCancel={handlePopoverClose}
                    footer={null}
                    closeIcon={null}

                >
                    <GiftSheetPopover
                        giftSheetInput={giftSheetInput}
                        onClose={() => handlePopoverClose()}
                    />
                </Modal>
            }
            {/* {policyUrl && (<Modal
                className="custom-modal"
                open={pdfModalVisible}
                centered
                onCancel={handlePdfModalClose}
                footer={null}
                closeIcon={null}
                width="80%" // Adjust as needed
                style={{ top: 20 }} // You can adjust the modal styling
            >
                <div style={{ height: '80%', overflowY: 'auto' }}>
                    <iframe
                        src={policyUrl}
                        style={{ width: '100%', height: '100%', border: 'none' }}
                        title="PDF Viewer"
                    />
                </div>
            </Modal>
            )} */}
            <div>
                {
                    imageUrl &&
                    <Image src={imageUrl} alt="Welcome" />
                }
                <h3>{title}</h3>
            </div>
            {
                videoUrl &&
                <video style={{ width: '90%', height: 230, margin: '10px 2px' }} controls>
                    <source src={videoUrl} type="video/mp4"></source>
                </video>
            }
            <p>{description}</p>
            {/* <div style={{ textAlign: 'left' }}>
                {notes.map((note, index) => (
                    <div key={index}>
                        <Note>{note}</Note>
                    </div>
                ))}
            </div> */}
            <ButtonsAndNotesContainer>
                <NotesContainer>
                    {notes.map((note, index) => (
                        <div key={index}>
                            <Note>{note}</Note>
                        </div>
                    ))}
                </NotesContainer>
                <WelcomeScreenButtonsWrapper>
                    {
                        giftSheetInput && (
                            <CustomizedButton primaryColor={theme.primaryColor} secondaryColor={theme.secondaryColor} onClick={handlePopoverOpen}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <GiftOutlined style={{ fontSize: '30px', marginRight: '8px' }} />
                                    <span>{t(LocalizationKeys.WelcomePrizesButton)}</span>
                                </div>
                            </CustomizedButton>
                        )
                    }
                    <CustomizedButton primaryColor={theme.primaryColor} secondaryColor={theme.secondaryColor} onClick={continueButtonTapped}>
                        {t(LocalizationKeys.WelcomeContinueButton)}
                    </CustomizedButton>
                </WelcomeScreenButtonsWrapper>
                {
                    policyUrl &&
                    <p>
                        <a href={policyUrl} target="_blank" rel="noopener noreferrer">
                        {t(LocalizationKeys.WelcomePolicyString)}
                        </a>
                    </p>
                }
            </ButtonsAndNotesContainer>
        </MainWrapper>
    );
}