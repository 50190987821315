import styled from "styled-components";
import { Button } from "antd";

export const MainWrapper = styled.div`
  padding: 14px 5px;
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1;
  & > header {
    flex: 0.7;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 22px;
    justify-content: space-evenly;
    gap: 15px;
  }
`;
