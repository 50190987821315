import { ILoadingPage } from "../interfaces";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";

export const LoadingPage: React.FC<ILoadingPage> = ({
    lottieFileName,
    loadingText,
}) => {
    const [lottieFileData, setLottieFileData] = useState<any | null>(null);

    useEffect(() => {
        const fetchLottieData = async () => {
            try {
                const response = await fetch(`/assets/Lottie/${lottieFileName}.json`);
                if (!response.ok) {
                    throw new Error("Loading page - Failed to fetch data");
                }
                const data = await response.json();
                setLottieFileData(data);
            } catch (error) {
                console.error("Loading page - Error fetching data:", error);
            }
        };

        fetchLottieData();
    }, [lottieFileName]);

    return (
        lottieFileData && (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    position: "absolute", // Make it fill the parent
                    top: 0,
                    left: 0,
                    textAlign: "center",
                }}
            >
                <Lottie animationData={lottieFileData} />
                {loadingText && (
                    <p style={{ marginTop: "20px", color: "#000000" }}>{loadingText}</p> // Adjust margin and text color as needed
                )}
            </div>
        )
    );
};
