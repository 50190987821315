import styled from "styled-components";

export const MainWrapper = styled.div`
  width: 95vw;
  height: 100vh;
`;

export const InnerContainer = styled.div`
  height: 100%;
  width: 100%;
  gap:20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

export const RegisterButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  margin: auto;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  gap: 20px;
`;

export const Paragraph = styled.p`
  font-weight: bolder;
  font-size: 0.9rem;
  line-height: 1px;
`;
