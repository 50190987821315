import * as THREE from "three";
import { v4 as uuidv4 } from "uuid";
import { Trigger } from "../TriggerAction/Triggers";
import { Action } from "../TriggerAction/Actions";
import {
  ExperienceType,
  IMetadata,
  ITheme,
  ICustomExperience,
  IActivity,
  IGeneralRule,
  IExperienceObject,
  IStyling,
  SupportedLanguages,
  SupportedFonts,
} from "../TriggerAction/Config";
import {
  UIElementProps,
  ORBButtonUI,
  ORBImageUI,
  ORBStatsUI,
  IUIElementPropsDict,
  IORBImageUI,
  IORBButtonUI,
  IORBStatsUI,
} from "../Managers/UIManager";
import { DispatchGroup } from "../utils";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { TriggerType } from "../TriggerAction/Triggers";

THREE.Cache.enabled = true;

export class ExperienceStyling {
  language?: SupportedLanguages;
  font?: SupportedFonts;

  constructor(stylingDict?: IStyling) {
    if (stylingDict) {
      if (Object.values(SupportedLanguages).includes(stylingDict.language as SupportedLanguages)) {
        this.language = stylingDict.language as SupportedLanguages
      }
      if (Object.values(SupportedFonts).includes(stylingDict.font as SupportedFonts)) {
        this.font = stylingDict.font as SupportedFonts
      }
    }
  }
}

export class ExperienceMetadata {
  experienceID: string;
  experienceType: ExperienceType;
  experienceName?: string;
  description?: string;
  imageUrl?: string;

  constructor(metadataDict: IMetadata) {
    this.experienceID = metadataDict.experienceID;
    this.experienceName = metadataDict.experienceName;
    this.description = metadataDict.description;
    this.imageUrl = metadataDict.imageUrl;

    // Make sure the template type for trigger-action experiences is BuildYourOwn and not "custom"
    // This is for backward compatability. There shouldn't be any more "custom" experiences.
    if (metadataDict.experienceType === "custom") {
      metadataDict.experienceType = ExperienceType.BUILDYOUROWN
    }
    if (!Object.values(ExperienceType).includes(metadataDict.experienceType as ExperienceType)) {
      throw Error(`Experience not supported at this time...`);
    } else {
      this.experienceType = metadataDict.experienceType as ExperienceType;
    }
  }

  toDictionary(): Record<string, any> {
    return {
      experienceID: this.experienceID,
      experienceType: this.experienceType
    };
  }
}

export class BrandTheme {
  imageUrl: string;
  primaryColor: string;
  secondaryColor: string;
  plantLogoOnSurfaces: boolean;

  constructor(themeDict: ITheme) {
    // for (const [tmp, paramName] of Object.entries(BrandThemeKeys)) {
    //   if (!(paramName in themeDict)) {
    //     throw new Error(`Metadata parameter ${paramName} is missing!`);
    //   }
    // }

    this.imageUrl = themeDict.imageUrl;
    this.plantLogoOnSurfaces = themeDict.plantLogoOnSurfaces;
    this.primaryColor = this.convertRGBToHex(themeDict.primaryColor) ?? "#12eace";
    this.secondaryColor = this.convertRGBToHex(themeDict.secondaryColor) ?? "#000000";
  }

  convertRGBToHex(rgbArray: number[]): string {
    if (rgbArray.length !== 3) {
      throw new Error(
        "Invalid RGB array length. Expected an array with three values."
      );
    }

    const [red, green, blue] = rgbArray.map((value) => {
      if (value < 0 || value > 255 || isNaN(value)) {
        throw new Error("RGB values should be integers between 0 and 255.");
      }
      return Math.round(value);
    });

    const toHex = (c: number) => {
      const hex = c.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };

    const redHex = toHex(red);
    const greenHex = toHex(green);
    const blueHex = toHex(blue);

    return `#${redHex}${greenHex}${blueHex}`;
  }
}

export class Activity {
  id: string;
  trigger: Trigger;
  actions: Action[] = [];
  maxExecutions: number = 1;

  constructor(activityDict: IActivity) {
    this.id = uuidv4();
    this.trigger = new Trigger(activityDict.trigger);
    for (const actionDict of activityDict.actions) {
      this.actions.push(new Action(actionDict));
    }
    if (activityDict.maxExecutions !== undefined) {
      this.maxExecutions = activityDict.maxExecutions == -1 ? Number.MAX_VALUE : activityDict.maxExecutions;
    }
  }

  clone() {
    return Object.assign({}, this);
  }

  isBringToLife() {
    for (const action of this.actions) {
      if (action.isBringToLifeAction()) {
        return true;
      }
    }
    return false;
  }

  isOnDeltaBringToLife() {
    if (this.trigger.isDeltaTrigger()) {
      return this.isBringToLife();
    }

    return false;
  }
}

export class ExperienceObject {
  id: string;
  name: string;
  object: THREE.Object3D | null = null;
  activities: Activity[] = [];
  maxExecutions: number;
  experienceObjectDict: IExperienceObject;
  constructor(experienceObjectDict: IExperienceObject) {
    this.id = experienceObjectDict.id ?? uuidv4();
    this.name = experienceObjectDict.name;

    for (const activityDict of experienceObjectDict.activities) {
      this.activities.push(new Activity(activityDict));
    }
    this.maxExecutions = experienceObjectDict.maxExecutions ?? Infinity;
    this.experienceObjectDict = experienceObjectDict;
  }

  async loadData(callback: (success: boolean) => void) {
    if (this.experienceObjectDict.object) {
      const objectDict = this.experienceObjectDict.object;
      const objectLoader = new ARObjectLoader(objectDict);
      if (objectLoader) {
        this.object = await objectLoader.loadARObject();
        callback(this.object != null);
      } else {
        callback(false);
      }
    }
  }

  createObjectSettings(objectDict: Record<string, any>): ARObjectSettings {
    return new ARObjectSettings(objectDict.settings);
  }

  clone() {
    var newExperienceObjectDict: IExperienceObject = Object.assign({}, this.experienceObjectDict)
    delete newExperienceObjectDict["id"];
    const newExperience = new ExperienceObject(newExperienceObjectDict);
    if (this.object) {
      newExperience.object = this.object.clone();
      // NEED TO RESET ANY CHANGED PROPERTIES IN THE OBJECT
      newExperience.object.userData.experienceID = newExperience.id;
      newExperience.object.userData.experienceName = newExperience.name;
    }
    return newExperience;
  }

  instantiateExperience() {
    // console.log("INSTANTIATE PRE", this)
    var experience = this.clone();

    // If a trigget has an experience ID which is the old experience ID, change it to the newly instantiated experience ID
    for (let idx = 0; idx < experience.activities.length; idx++) {
      switch (experience.activities[idx].trigger.triggerType) {
        case TriggerType.onTapObject:
          if (experience.activities[idx].trigger.params.id == this.id) {
            experience.activities[idx].trigger.params.id = experience.id;
          }
          break;
        case TriggerType.onCollideWith:
          if (
            [this.id, ""].includes(
              experience.activities[idx].trigger.params.id1
            )
          ) {
            experience.activities[idx].trigger.params.id1 = experience.id;
          }
          if (
            [this.id].includes(experience.activities[idx].trigger.params.id2)
          ) {
            experience.activities[idx].trigger.params.id2 = experience.id;
          }
          break;
        case TriggerType.onRandDelta:
          // Make sure that duplicated timers have the same timerID
          experience.activities[idx].trigger.params.timerID =
            this.activities[idx].trigger.params.timerID;
          break;
      }
      // Make sure to rrecompute the triggerID as the IDs / params might have changed
      experience.activities[idx].trigger.computeTriggerID();
    }
    // console.log("INSTANTIATE POST", experience)

    return experience;
  }
}

export class GeneralRule {
  activity: Activity;

  constructor(generalRulesDict: IGeneralRule) {
    this.activity = new Activity(generalRulesDict.activity);
  }
}

export class FullExperience {
  experienceDict: ICustomExperience;
  experiences: ExperienceObject[] = [];
  UIObjects: UIElementProps[] = [];
  generalRules: GeneralRule[] = [];

  constructor(experienceDict: ICustomExperience) {
    // if (
    //   !(FullExperienceKeys.objectsKey in experienceDict) ||
    //   !(FullExperienceKeys.generalRulesKey in experienceDict) ||
    //   !(FullExperienceKeys.UIObjectsKey in experienceDict)
    // ) {
    //   console.log(`An experience key is missing!!`);
    // }

    this.experienceDict = experienceDict;
  }

  // Iterate over every object / uiObject, and try to load the relevant data.
  // Only successfully-loaded objects are added to the current full experience.
  // Once all objects have loaded / failed to load, the function finishes
  async loadData() {
    console.log("FullExperience - loading full experience data...");
    const group1 = new DispatchGroup();
    const group2 = new DispatchGroup();

    // For every experience object dictionary, create a new ExperienceObject, then load its data. If it succeeds, add it to this.objects
    for (const rawExperienceDict of this.experienceDict.objects) {
      const rawExperience = new ExperienceObject(rawExperienceDict);
      group1.enter();
      rawExperience.loadData((success) => {
        if (success) {
          this.experiences.push(rawExperience);
        }
        // TODO: alert in case of failure for easy detection or for stopping the un-fully-loaded experience
        group1.leave();
      });
    }

    // For every UIelement dict, create one of the UI elements, then load its data. If it succeedsm add it to the this.UIObjects
    const uiObjectsLoader = new UIObjectLoader(this.experienceDict.UIObjects);
    this.UIObjects = await uiObjectsLoader.loadData();

    for (const generalRuleDict of this.experienceDict.generalRules) {
      const generalRule = new GeneralRule(generalRuleDict);
      this.generalRules.push(generalRule);
    }

    await group1.wait();
    await group2.wait();
  }

  loadDataCallback(success: boolean, element: UIElementProps) {
    if (success) {
      this.UIObjects.push(element);
    }
  }
}

// export enum ScaleAxis {
//     x = 0,
//     y = 1,
//     z = 2
// }

export class ARObjectSettings {
  allowUserGestures: boolean = false;
  size: number = 0.05;
  scaleAxis: number = 0;
  offsetVec: THREE.Vector3 = new THREE.Vector3(0, 0, 0);
  addToAnalytics: boolean = false;
  timeOut: number = -1;
  addPhysicsBody: boolean = true;
  physicsShapeType: number = 2; // 0 - Static, 1 - dynamic, 2 - kinematic
  isAffectedByGravity: boolean = false;

  constructor(settingsDict?: Record<string, any>) {
    if (settingsDict) {
      if (typeof settingsDict.allowUserGestures === "boolean") {
        this.allowUserGestures = settingsDict.allowUserGestures;
      }

      if (typeof settingsDict.size === "number") {
        this.size = settingsDict.size;
      }

      if (Array.isArray(settingsDict.offsetVec)) {
        this.offsetVec = new THREE.Vector3(
          settingsDict.offsetVec[0],
          settingsDict.offsetVec[1],
          settingsDict.offsetVec[2]
        );
      }

      if (typeof settingsDict.scaleAxis === "number") {
        this.scaleAxis = settingsDict.scaleAxis;
      }

      if (typeof settingsDict.addToAnalytics === "boolean") {
        this.addToAnalytics = settingsDict.addToAnalytics;
      }

      if (typeof settingsDict.timeOut === "number") {
        this.timeOut = settingsDict.timeOut;
      }

      if (typeof settingsDict.addPhysicsBody === "boolean") {
        this.addPhysicsBody = settingsDict.addPhysicsBody;
      }

      if (typeof settingsDict.physicsShapeType === "number") {
        this.physicsShapeType = settingsDict.physicsShapeType;
      }

      if (typeof settingsDict.isAffectedByGravity === "boolean") {
        this.isAffectedByGravity = settingsDict.isAffectedByGravity;
      }
    }
  }
}

interface NetworkARObject {
  fileName: string;
  fileUrl: string;
  fileUrlAndroid: string;
  fileThumbnail: string;
  settings: ARObjectSettings;
}

export type NetworkARObjectDict = {
  fileName: string;
  fileUrl: string;
  fileUrlAndroid?: string;
  fileThumbnail?: string;
  settings?: { [key: string]: any };
};

export class ARObjectLoader implements NetworkARObject {
  object: THREE.Object3D | null = null;
  fileName: string;
  fileUrl: string;
  fileUrlAndroid: string;
  fileThumbnail: string;
  settings: ARObjectSettings;
  data: NetworkARObjectDict;

  constructor(data: NetworkARObjectDict) {
    this.fileName = data.fileName;
    this.fileUrl = data.fileUrl;
    this.fileUrlAndroid = data.fileUrlAndroid ?? data.fileUrl;
    this.fileThumbnail = data.fileThumbnail ?? "";
    this.settings = new ARObjectSettings(data.settings);
    this.data = data
  }

  async loadARObject() {
    const group = new DispatchGroup();
    if (
      this.fileName.endsWith(".gltf") ||
      this.fileName.endsWith(".glb") ||
      this.fileName.endsWith(".usdz")
    ) {
      var loadTime = performance.now();
      const loader = new GLTFLoader();
      group.enter();
      var loadTime = performance.now();
      // TODO: IBRAHIM consider using loadAsync
      loader.load(this.fileUrlAndroid, (gltf) => {
        if (gltf) {
          loadTime = (performance.now() - loadTime) / 1000;
          // console.log("GLTF LOADING TIME", loadTime);
          // TODO: Ibrahim consider using scene.children[0]
          this.object = gltf.scene;
          // Animation clips are usually stored in the gltf object, so we need to copy them to the created object
          this.object.animations = gltf.animations;

          this.setObjectSettings();
          this.scaleARObject();
          // this.fixPositionAndPivot()
        }
        group.leave();
      });
    } else if (this.isAllowedImageFormat(this.fileName)) {
      group.enter();
      this.object = await this.createObjectFromImage(this.fileUrlAndroid);
      this.setObjectSettings();
      group.leave();
    }

    await group.wait();
    this.object.name = this.fileName
    return this.object;
  }

  isAllowedImageFormat(name: string): boolean {
    if (this.fileName.endsWith(".png") || this.fileName.endsWith(".jpg") || this.fileName.endsWith(".jpeg")) {
      return true
    }

    return false
  }

  // createObjectFromImage(imgPath: string) {
  //   const planeGeometry = new THREE.PlaneGeometry(
  //     this.settings.size,
  //     this.settings.size
  //   );
  //   const textureLoader = new THREE.TextureLoader();
  //   var loadTime = performance.now();
  //   const texture = textureLoader.load(imgPath, () => {
  //     loadTime = (performance.now() - loadTime) / 1000;
  //     // console.log("TEXTURE LOADING TIME", loadTime);
  //   });
  //   texture.colorSpace = THREE.SRGBColorSpace;
  //   const material = new THREE.MeshBasicMaterial({
  //     map: texture,
  //     side: THREE.DoubleSide,
  //     transparent: true,
  //   });
  //   const planeMesh = new THREE.Mesh(planeGeometry, material);
  //   return planeMesh;
  // }
  async createObjectFromImage(imgPath: string) {
    const planeGeometry = new THREE.PlaneGeometry(
      this.settings.size,
      this.settings.size
    );
    const textureLoader = new THREE.TextureLoader();
    let loadTime = performance.now();

    // Use loadAsync to await the texture loading process
    const texture = await textureLoader.loadAsync(imgPath);
    // loadTime = (performance.now() - loadTime) / 1000;
    // console.log("TEXTURE LOADING TIME", loadTime);

    // Apply the correct color space
    texture.colorSpace = THREE.SRGBColorSpace;

    // Create the material and mesh as before
    const material = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide,
      transparent: true,
    });
    const planeMesh = new THREE.Mesh(planeGeometry, material);

    return planeMesh;
  }


  scaleARObject() {
    const scale = this.settings.size;
    const bbox = new THREE.Box3().setFromObject(this.object);
    var origHeight = bbox.max.x - bbox.min.x;
    switch (this.settings.scaleAxis) {
      case 0:
        origHeight = bbox.max.x - bbox.min.x;
        break;
      case 1:
        origHeight = bbox.max.y - bbox.min.y;
        break;
      case 2:
        origHeight = bbox.max.z - bbox.min.z;
        break;
    }

    var scaleFactor = scale / origHeight;
    this.object.scale.set(scaleFactor, scaleFactor, scaleFactor);
  }

  fixPositionAndPivot(): void {
    const offset = this.settings.offsetVec;
    this.object.position.add(offset);
  }

  setObjectSettings() {
    this.object.userData.settings = this.settings;
    this.object.userData.objectMetadata = this.data;
  }

  // loadGLTFModel(modelURL, onLoad, onProgress, onError) {
  //     // Check if local storage is supported
  //     if (typeof Storage !== 'undefined') {
  //         // Attempt to retrieve the model from local storage
  //         const cachedModel = localStorage.getItem(modelURL);

  //         if (cachedModel) {
  //             console.log("LOADING GLTF FROM LOCAL STORAGEEE !!!")
  //             onLoad(JSON.parse(cachedModel));
  //             return;

  //             // If cached, parse the stored JSON and create a THREE.Object3D
  //             const object = new THREE.Object3D();
  //             const data = JSON.parse(cachedModel);

  //             // Traverse the stored data and create a new THREE.Object3D
  //             data.children.forEach((childData) => {
  //                 const child = new THREE.Object3D();
  //                 child.position.fromArray(childData.position);
  //                 child.rotation.fromArray(childData.rotation);
  //                 child.scale.fromArray(childData.scale);
  //                 object.add(child);
  //             });

  //             // Invoke the onLoad callback with the cloned model
  //             onLoad(object.clone());
  //             return;
  //         }
  //     }

  //     // If local storage is not supported or the model is not in the cache, load it
  //     console.log("LOADING GLTF NORMALLY !!!")
  //     const loader = new GLTFLoader();
  //     loader.load(
  //         modelURL,
  //         (gltf) => {
  //             // Store the loaded model in the cache
  //             const object = gltf.scene;
  //             // gltfCache[modelURL] = object;

  //             // Invoke the onLoad callback with the cloned model
  //             onLoad(object);

  //             if (typeof Storage !== 'undefined') {
  //                 localStorage.setItem(modelURL, JSON.stringify(object));
  //             }

  //             // If local storage is supported, store the model data in local storage
  //             // if (typeof Storage !== 'undefined') {
  //             //     const data = {
  //             //         children: [],
  //             //     };

  //             //     object.children.forEach((child) => {
  //             //         data.children.push({
  //             //             position: child.position.toArray(),
  //             //             rotation: child.rotation.toArray(),
  //             //             scale: child.scale.toArray(),
  //             //         });
  //             //     });

  //             //     localStorage.setItem(modelURL, JSON.stringify(data));
  //             // }
  //         },
  //         onProgress,
  //         onError
  //     );
  // }
}

export class UIObjectLoader {
  uiObjectsDict: IUIElementPropsDict[];
  UIObjects: UIElementProps[] = [];

  constructor(uiObjectsDict: IUIElementPropsDict[]) {
    this.uiObjectsDict = uiObjectsDict;
  }

  async loadData() {
    const group = new DispatchGroup();
    for (const uiObjectDict of this.uiObjectsDict) {
      const uiObjectType = uiObjectDict.type;
      var self = this;
      group.enter();
      switch (uiObjectType) {
        case "image":
          var imageElement = new ORBImageUI(uiObjectDict as IORBImageUI);
          (function (image) {
            image.loadData((success) => {
              if (success) {
                self.UIObjects.push(image);
              }
              group.leave();
            });
          })(imageElement);
          break;
        case "button":
          var buttonElement = new ORBButtonUI(uiObjectDict as IORBButtonUI);
          (function (button) {
            button.loadData((success) => {
              if (success) {
                self.UIObjects.push(button);
              }
              group.leave();
            });
          })(buttonElement);
          break;
        case "stats":
          var statsElement = new ORBStatsUI(uiObjectDict as IORBStatsUI);
          (function (stats) {
            stats.loadData((success) => {
              if (success) {
                self.UIObjects.push(stats);
              }
              group.leave();
            });
          })(statsElement);
          break;
        default:
          group.leave();
      }
    }
    await group.wait();
    return this.UIObjects;
  }
}

export class DelayedARObject extends THREE.Object3D {
  private data: NetworkARObjectDict;

  constructor(data: NetworkARObjectDict) {
    super();
    this.data = data;
  }

  async loadObject(): Promise<THREE.Object3D | null> {
    const objectLoader = new ARObjectLoader(this.data);
    const object = await objectLoader.loadARObject();

    return object;
  }
}

export class PngSequencePlane {
  private textures: THREE.Texture[] = [];
  private currentFrame: number = 0;
  private mesh: THREE.Mesh;

  constructor(size: number) {
    const planeGeometry = new THREE.PlaneGeometry(size, size);
    const material = new THREE.MeshBasicMaterial({
      side: THREE.DoubleSide,
      transparent: true,
    });

    this.mesh = new THREE.Mesh(planeGeometry, material);
  }

  public async loadAllTextures(imageUrls: string[]): Promise<void> {
    const loader = new THREE.TextureLoader();
    const loadTexturePromises = imageUrls.map((url) =>
      new Promise<THREE.Texture>((resolve, reject) => {
        loader.load(url, resolve, undefined, reject);
      })
    );

    try {
      this.textures = await Promise.all(loadTexturePromises);
      // Set the first texture after loading is complete
      if (this.textures.length > 0) {
        (this.mesh.material as THREE.MeshBasicMaterial).map = this.textures[0];
        (this.mesh.material as THREE.MeshBasicMaterial).needsUpdate = true;
      }
    } catch (error) {
      console.error('Error loading textures:', error);
    }
  }

  public updateTexture(): void {
    if (this.textures.length > 0) {
      this.currentFrame = (this.currentFrame + 1) % this.textures.length;
      (this.mesh.material as THREE.MeshBasicMaterial).map = this.textures[this.currentFrame];
      (this.mesh.material as THREE.MeshBasicMaterial).needsUpdate = true;
    }
  }

  public getMesh(): THREE.Mesh {
    return this.mesh;
  }
}