import styled from "styled-components";

export const ModalWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalContentBody = styled.div`
  background-color: white;
  z-index: 999;
  width: 90%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

export const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const ModalTitle = styled.p`
  font-weight: bold;
`;

export const StatsContainer = styled.div`
    margin: auto;
  margin-top: 5vh;
`;

export const LottieContainer = styled.div`
  width: 60%;
  height: auto;
  margin: auto;
  margin-top: 8%;
`;

export const FullScreenLottieContainer = styled.div`
  width: 100%;
  height: auto;
  margin: auto;
  z-index: 999;
`;