import React, { useContext } from 'react';
import { useState } from "react";
import "./styles.css"

import { FlexContainer, Title, LogoContainer, CustomizedText, ItemContainer } from "./styled";
import { ShootersListViewTheme, ShootersListViewType } from '../BaselBurgerShootersInput';
import { AppStore } from '../../../app-store/store';
import { CustomizedButton } from '../../../components/pages/welcome-page/styled';
import { useTranslation } from 'react-i18next';
import { LocalizationKeys } from '../../../localization/LocalizationKeys';

export class BaselBurgerListItem {
    id: string
    name: string
    image: string

    constructor(name: string, image: string) {
        this.name = name
        this.image = image
    }
}

interface BaselBurgerSelectionScreenProps {
    list: Array<BaselBurgerListItem>,
    theme: ShootersListViewTheme,
    onItemClick: (id: string | number, index: number) => void
}

export const BaselBurgerSelectionScreen: React.FC<BaselBurgerSelectionScreenProps> = ({ list, theme, onItemClick }) => {
    const { globalTheme } = useContext(AppStore)

    const [selectedItem, setSelectedItem] = useState<{ id: string | number, index: number } | null>(null);
    const { t } = useTranslation();

    const handleItemClick = (id: string | number, index: number) => {
        setSelectedItem({ id, index });
    };

    const handleDoneClick = () => {
        if (selectedItem) {
            onItemClick(selectedItem.id, selectedItem.index);
            setSelectedItem(null);
        }
    };

    return (
        <FlexContainer backgroundImage={theme.backgroundImageUrl}>
            <LogoContainer>
                <img src={globalTheme.imageUrl} />
            </LogoContainer>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {theme.title && <Title>{theme.title}</Title>}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
                <div style={{ flexGrow: 1, width: '100%', overflowY: 'auto', maxHeight: 'calc(100% - 100px)' }}>
                    {theme.listType === ShootersListViewType.Bubbles ? (
                        <BubbleItems
                            items={list}
                            theme={theme}
                            selectedItem={selectedItem}
                            onItemClick={handleItemClick}
                        />
                    ) : (
                        <LargeIconItems
                            items={list}
                            theme={theme}
                            selectedItem={selectedItem}
                            onItemClick={handleItemClick}
                        />
                    )}
                </div>
                <CustomizedButton
                    disabled={!selectedItem}
                    primaryColor={globalTheme.primaryColor ?? "#12eace"}
                    secondaryColor={globalTheme.secondaryColor ?? "#000000"}
                    onClick={handleDoneClick}
                    style={{ position: 'absolute', bottom: '20px', left: '50%', transform: 'translateX(-50%)' }} // Centered horizontally with margin from bottom
                >
                    {t(LocalizationKeys.SelectionScreenDoneButton)}
                </CustomizedButton>
            </div>
        </FlexContainer>
    )
};

const BubbleItems: React.FC<{
    items: Array<{ id: string | number; image: string }>;
    theme: ShootersListViewTheme;
    selectedItem: { id: string | number; index: number } | null;
    onItemClick: (id: string | number, index: number) => void;
}> = ({ items, theme, selectedItem, onItemClick }) => {

    const { globalTheme } = useContext(AppStore)

    const renderRows = () => {
        const rows = [];
        for (let i = 0; i < items.length; i += 3) {
            const rowItems = [];

            for (let j = 0; j < 3; j++) {
                const index = i + j;
                if (index < items.length) {
                    const item = items[index];
                    const isSelected = selectedItem && selectedItem.index === index;

                    rowItems.push(
                        <img
                            key={item.id}
                            src={item.image}
                            alt={`Bubble ${index}`}
                            onClick={() => onItemClick(item.id, index)}
                            style={{
                                border: isSelected ? `2px solid ${globalTheme.primaryColor}` : 'none',
                                width: '120px',
                                height: '120px',
                                margin: '2px',
                            }}
                        />
                    );
                }
            }

            rows.push(
                <div className="row" key={`row-${i}`}>
                    {rowItems}
                </div>
            );
        }
        return rows;
    };

    return <div>{renderRows()}</div>;
};


const LargeIconItems: React.FC<{
    items: Array<{ id: string | number; image: string; name: string }>;
    theme: ShootersListViewTheme;
    selectedItem: { id: string | number; index: number } | null;
    onItemClick: (id: string | number, index: number) => void;
}> = ({ items, theme, selectedItem, onItemClick }) => {

    const { globalTheme } = useContext(AppStore)

    return (
        <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center'
        }}>
            {items.map((item, index) => {
                const isSelected = selectedItem && selectedItem.index === index;
                return (
                    <ItemContainer
                        isSelected={isSelected}
                        primaryColor={globalTheme.primaryColor}
                        secondaryColor={globalTheme.secondaryColor}
                        theme={globalTheme} // Pass the theme object for colors
                        onClick={() => onItemClick(item.id, index)}
                    >
                        <img
                            src={item.image}
                            alt={item.name}
                            style={{
                                width: '100%', // Make the image fill the container
                                height: 'auto', // Maintain aspect ratio
                            }}
                        />
                        <CustomizedText
                            primaryColor={globalTheme.primaryColor ?? "#12eace"}
                            secondaryColor={globalTheme.secondaryColor ?? "#000000"}
                        >
                            {item.name}
                        </CustomizedText>
                    </ItemContainer>
                );
            })}
        </div>
    );
};
