import React from 'react';
import { IGiftSheet } from '../pages/interfaces';

interface GiftSheetPopoverProps {
  giftSheetInput: IGiftSheet;
  onClose: () => void; // Callback function to close the popover
}

const GiftSheetPopover: React.FC<GiftSheetPopoverProps> = ({ giftSheetInput, onClose }) => {
  return (
    <>
      {
        giftSheetInput && (
          <img
            src={giftSheetInput.imageUrl}
            style={{
              maxWidth: '100%',
              height: '100%',
              // marginBottom: '10px'
            }}
            onClick={onClose}
          />
        )
      }
      {
        giftSheetInput &&
        <p>{giftSheetInput.description}</p>
      }
    </>
  );
};

export default GiftSheetPopover;
